import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axiosInstance";
import toast from "react-hot-toast";
import { setChatLoader, setSaveLoader } from "../Loader";
import { setBenchmark } from "../BenchmarkDetails";

export const initialState = {
  messages: [],
  message: "",
  conversationName: "",
};

const chatSlice = createSlice({
  name: "chat",
  initialState: initialState,
  reducers: {
    storeChats(state, action) {
      state.messages.push(action.payload);
    },
    clearChats(state, action) {
      state.messages = action.payload;
    },
    setMessage(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

export const { storeChats, clearChats, setMessage } = chatSlice.actions;
export default chatSlice.reducer;

export const fetchChatsThunkMiddleware = ({ message, messages }, callback) => {
  return async (dispatch) => {
    try {
      if (!message.content || !message.role) {
        return toast.error("Please Enter Message");
      }

      let reqPayload = [...messages, message];
      dispatch(setMessage({ messages: reqPayload }));
      dispatch(setChatLoader(true));
      callback(null);

      console.log(reqPayload);
      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_CHAT,
        data: reqPayload,
      });

      if (response.status === 200) {
        const { message } = response.data;

        const respMessage = [
          ...reqPayload,
          {
            content: message.content,
            role: message.role,
          },
        ];
        dispatch(setMessage({ messages: respMessage, message: null }));
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      dispatch(setChatLoader(false));
    }
  };
};

export const saveConversationThunkMiddleware = (
  { conversationName, messages },
  callback
) => {
  return async (dispatch) => {
    try {
      dispatch(setSaveLoader(true));
      if (!conversationName) {
        return toast.error("Please Enter Conersation Name");
      }

      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_SAVE_CHAT,
        data: { conversationName, messages },
      });

      if (response.status === 201) {
        const { message } = response.data;
        toast.success(message);
        callback(null);
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      dispatch(setSaveLoader(false));
    }
  };
};

export const summarizeConversationThunkMiddleware = (
  { messages },
  callback
) => {
  return async (dispatch) => {
    try {
      dispatch(setSaveLoader(true));

      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_SUMMARIZE_CHAT,
        data: { messages },
      });

      if (response.status === 200) {
        const { summarizedContent } = response.data;
        dispatch(
          setBenchmark({
            ...summarizedContent,
            source: "Conversation",
            scope: "Yes",
          })
        );
        callback({ summarizedContent });
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      dispatch(setSaveLoader(false));
    }
  };
};
