import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axiosInstance";
import toast from "react-hot-toast";
import { setToken } from "../Auth";
import { setLoader } from "../Loader";

const initialState = {
  name: "",
  title: null,
  email: null,
  currentCompany: "",
  country: null,
  role: null,
  globalOrRegional: null,
  b2bOrB2c: null,
  currencySymbol: null,
  industry: null,
  profile: {
    titleOptions: { options: [], selectedOption: null },
    countryOptions: { options: [], selectedOption: null },
    globalOrRegionalOptions: { options: [], selectedOption: null },
    industryOptions: { options: [], selectedOption: null },
    b2bOrB2cOptions: { options: [], selectedOption: null },
  },

  isSaved: localStorage.hasOwnProperty("isProfileSaved")
    ? localStorage.getItem("isProfileSaved")
    : false,

  totalBenchmarkValue: null,
  totalBenchmark: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    setProfileOptions(state, action) {
      Object.keys(action.payload).forEach((key, index) => {
        state.profile[key] = {
          options: [...action.payload[key]],
          selectedOption: state.profile[key].selectedOption,
        };
      });
    },
    setPersonalInfo(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
    setSelectedOption(state, action) {
      Object.keys(action.payload).forEach((key, index) => {
        state.profile[key].selectedOption = action.payload[key];
      });
    },
    setSaveStatus(state, action) {
      const { isSaved } = action.payload;
      state.isSaved = isSaved;
      localStorage.setItem("isProfileSaved", isSaved);
    },
  },
});

export const {
  setProfileOptions,
  setPersonalInfo,
  setSelectedOption,
  setSaveStatus,
} = profileSlice.actions;

export default profileSlice.reducer;

export const getProfileOptionsThunkMiddleware = () => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_GET_PROFILE_OPTIONS,
      });
      if (res.status === 200) {
        const { data } = res;
        dispatch(setProfileOptions(data));
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      dispatch(setProfileOptions({}));
      toast.error(message);
    }
  };
};

export const postProfileThunkMiddleware = ({
  name,
  currentCompany,
  title,
  country,
  globalOrRegional,
  industry,
  b2bOrB2c,
}) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true));

      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_POST_PROFILE,
        data: {
          name,
          currentCompany,
          title,
          country,
          globalOrRegional,
          industry,
          b2bOrB2c,
        },
      });

      if (res.status === 201) {
        toast.success("Profile Saved Successfully");
        dispatch(setSaveStatus({ isSaved: true }));
      }
    } catch (error) {
      let message = "ERROR";
      console.log(error);
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      dispatch(setSaveStatus({ isSaved: false }));
      toast.error(message);
    } finally {
      dispatch(setLoader(false));
    }
  };
};

export const getProfileThunkMiddleware = () => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_GET_PROFILE,
      });

      if (res.status === 200) {
        console.log(res.data);
        const {
          name,
          currentCompany,
          totalBenchmarkValue,
          country,
          totalBenchmark,
          globalOrRegional,
          b2bOrB2c,
          industry,
          title,
          currencySymbol,
        } = res.data.userProfile;
        const role = res.data.role;
        const email = res.data.email;

        dispatch(
          setPersonalInfo({
            role,
            name,
            country,
            currentCompany,
            totalBenchmarkValue,
            totalBenchmark,
            globalOrRegional,
            b2bOrB2c,
            industry,
            title,
            email,
            currencySymbol,
          })
        );

        dispatch(setSaveStatus({ isSaved: true }));
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      dispatch(setSaveStatus({ isSaved: false }));
      toast.error(message);
    }
  };
};

export const updateProfileThunkMiddleware = (
  {
    name,
    currentCompany,
    title,
    country,
    globalOrRegional,
    b2bOrB2c,
    industry,
    email,
  },
  callback
) => {
  return async (dispatch) => {
    try {
      setLoader(true);
      const res = await axios({
        method: "PUT",
        url: process.env.REACT_APP_UPDATE_PROFILE,
        data: {
          name,
          currentCompany,
          title,
          country,
          globalOrRegional,
          b2bOrB2c,
          industry,
          email,
        },
      });

      if (res.status === 200) {
        console.log(res.data);
        const {
          name,
          currentCompany,
          country,
          globalOrRegional,
          b2bOrB2c,
          industry,
          title,
        } = res.data.updatedProfile;
        const email = res.data.email;

        dispatch(
          setPersonalInfo({
            name,
            country,
            currentCompany,
            globalOrRegional,
            b2bOrB2c,
            industry,
            title,
            email,
          })
        );
        callback(null);
        toast.success("Profile Updated Successfully");
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      setLoader(true);
    }
  };
};

export const logoutThunkMiddleware = () => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_LOGOUT,
      });
      console.log(res);
      if (res.status === 200) {
        localStorage.clear();
        dispatch(setToken({ token: null, isAuthenticated: false, role: null }));
        dispatch(setSaveStatus({ isSaved: false }));
        dispatch(setPersonalInfo(initialState));
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      dispatch(setSaveStatus({ isSaved: false }));
      toast.error(message);
    }
  };
};
