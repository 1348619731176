import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "conversation",
  update: null,
  historySidebar: false,
};

const historySlice = createSlice({
  name: "history",
  initialState: initialState,
  reducers: {
    setHistory(state, action) {
      state.name = action.payload;
    },
    setUpdateHistory(state, action) {
      state.update = action.payload;
    },
    setHistorySidebar(state, action) {
      state.historySidebar = action.payload;
    },
  },
});
export const { setHistory, setUpdateHistory, setHistorySidebar } =
  historySlice.actions;
export default historySlice.reducer;
