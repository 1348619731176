import styles from "./Conversation.module.css";
import InputTextButton from "../../InputTextButton";
import { useDispatch, useSelector } from "react-redux";
import { setHistory } from "../../../redux/features/History";
import { IoMdSend } from "react-icons/io";
import {
  fetchChatsThunkMiddleware,
  setMessage,
} from "../../../redux/features/Chat";
import { useRef } from "react";
import { FaArrowRight } from "react-icons/fa";
import Button from "../../Button";
import { conversationCardData } from "../../../constant";

const inputBtnStyles = {
  borderRadius: "8px",
  border: "1px solid #FFF",
  background: `rgba(255, 255, 255, 0.10)`,
  color: "#FFF",
  fontSize: "18px",
  fontWeight: 500,
  padding: "0.8rem 5rem 0.8rem 0.8rem",
};
// const selectedBtnStyles = { backgroundColor: "#374CE7", border: "none" };
const btnStyles = { backgroundColor: "inherit" };

export default function Conversation() {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const { message, messages } = useSelector((state) => state.chat);

  const sendMessageHandler = (e) => {
    e.preventDefault(e);
    dispatch(
      fetchChatsThunkMiddleware(
        {
          message: { content: message, role: "user" },
          messages,
        },
        (error) => {
          if (!error) dispatch(setHistory("chat"));
        }
      )
    );
  };

  const sendCardMessageHandler = (text) => {
    dispatch(setMessage({ message: { content: text, role: "user" } }));
    dispatch(
      fetchChatsThunkMiddleware(
        {
          message: { content: text, role: "user" },
          messages,
        },
        (error) => {
          if (!error) dispatch(setHistory("chat"));
        }
      )
    );
    if (inputRef.current && inputRef.current.resetInput) {
      inputRef.current.resetInput();
    }
  };

  return (
    <div className={styles.conversation}>
      <div className={styles.content}>
        <div className={styles.heading}>
          Here are some initial prompts to start, Use your own skills and
          knowledge...
        </div>
        <div className={styles.cards}>
          {conversationCardData.map((item, index) => (
            <div
              onClick={() => sendCardMessageHandler(item)}
              key={index}
              className={styles.card}
            >
              <div className={styles.text}>{item}</div>
              <div className={styles.arrow}>
                <FaArrowRight />
              </div>
            </div>
          ))}
        </div>
      </div>

      <form onSubmit={sendMessageHandler} className={styles.sendMessageBtn}>
        <div className={styles.sendIcon} onClick={sendMessageHandler}>
          <IoMdSend />
        </div>

        <InputTextButton
          ref={inputRef}
          name={"Send a message..."}
          style={inputBtnStyles}
          setValue={setMessage}
          initialValue={message}
          title={"message"}
        />
      </form>

      <div className={styles.buttons}>
        <Button
          style={{ ...btnStyles, borderRadius: "8px" }}
          onClick={(e) => {
            dispatch(setHistory("step1"));
          }}
          className={styles.selectionButton}
        >
          Use Drop-Down Menu Instead
        </Button>
      </div>
    </div>
  );
}
