import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setToken } from "../redux/features/Auth";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { setSaveStatus } from "../redux/features/Profile";

export default function Token() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    const isProfileSaved = new URLSearchParams(location.search).get(
      "isProfileSaved"
    );
    const role = new URLSearchParams(location.search).get("role");
    dispatch(setToken({ token, isAuthenticated: true, role }));
    dispatch(setSaveStatus({ isSaved: isProfileSaved }));
    navigate("/user");

    toast.success("Logged In Successfully");
  }, []);

  return <></>;
}
