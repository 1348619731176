import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axiosInstance";
import toast from "react-hot-toast";
import { setLoader } from "../Loader";

const initialState = {
    totalUsers: null,
    totalBenchmarkValue: null,
    lastFiveBenchmark: null,

    industryStats: null,
    departmentStats: null,

    recentUsers: null,

    benchmarkStats: null,
    recentBenchmark: null,

    rateCardStats: null,
    recentRateCard: null,

};

const adminSlice = createSlice({
    name: "admin",
    initialState: initialState,
    reducers: {
        setAdminState(state, action) {
            Object.keys(action.payload).forEach((key, index) => {
                state[key] = action.payload[key];
            });
        },
    },
});

export const { setAdminState } = adminSlice.actions;
export default adminSlice.reducer;

export const getAdminDashboardStatsThunkMiddleware = () => {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true));
            const res = await axios({
                method: "GET",
                url: process.env.REACT_APP_ADMIN_GET_TOTAL_USERS_BENCHMARKS,
            });

            if (res.status === 200) {
                const { totalUsers, totalBenchmarkValue } = res.data;
                dispatch(setAdminState({ totalUsers, totalBenchmarkValue }));
            }
        } catch (error) {
            let message = "ERROR";
            if (error.hasOwnProperty("response")) {
                message = error.response.data.message;
            } else {
                message = error.message;
            }
            toast.error(message);
        } finally {
            dispatch(setLoader(false));
        }
    };
};

export const lastFiveBenchmarkThunkMiddleware = () => {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true));
            const res = await axios({
                method: "GET",
                url: process.env.REACT_APP_ADMIN_GET_LAST_FIVE_BENCHMARKS,
            });

            if (res.status === 200) {
                const lastFiveBenchmark = res.data;
                dispatch(setAdminState({ lastFiveBenchmark }));
            }
        } catch (error) {
            let message = "ERROR";
            if (error.hasOwnProperty("response")) {
                message = error.response.data.message;
            } else {
                message = error.message;
            }
            toast.error(message);
        } finally {
            dispatch(setLoader(false));
        }
    };
};

export const profileIndustryStatsThunkMiddleware = () => {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true));
            const res = await axios({
                method: "GET",
                url: process.env.REACT_APP_ADMIN_GET_PORFILE_INDUSTRY_STATS,
            });

            if (res.status === 200) {
                const { totalUsers, industryStats, departmentStats } = res.data;
                dispatch(setAdminState({ totalUsers, industryStats, departmentStats }));
            }
        } catch (error) {
            let message = "ERROR";
            if (error.hasOwnProperty("response")) {
                message = error.response.data.message;
            } else {
                message = error.message;
            }
            toast.error(message);
        } finally {
            dispatch(setLoader(false));
        }
    };
};

export const recentUsersThunkMiddleware = () => {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true));
            const res = await axios({
                method: "GET",
                url: process.env.REACT_APP_ADMIN_GET_RECENT_USERS,
            });

            if (res.status === 200) {
                const recentUsers = res.data;
                dispatch(setAdminState({ recentUsers }));
            }
        } catch (error) {
            let message = "ERROR";
            if (error.hasOwnProperty("response")) {
                message = error.response.data.message;
            } else {
                message = error.message;
            }
            toast.error(message);
        } finally {
            dispatch(setLoader(false));
        }
    };
};

export const benchmarkStatsThunkMiddleware = () => {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true));
            const res = await axios({
                method: "GET",
                url: process.env.REACT_APP_ADMIN_GET_BENCHMARK_STATS,
            });

            if (res.status === 200) {
                const { stats } = res.data;
                dispatch(setAdminState({ benchmarkStats: stats }));
            }
        } catch (error) {
            let message = "ERROR";
            if (error.hasOwnProperty("response")) {
                message = error.response.data.message;
            } else {
                message = error.message;
            }
            toast.error(message);
        } finally {
            dispatch(setLoader(false));
        }
    };
};

export const recentBenchmarkThunkMiddleware = () => {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true));
            const res = await axios({
                method: "GET",
                url: process.env.REACT_APP_ADMIN_GET_RECENT_BENCHMARKS,
            });

            if (res.status === 200) {
                const recentBenchmark = res.data;
                dispatch(setAdminState({ recentBenchmark }));
            }
        } catch (error) {
            let message = "ERROR";
            if (error.hasOwnProperty("response")) {
                message = error.response.data.message;
            } else {
                message = error.message;
            }
            toast.error(message);
        } finally {
            dispatch(setLoader(false));
        }
    };
};

export const rateCardStatsThunkMiddleware = () => {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true));
            const res = await axios({
                method: "GET",
                url: process.env.REACT_APP_ADMIN_GET_RATECARD_STATS,
            });

            if (res.status === 200) {
                const { stats } = res.data;
                dispatch(setAdminState({ rateCardStats: stats }));
            }
        } catch (error) {
            let message = "ERROR";
            if (error.hasOwnProperty("response")) {
                message = error.response.data.message;
            } else {
                message = error.message;
            }
            toast.error(message);
        } finally {
            dispatch(setLoader(false));
        }
    };
};

export const recentRateCardThunkMiddleware = () => {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true));
            const res = await axios({
                method: "GET",
                url: process.env.REACT_APP_ADMIN_GET_RECENT_RATECARDS,
            });

            if (res.status === 200) {
                const recentRateCard = res.data;
                dispatch(setAdminState({ recentRateCard }));
            }
        } catch (error) {
            let message = "ERROR";
            if (error.hasOwnProperty("response")) {
                message = error.response.data.message;
            } else {
                message = error.message;
            }
            toast.error(message);
        } finally {
            dispatch(setLoader(false));
        }
    };
};