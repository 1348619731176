import { useRef, forwardRef, useImperativeHandle, useState } from "react";
import styles from "./InputTextButton.module.css";
import { useDispatch } from "react-redux";
export default forwardRef(function InputTextButton(
  { name, type, initialValue, title, setValue, style, children, disable },
  ref
) {
  const dispatch = useDispatch();
  const inputRef = useRef("");
  const [state, setState] = useState(initialValue);
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return inputRef.current.value;
      },
      resetInput() {
        setState(""); // Reset the state to initial value
        // inputRef.current.value = ""; // Reset the input element value
      },
    };
  });
  return (
    <input
      type={type}
      className={styles.inputText}
      style={style}
      placeholder={name}
      onChange={(e) => {
        if (typeof setValue === "function") {
          const obj = {};
          obj[title] = e.target.value;
          dispatch(setValue(obj));
        }
        setState(e.target.value);
      }}
      ref={inputRef}
      value={state}
      disabled={disable}
    />
  );
});
