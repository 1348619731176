import { useSelector } from "react-redux";
import styles from "./BenchMarkCard.module.css";
import { CircularProgress } from "@mui/material";


export default function BenchMarkCard({ heading, value, comment }) {
  const { isLoading } = useSelector((state) => state.loader);

  return (
    <>
      {
        isLoading ? <>
          <div className={styles.card_skeleton}>
            {/* <Skeleton sx={{ bgcolor: 'rgba(50, 120, 160, 0.20)' }} animation={"wave"} variant="overlay" width={"100%"} height={190} /> */}
            <CircularProgress style={{
              color: "#374CE7"
            }} />
          </div>
        </> : (
          <div className={styles.card}>
            <div className={styles.heading}>{heading}</div>
            <div className={styles.value}>{value}</div>
            <div className={styles.comment}>{comment}</div>
          </div>
        )
      }
    </>
  );
}
