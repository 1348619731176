import { useDispatch } from "react-redux";
import styles from "./AgencyDropDown.module.css";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

export default function AgencyDropDown({
  name,
  options,
  countries,
  initialValue,
  setOption,
  title,
}) {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(initialValue);
  const [clicked, setClicked] = useState(false);
  const [modal, setModal] = useState("close");

  useEffect(() => {
    if (typeof setOption === "function") {
      const obj = {};
      obj[title] = selectedOption;
      dispatch(setOption(obj));
    }
  }, [selectedOption]);
  return (
    <>
      <div
        className={`${styles.agencyDropDown} ${
          selectedOption !== null ? styles.selected : ""
        }`}
        onClick={(e) => {
          setClicked((state) => !state);
        }}
      >
        {!selectedOption ? name : selectedOption}
      </div>
      <div className={styles.dropDownIndicator}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M6 9L12 15L18 9"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <AnimatePresence>
        {clicked && (
          <motion.div
            initial={{ height: "0", opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{
              duration: 0.25,
              ease: "easeIn",
              damping: 25,
              stiffness: 500,
            }}
          >
            {options && options.length > 0 ? (
              options.map((op) => {
                return (
                  <div
                    className={styles.menuItem}
                    onClick={(e) => {
                      if (op.value === "same") {
                        //same country
                        setSelectedOption(op.display);
                        setClicked((state) => !state);
                        setModal("close");
                      } else {
                        //different country
                        setModal((val) => {
                          if (val === "open") {
                            return "close";
                          } else {
                            return "open";
                          }
                        });
                      }
                    }}
                  >
                    <div className={styles.optionsContainer}>
                      <div className={styles.option}>
                        <div className={styles.content}>
                          <div className={styles.heading}>{op.label}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={styles.menuItem}>
                <div className={styles.optionsContainer}>
                  <div className={styles.option}>
                    <div className={styles.content}>
                      <div className={styles.heading}>No Item to Select</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {modal === "open" && (
        <motion.div
          initial={{ height: "0", opacity: 0 }}
          animate={{ height: "", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{
            duration: 0.25,
            ease: "easeIn",
            damping: 25,
            stiffness: 500,
          }}
          className={styles.overlay}
        >
          {countries.map(({ value }) => {
            return (
              <div
                className={styles.countryName}
                onClick={(e) => {
                  setClicked((state) => !state);
                  setModal("close");
                  setSelectedOption(value);
                }}
              >
                {value}
              </div>
            );
          })}
        </motion.div>
      )}
    </>
  );
}
