import { useDispatch, useSelector } from "react-redux";
import Button from "../../Button";
import StickyHeadTable from "../../Table/Table";
import styles from "./Result.module.css";
import { setHome } from "../../../redux/features/Home";
import {
  postRatecardDetailsAndInfoThunkMiddleware,
  setRatecard,
} from "../../../redux/features/RatedcardDetails";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Papa from "papaparse";
import toast from "react-hot-toast";
import InputTextButton from "../../InputTextButton";
import { CircularProgress, Typography } from "@mui/material";

const columns = [
  // { id: "serialNo", label: "Sr. No.", minWidth: 100, align: "center" },
  { id: "department", label: "Department", minWidth: 100, align: "center" },
  {
    id: "seniority",
    label: "Seniority",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "role_level",
    label: "Role Level",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "role",
    label: "Role",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "hourlyRate",
    label: "Hourly Rate",
    minWidth: 100,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

const options = [
  { id: "CLIENT SERVICE", color: "#6071ED" },
  { id: "PROJECT MANAGEMENT", color: "#8D99F180" },
  { id: "TECHNOLOGY & PRODUCTION ", color: "#459ff0" },
  { id: "CREATIVE", color: "#8D99F1" },
  { id: "PRODUCTION", color: "#DBDEFB" },
  { id: "STRATEGY", color: "#8C98F2" },
  { id: "MEDICAL", color: "#8C80F4" },
  { id: "ACCOUNT MANAGEMENT", color: "#7010ED" },
  { id: "ANALYTICS, PLANNING & STRATEGY", color: "#8D99F150" },
  { id: "OTHERS", color: "#1296BE" },
  { id: "AGENCY MANAGEMENT", color: "#74acc5" },
];

function createData(department, seniority, role_level, role, hourlyRate) {
  return { department, seniority, role_level, role, hourlyRate };
}

export default function Result({ setState, isViewAllTab, setIsViewAllTab }) {
  const dispatch = useDispatch();

  const { rateCardData, country, tier, agencyType, rateCardName, logFileUrl } =
    useSelector((state) => state.ratecardDetails);

  const homeState = useSelector((state) => state.home.name);
  const { isLoading } = useSelector((state) => state.loader);

  const CreateRows = (rateCardData) => {
    const rows = [];
    if (rateCardData) {
      rateCardData.forEach((element) => {
        rows.push(
          createData(
            element.department.replace(/_/g, " "),
            element.seniority,
            element.role_level,
            element.role,
            `${element.currencySymbol} ${(+element.median_rate).toLocaleString(
              "en-US",
              { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            )}`
          )
        );
      });
    }
    return rows;
  };

  const postRatecardInfo = async () => {
    dispatch(
      postRatecardDetailsAndInfoThunkMiddleware(
        {
          country,
          tier,
          agencyType,
          rateCardName,
          rateCardData,
          logFileUrl,
        },
        (error) => {
          if (!error) {
            setState("form");
            dispatch(setHome("home"));
          }
        }
      )
    );
  };

  // Export to CSV
  const CSVExporter = (data) => {
    try {
      if (rateCardName || homeState === "rateCardDetail") {
        const csv = Papa.unparse(data);
        const blob = new Blob([csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${rateCardName}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        toast.success("Downloaded Successfully");
      } else {
        toast.error("Please Enter Ratecard Name");
      }
    } catch (error) {
      toast.error("Download Failed");
    }
  };

  return (
    <div className={styles.rateCardResult}>
      <div className={styles.content}>
        <div className={styles.heading}>
          {homeState === "rateCardDetail"
            ? ` Here is the result for ${rateCardName} ratecard`
            : "  Here is the result for requested rate card"}
        </div>
        <StickyHeadTable>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{
                    width: column.minWidth,
                    color: "rgba(55, 76, 231, 1)",
                    backgroundColor: `rgba(255, 255, 255, 1)`,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    fontWeight: "700",
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && homeState === "rateCardDetail" ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <div
                    style={{
                      minHeight: "15rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      style={{
                        color: "#374CE7",
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {rateCardData ? (
                  CreateRows(rateCardData).map((row) => {
                    let color = options.find((op) => {
                      if (op.id === row.department) return op.color;
                    });
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                        style={{ backgroundColor: `white` }}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                color: "rgba(0, 0, 0, 0.8)",
                                fontWeight: 700,
                                fontSize: "14px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                border: "1px solid black",
                                fontFamily: "Montserrat",
                              }}
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography
                        style={{ textAlign: "center" }}
                        variant="subtitle1"
                      >
                        No Ratecard to show
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </StickyHeadTable>

        <div className={styles.download}>
          <Button
            name={"Download as CSV"}
            style={{
              height: "2.8rem",
              fontWeight: "400",
              backgroundColor: "#374CE7",
              border: "none",
              color: "#FFF",
            }}
            onClick={() => CSVExporter(rateCardData)}
            disable={!rateCardData}
          >
            Download as CSV
          </Button>

          {homeState === "rateCardDetail" && (
            <Button
              name={"Recalculate"}
              style={{
                height: "2.8rem",
                fontWeight: "400",
                backgroundColor: "inherit",
              }}
              onClick={(e) => {
                if (isViewAllTab === true) {
                  dispatch(setHome("viewAll"));
                } else if (isViewAllTab === false) {
                  dispatch(setHome("home"));
                }
                dispatch(setRatecard({ rateCardName: null }));
                setIsViewAllTab(false);
              }}
            >
              Cancel
            </Button>
          )}
        </div>

        {homeState !== "rateCardDetail" && (
          <div className={styles.inputBoxContainer}>
            <div className={styles.inputLabel}>Ratecard Name:</div>
            <div className={styles.inputBox}>
              <InputTextButton
                name={"Enter Ratecard name"}
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.20)",
                  border: "1px solid rgba(255, 255, 255, 0.50)",
                }}
                setValue={setRatecard}
                initialValue={rateCardName}
                title={"rateCardName"}
              />
            </div>
          </div>
        )}

        {homeState !== "rateCardDetail" && (
          <div className={styles.buttonBox}>
            <div className={styles.button1}>
              <Button
                name={"Recalculate"}
                style={{
                  height: "2.8rem",
                  fontWeight: "400",
                  backgroundColor: "inherit",
                }}
                onClick={(e) => {
                  setState("form");
                }}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.button2}>
              <Button
                name={"Save"}
                style={{
                  height: "2.8rem",
                  fontWeight: "700",
                  backgroundColor: "#374CE7",
                  border: "0",
                }}
                onClick={postRatecardInfo}
                disable={!rateCardData}
              >
                {isLoading ? (
                  <CircularProgress size={30} color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
