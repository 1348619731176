import React from "react";
import styles from "./InputGroup.module.css";
import InputTextButton from "../../InputTextButton";

const InputGroup = ({
  label = "Label",
  placeholder,
  initialValue,
  setFunction,
  activeStatus,
  title,
}) => {
  return (
    <div className={styles.inputGroup}>
      <div className={styles.label}>{label}</div>
      <div className={styles.inputTextbox}>
        <InputTextButton
          name={placeholder}
          style={{
            backgroundColor: "transparent",
            opacity: `${!activeStatus ? "1" : "0.5"}`,
          }}
          setValue={setFunction}
          initialValue={initialValue}
          title={title}
          disable={activeStatus}
        />
      </div>
    </div>
  );
};

export default InputGroup;
