import styles from "./Dashboard.module.css";
import Home from "./Home/Home";
import UserManagement from "./UserManagement/UserManagement";
import RatecardManagement from "./RatecardManagement/RatecardManagement";
import BenchmarkAnalysis from "./BechmarkAnalysis/BenchmarkAnalysis";
import Support from "./Support/Support";
import SideNav from "./SideNav/SideNav";
import { useDispatch, useSelector } from "react-redux";


export default function Dashboard() {
  const dispatch = useDispatch();
  const navBarState = useSelector((state) => state.navBar.name);

  return (
    <div className={styles.dashBoard}>
      <SideNav />
      {navBarState === "home" && <Home />}
      {navBarState === "userManagement" && <UserManagement />}
      {navBarState === "benchmarkAnalysis" && <BenchmarkAnalysis />}
      {navBarState === "ratecardManagement" && <RatecardManagement />}
      {navBarState === "support" && <Support />}
    </div>
  );
}
