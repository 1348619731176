import LeftPage from "./LeftPage";
import RightPage from "./RightPage";
import styles from "./Auth.module.css";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import logo from "../../assets/images/AlexLogo.png";

export default function Auth({ setIsLoggedIn }) {
  return (
    <div className={styles.authPage}>
      <LeftPage />
      <RightPage setIsLoggedIn={setIsLoggedIn} />
    </div>
  );
}
