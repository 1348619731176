import styles from "./SideNav.module.css";
import companyLogo from "../../../../assets/images/AlexLogo.png";
import BechmarkAnalysis from "../../../../assets/images/BenchmarkAnalysis.png";
import RatecardManagement from "../../../../assets/images/RatecardManagement.png";
import NavBarItem from "../../../Dashboard/NavBarItem";
import { setNavBar } from "../../../../redux/features/NavBar";
import { useSelector, useDispatch } from "react-redux";
import { FiHome } from "react-icons/fi";
import { FaRegFileAlt, FaRegQuestionCircle } from "react-icons/fa";
import { GrAnalytics } from "react-icons/gr";
import { MdCorporateFare } from "react-icons/md";

export default function SideNav() {
  const dispatch = useDispatch();
  const navBarState = useSelector((state) => state.navBar.name);
  return (
    <div className={styles.sideNav}>
      <div className={styles.companyLogo}>
        <img src={companyLogo} height={"100%"} width={"auto"} />
      </div>
      <hr className={styles.dotted} />
      <div className={styles.navBar}>
        <NavBarItem
          onClick={(e) => {
            dispatch(setNavBar("home"));
          }}
          style={{
            backgroundColor:
              navBarState === "home" ? `rgba(35, 44, 107, 1)` : "inherit",
          }}
        >
          <FiHome />
          <div className={styles.text}>Home</div>
        </NavBarItem>
        <NavBarItem
          onClick={(e) => {
            dispatch(setNavBar("userManagement"));
          }}
          style={{
            backgroundColor:
              navBarState === "userManagement"
                ? `rgba(35, 44, 107, 1)`
                : "inherit",
          }}
        >
          <FaRegFileAlt />
          <div className={styles.text}>User Management</div>
        </NavBarItem>
        <NavBarItem
          onClick={(e) => {
            dispatch(setNavBar("benchmarkAnalysis"));
          }}
          style={{
            backgroundColor:
              navBarState === "benchmarkAnalysis"
                ? `rgba(35, 44, 107, 1)`
                : "inherit",
          }}
        >
          <GrAnalytics />
          <div className={styles.text}>Bechmark Analysis</div>
        </NavBarItem>
        <NavBarItem
          onClick={(e) => {
            dispatch(setNavBar("ratecardManagement"));
          }}
          style={{
            backgroundColor:
              navBarState === "ratecardManagement"
                ? `rgba(35, 44, 107, 1)`
                : "inherit",
          }}
        >
          <MdCorporateFare />
          <div className={styles.text}>Ratecard Management</div>
        </NavBarItem>
        <NavBarItem
          onClick={(e) => {
            dispatch(setNavBar("support"));
          }}
          style={{
            backgroundColor:
              navBarState === "support" ? `rgba(35, 44, 107, 1)` : "inherit",
          }}
        >
          <FaRegQuestionCircle />
          <div className={styles.text}>Support and Help</div>
        </NavBarItem>
      </div>
    </div>
  );
}
