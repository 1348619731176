import InputTextButton from "../../InputTextButton";
import styles from "./Screen1.module.css";
import arrow from "../../../assets/images/RightArrow.png";
import Logo from "../../Logo/Logo";
import AgencyTypeDropDown from "../../SelectionEstimation/Step2/AgencyTypeDropDown";
import { useSelector } from "react-redux";
import { setPersonalInfo } from "../../../redux/features/Profile";

export default function Screen1({ setScreen }) {
  const { countryList } = useSelector((state) => state.miscellaneous);
  const { name, currentCompany, title, country } = useSelector(
    (state) => state.profile
  );

  return (
    <div className={styles.screen1}>
      <Logo />
      <div className={styles.modal}>
        <div className={styles.head}>
          Let's get started with some basic company information.
        </div>
        <div className={styles.step}>Step 1 of 2</div>
        <div className={styles.inputLabel}>Name</div>
        <div className={styles.inputBox}>
          <InputTextButton
            name={"Enter your name"}
            style={{
              backgroundColor: "#000",
              border: "1px solid rgba(255, 255, 255, 0.50)",
            }}
            setValue={setPersonalInfo}
            initialValue={name}
            title={"name"}
          />
        </div>
        <div className={styles.inputLabel}>Current Company</div>
        <div className={styles.inputBox}>
          <InputTextButton
            name={"Enter your company"}
            style={{
              backgroundColor: "#000",
              border: "1px solid rgba(255, 255, 255, 0.50)",
            }}
            setValue={setPersonalInfo}
            initialValue={currentCompany}
            title={"currentCompany"}
          />
        </div>
        <div className={styles.inputLabel}>Title</div>
        <div className={styles.selectDropDown}>
          <InputTextButton
            name={"Enter your title"}
            style={{
              backgroundColor: "#000",
              border: "1px solid rgba(255, 255, 255, 0.50)",
            }}
            setValue={setPersonalInfo}
            initialValue={title}
            title={"title"}
          />
        </div>
        <div className={styles.inputLabel}>Country</div>
        <div className={styles.selectDropDown}>
          <AgencyTypeDropDown
            name={"Select your country"}
            options={countryList}
            initialValue={country}
            setOption={setPersonalInfo}
            title={"country"}
          />
        </div>
      </div>
      <div
        className={styles.arrow}
        onClick={(e) => {
          setScreen("screen2");
        }}
      >
        <img src={arrow}></img>
      </div>
    </div>
  );
}
