import styles from "./LeftPage.module.css";
import { Link } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";

export default function LeftPage() {
  return (
    <div className={styles.leftPage}>
      <div className={styles.modal}>
        {/* <div className={styles.head1}>
          <div className={styles.content}>Welcome Back</div>
        </div>
        <div className={styles.head2}>
          <div className={styles.content}>
            Have access to your AI Marketing Consultant.
          </div>
        </div>

        <div className={styles.description1}>
          <div className={styles.content}>
            Sign in and lets get to work, Alex
          </div>
        </div> */}
        <div className={styles.description2}>
          <div className={styles.content}>
            We use the world’s largest database of marketing investment and
            unique GenAI technology to impartially benchmark and advise on your
            costs to help you make better decisions with confidence
          </div>
        </div>

        <Link to={"/"} className={styles.getStarted}>
          <div className={styles.getStartedBtnStyles}>
            <FaArrowLeftLong />

            <div>Back To Home!</div>
          </div>
        </Link>
      </div>
    </div>
  );
}
