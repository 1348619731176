import styles from "./Row.module.css";
import InputTextButton from "../../../InputTextButton";
const inputStyles = {
  backgroundColor: "inherit",
  fontSize: "14px",
  fontWeight: "400",
  color: "#FFF",
};
export default function Row({ name, label, initialValue, disable }) {
  return (
    <div className={styles.row}>
      <div className={styles.label}>{label}</div>
      <div className={styles.inputText}>
        <InputTextButton
          name={name}
          style={inputStyles}
          initialValue={initialValue}
          disable={disable}
        />
      </div>
    </div>
  );
}
