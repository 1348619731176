import { useDispatch, useSelector } from "react-redux";
import styles from "./Messages.module.css";
import { useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";
import Button from "../../Button";
import { summarizeConversationThunkMiddleware } from "../../../redux/features/Chat";
import { getBenchmarkInfoThunkMiddleware } from "../../../redux/features/BenchmarkDetails";
import { setHistory } from "../../../redux/features/History";

const createBenchmarkBtnStyles = {
  height: "2.8rem",
  fontWeight: "700",
  backgroundColor: "#374CE7",
  border: "0",
  width: "12rem",
  marginRight: "auto",
  padding: "1rem",
};

export default function Messages() {
  const { messages } = useSelector((state) => state.chat);
  const { isChatLoading, isSaveLoading, isLoading } = useSelector(
    (state) => state.loader
  );
  const messagesEndRef = useRef(null);
  const { name } = useSelector((state) => state.profile);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const dispatch = useDispatch();

  const summerizeAndCalculationHandler = () => {
    dispatch(
      summarizeConversationThunkMiddleware(
        {
          messages,
        },
        ({ summarizedContent }) => {
          dispatch(
            getBenchmarkInfoThunkMiddleware(
              {
                ...summarizedContent,
              },
              (error) => {
                if (!error) dispatch(setHistory("summary"));
              }
            )
          );
        }
      )
    );
  };

  return (
    <div className={styles.messages}>
      {messages &&
        messages.length > 0 &&
        messages.map((item, index) => {
          return (
            <div
              key={index}
              className={`${styles.message} ${
                item?.role === "assistant" ? styles.from : styles.to
              }`}
            >
              {item?.role === "assistant" ? (
                <>
                  <div className={styles.profileIcon}>
                    <div className={styles.letter}>AI</div>
                  </div>

                  <div className={styles.text}>
                    {item?.content?.split(/\n- |\n\n- |\n\n|\n/).map((item) => (
                      <p>{item}</p>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.text}>{item?.content}</div>
                  <div className={styles.profileIcon}>
                    <div>{name ? name[0] : "V"}</div>
                  </div>
                </>
              )}
            </div>
          );
        })}
      {isChatLoading && (
        <div
          className={`${styles.message} ${styles.loadingdots} ${styles.from}`}
        >
          <CircularProgress size={25} color="inherit" />
        </div>
      )}
      {/* Button  */}
      {messages[messages.length - 1] &&
        messages[messages.length - 1]?.role === "assistant" &&
        messages[messages.length - 1]?.content?.includes(
          "Shall I proceed further to calculate the benchmark"
        ) && (
          <Button
            onClick={summerizeAndCalculationHandler}
            style={createBenchmarkBtnStyles}
          >
            {isSaveLoading || isLoading ? (
              <div className=" flex items-center gap-1">
                <CircularProgress size={25} color="inherit" />
              </div>
            ) : (
              "Create Benchmark"
            )}
          </Button>
        )}
      <div ref={messagesEndRef} />
    </div>
  );
}
