import styles from "./Step2.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setHistory } from "../../../redux/features/History/index";
import CalibreDropDown from "./CalibreDropDown";
import AgencyDropDown from "./AgencyDropDown";
import AgencyTypeDropDown from "./AgencyTypeDropDown";
import { setBenchmark } from "../../../redux/features/BenchmarkDetails";
import { MdOutlineHelpOutline } from "react-icons/md";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

export default function Step2() {
  const dispatch = useDispatch();
  const { location, agencyType, tier } = useSelector(
    (state) => state.benchmarkDetails
  );
  const { countryList, calibresList, agencyTypeList } = useSelector(
    (state) => state.miscellaneous
  );

  const { country } = useSelector((state) => state.profile);

  const locationOptions = [
    {
      display: country,
      value: "same",
      label: "Same country as you",
    },
    { value: "different", label: "Different country" },
  ];

  return (
    <div className={styles.step2}>
      {/* <div className={styles.heading}>
        Fill in the questions below for Alex to extract relevant details for
        your algorithm
      </div> */}
      <div className={styles.stepNo}>Step 2 of 5</div>
      <div className={styles.about}>
        <div className={styles.text}>About the agency</div>
        <div className={styles.helpLogo}>
          <MdOutlineHelpOutline />
        </div>
      </div>
      <div className={styles.inputBox}>
        <div className={styles.inputLabel}>What's the location of agency?</div>
        <div className={styles.inputText}>
          <AgencyDropDown
            options={locationOptions}
            name={"Select your agency location"}
            countries={countryList}
            title={"location"}
            setOption={setBenchmark}
            initialValue={location}
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <div className={styles.inputLabel}>
          What's the type of agency you are looking for?
        </div>
        <div className={styles.inputText}>
          <AgencyTypeDropDown
            options={agencyTypeList}
            name={"Select your agency type"}
            title={"agencyType"}
            setOption={setBenchmark}
            initialValue={agencyType}
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <div className={styles.inputLabel}>
          What's the calibre of the agency your want to benchmark?
        </div>
        <div className={styles.inputText}>
          <CalibreDropDown
            name={"Select your calibre of the agency"}
            options={calibresList}
            title={"tier"}
            setOption={setBenchmark}
            initialValue={tier}
          />
        </div>
      </div>
      <div className={styles.arrow}>
        <div
          className={styles.arrowIcon}
          onClick={(e) => {
            dispatch(setHistory("step1"));
          }}
        >
          <FaArrowLeftLong />
        </div>
        <div
          className={styles.arrowIcon}
          onClick={(e) => {
            dispatch(setHistory("step3"));
          }}
        >
          <FaArrowRightLong />
        </div>
      </div>
    </div>
  );
}
