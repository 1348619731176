import styles from "./RecentWorks.module.css";
import Button from "../../Button";
import AgencyTypeDropDown from "../../SelectionEstimation/Step2/AgencyTypeDropDown";
import WorkContainer from "../WorkContainer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useEffect } from "react";
import {
  getLatestInfosThunkMiddleware,
  getUserSpecificBenchmarkInfoThunkMiddleware,
} from "../../../redux/features/BenchmarkDetails";
import { LatestCardSkeleton, RateCardSkeleton } from "./CardSkeleton";
import { getUserSpecificRatecardInfoThunkMiddleware } from "../../../redux/features/RatedcardDetails";
import { setHome } from "../../../redux/features/Home";
import { MdOutlineArrowBack } from "react-icons/md";

const options = [{ label: "Last Week", value: "Last Week" }];

const btnStyles = {
  backgroundColor: `rgba(255, 255, 255, 0.20)`,
  borderRadius: "4px",
  border: "0px solid #000",
  color: "#FFF",
  fontWeight: 700,
  fontSize: "12px",
  padding: "4px 12px",
};

export default function RecentWorks({
  setIsViewAllTab,
  rateCardDetailHandler,
  benchMarkDetailHandler,
}) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.loader);
  const { userSpecificRateCardInfo } = useSelector(
    (state) => state.ratecardDetails
  );
  const { userSpecificBenchmarkInfo, latestInfos } = useSelector(
    (state) => state.benchmarkDetails
  );

  useEffect(() => {
    const getDetails = () => {
      dispatch(getUserSpecificRatecardInfoThunkMiddleware());
      dispatch(getUserSpecificBenchmarkInfoThunkMiddleware());
      dispatch(getLatestInfosThunkMiddleware());
    };
    getDetails();
  }, []);

  const LastestInfoUpdateHandler = (item) => {
    if (item.benchmarkInfo_id) {
      benchMarkDetailHandler(item.benchmarkInfo_id);
    } else {
      rateCardDetailHandler(item.rateCardInfo_id);
      setIsViewAllTab(true);
    }
  };

  return (
    <div className={styles.recentWorksContainer}>
      {/* Heading  */}
      <div className={styles.topHeading}>
        <div className={styles.left}>
          <div className={styles.displayName}>Recent Projects</div>
          {/* <div className={styles.welcomeNote}>
            Mentioning below the detailed view for the benchmarks and rate cards
            for the recent works under your profile!
          </div> */}
        </div>
        <div className={styles.right}>
          <div
            className={styles.headingIcon}
            onClick={() => dispatch(setHome("home"))}
          >
            <div className={styles.letter}>
              <MdOutlineArrowBack />
            </div>
          </div>
        </div>
      </div>

      {/* Content  */}
      <div className={styles.scrollBar}>
        {/* Latest Record  */}
        <div className={styles.lastThreeRatecards}>
          <div className={styles.heading}>Last 3 benchmark and ratecard</div>
          <div>
            {isLoading ? (
              <div className={styles.benchmarksList}>
                <LatestCardSkeleton />
              </div>
            ) : (
              <>
                {latestInfos && latestInfos.length > 0 ? (
                  <div className={styles.benchmarksList}>
                    {latestInfos.map((item, index) => (
                      <div
                        className={styles.card}
                        key={index}
                        onClick={() => LastestInfoUpdateHandler(item)}
                      >
                        <div className={styles.nameDate}>
                          <div className={styles.name}>
                            {item.benchmarkName || item.rateCardName}
                          </div>
                          <div className={styles.date}>
                            {moment(item.createdAt).format("MMM DD")}
                          </div>
                        </div>
                        <div className={styles.btn}>
                          <Button style={btnStyles}>
                            {item.benchmarkType || item.rateCardType
                              ? item.benchmarkType || item.rateCardType
                              : "Type Not Available"}
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={styles.noData}>No Latest Info Found</div>
                )}
              </>
            )}
          </div>
        </div>

        {/* Benchmark  */}
        <div>
          <div className={styles.summaryContainer}>
            <div className={styles.summaryText}>Benchmarks</div>
            {/* <div className={styles.dropDown}>
              <AgencyTypeDropDown name={"Last Week"} options={options} />
            </div> */}
            <div className={styles.icons}>
              <div className={styles.helpIcon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.08984 8.99999C9.32495 8.33166 9.789 7.7681 10.3998 7.40912C11.0106 7.05015 11.7287 6.91893 12.427 7.0387C13.1253 7.15848 13.7587 7.52151 14.2149 8.06352C14.6712 8.60552 14.9209 9.29151 14.9198 9.99999C14.9198 12 11.9198 13 11.9198 13"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 17H12.01"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div>
            {isLoading ? (
              <div className={styles.workContainer}>
                {" "}
                <RateCardSkeleton />
              </div>
            ) : userSpecificBenchmarkInfo &&
              userSpecificBenchmarkInfo.length > 0 ? (
              <div className={styles.workContainer}>
                {userSpecificBenchmarkInfo?.map((item, index) => {
                  return (
                    <WorkContainer
                      clickHandler={() =>
                        benchMarkDetailHandler(item.benchmarkInfo_id)
                      }
                      name={item.benchmarkName}
                      date={moment(item.createdAt).format("MMM DD")}
                      type={item.benchmarkType}
                      key={index}
                    />
                  );
                })}
              </div>
            ) : (
              <div className={styles.noData}>No Benchmark Found</div>
            )}
          </div>
        </div>

        {/* RateCard  */}
        <div>
          <div className={styles.summaryContainer}>
            <div className={styles.summaryText}>Ratecards</div>
            {/* <div className={styles.dropDown}>
              <AgencyTypeDropDown name={"Last Week"} options={options} />
            </div> */}
            <div className={styles.icons}>
              <div className={styles.helpIcon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.08984 8.99999C9.32495 8.33166 9.789 7.7681 10.3998 7.40912C11.0106 7.05015 11.7287 6.91893 12.427 7.0387C13.1253 7.15848 13.7587 7.52151 14.2149 8.06352C14.6712 8.60552 14.9209 9.29151 14.9198 9.99999C14.9198 12 11.9198 13 11.9198 13"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 17H12.01"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div>
            {isLoading ? (
              <div className={styles.workContainer}>
                <RateCardSkeleton />
              </div>
            ) : userSpecificRateCardInfo &&
              userSpecificRateCardInfo.length > 0 ? (
              <div className={styles.workContainer}>
                {userSpecificRateCardInfo?.map((item, index) => {
                  return (
                    <WorkContainer
                      clickHandler={() => {
                        rateCardDetailHandler(item.rateCardInfo_id);
                        setIsViewAllTab(true);
                      }}
                      name={item.rateCardName}
                      date={moment(item.createdAt).format("MMM DD")}
                      type={item.rateCardType}
                      key={index}
                    />
                  );
                })}
              </div>
            ) : (
              <div className={styles.noData}>No Benchmark Found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
