import SideNav from "./SideNav";
import Main from "./Main";
import styles from "./BenchmarkHistory.module.css";
import Summary from "./Summary/Summary";
import { useSelector } from "react-redux";
import SelectionEstimation from "../../SelectionEstimation/SelectionEstimation";
import Chat from "../../Chat/Chat";

export default function BenchmarkHistory() {
  const { name } = useSelector((state) => state.history);

  return (
    <div className={styles.history}>
      <SideNav />
      {(name === "conversation" || name === "selection") && (
        <>
          <Main />
        </>
      )}
      {name.slice(0, 4) === "step" && <SelectionEstimation />}
      {name === "summary" || name === "updateSummary" ? <Summary /> : null}
      {name === "chat" && <Chat />}
    </div>
  );
}
