import { useDispatch, useSelector } from "react-redux";
import styles from "./AgencyTypeDropDown.module.css";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { AnimatePresence, motion } from "framer-motion";
const inValids = ["", undefined, null];

export default forwardRef(function AgencyTypeDropDown(
  { name, options, setOption, initialValue, title, isDisable, nextStepTrigger },
  ref
) {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(initialValue);
  const [clicked, setClicked] = useState(false);
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return selectedOption;
      },
    };
  });

  const homeState = useSelector((state) => state.home.name);
  const navBarState = useSelector((state) => state.navBar.name);
  useEffect(() => {
    if (typeof setOption === "function") {
      const obj = {};
      obj[title] = selectedOption;
      console.log(obj);
      dispatch(setOption(obj));
    }
  }, [selectedOption]);

  return (
    <>
      <div
        className={`${styles.agencyTypeDropDown} ${
          (selectedOption &&
            (navBarState === "history" || homeState === "estimate")) ||
          (!isDisable && navBarState === "home" && homeState === "profile")
            ? styles.selected
            : ""
        }`}
        onClick={(e) => {
          !isDisable && setClicked((state) => !state);
        }}
      >
        {inValids.includes(selectedOption) ? name : selectedOption}
      </div>

      <div className={styles.dropDownIndicator}>
        <IoIosArrowDown size={22} />
      </div>

      <AnimatePresence>
        {!isDisable && clicked && (
          <motion.div
            initial={{ height: "0", opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{
              uration: 0.25,
              ease: "easeIn",
              damping: 25,
              stiffness: 500,
            }}
            className={styles.dropDownContainer}
          >
            {options && options.length > 0 ? (
              options.map((op) => {
                let label, value;
                if (typeof op === "object") {
                  label = op?.label;
                  value = op?.value;
                } else {
                  label = value = op;
                }
                return (
                  <div
                    className={styles.menuItem}
                    onClick={(e) => {
                      if (
                        typeof nextStepTrigger === "function" &&
                        value == "Yes" &&
                        label == "Yes"
                      ) {
                        nextStepTrigger();
                      }
                      setSelectedOption(value);
                      setClicked((state) => !state);
                    }}
                  >
                    <div className={styles.optionsContainer}>
                      <div className={styles.option}>
                        <div className={styles.content}>
                          <div className={styles.heading}>{label}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={styles.menuItem}>
                <div className={styles.optionsContainer}>
                  <div className={styles.option}>
                    <div className={styles.content}>
                      <div className={styles.heading}>No Item to Select</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
});
