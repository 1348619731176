import { createSlice } from "@reduxjs/toolkit";
const initialState = { name: "home" };
const navBarSlice = createSlice({
  name: "navbar",
  initialState: initialState,
  reducers: {
    setNavBar(state, action) {
      console.log(action.payload, state.name);
      state.name = action.payload;
    },
  },
});
export const { setNavBar } = navBarSlice.actions;
export default navBarSlice.reducer;
