import styles from "./BenchmarkAnalysis.module.css";
import StickyHeadTable from "../../../Table/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "../../../Button";
import { Link } from "react-router-dom";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  plugins,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  benchmarkStatsThunkMiddleware,
  recentBenchmarkThunkMiddleware,
} from "../../../../redux/features/Admin";
import TableSkeleton from "../Home/TableSkeleton";
import { Typography } from "@mui/material";
ChartJS.register(ArcElement, Tooltip, Legend);

export const charts = [
  {
    name: "Geography",
    data: {
      labels: [
        "Technology",
        "Finance",
        "Entertainment",
        "Healthcare",
        "Retail",
      ],
      datasets: [
        {
          label: "# of Registered Industries",
          data: [20, 20, 20, 20, 20],
          backgroundColor: [
            "#374CE7",
            "#EFF0FB",
            "#D7D9F4",
            "#B6BBEC",
            "#9AA0E5",
          ],
          borderColor: ["white", "white", "white", "white", "white"],
          borderWidth: 1,
        },
      ],
    },
  },
  {
    name: "Industry Regulation",
    data: {
      labels: ["High Regulation", "Medium Regulation", "Limited Regulation"],
      datasets: [
        {
          label: "# of Registered Industries",
          data: [40, 25, 30],
          backgroundColor: [
            "#374CE7",
            "#EFF0FB",
            "#D7D9F4",
            "#B6BBEC",
            "#9AA0E5",
          ],
          borderColor: ["white", "white", "white"],
          borderWidth: 1,
        },
      ],
    },
  },
  {
    name: "Budget",
    data: {
      labels: ["Editorial", "Marketing", "Finance", "Design", "Development"],
      datasets: [
        {
          label: "# of Registered Industries",
          data: [20, 20, 20, 20, 20],
          backgroundColor: [
            "#374CE7",
            "#EFF0FB",
            "#D7D9F4",
            "#B6BBEC",
            "#9AA0E5",
          ],
          borderColor: ["white", "white", "white", "white", "white"],
          borderWidth: 1,
        },
      ],
    },
  },
  {
    name: "Campaign Ambition",
    data: {
      labels: [
        "Small and targeted audience",
        "Broad reach with high engagement",
        "Extreme reach, maximum engagement",
        "Moderate exposure and engagement",
        "Viral potential and maximum impact",
      ],
      datasets: [
        {
          label: "# of Registered Industries",
          data: [35, 5, 15, 25, 20],
          backgroundColor: [
            "#374CE7",
            "#EFF0FB",
            "#D7D9F4",
            "#B6BBEC",
            "#9AA0E5",
          ],
          borderColor: ["white", "white", "white", "white", "white"],
          borderWidth: 1,
        },
      ],
    },
  },
  {
    name: "Agency Type",
    data: {
      labels: [
        "Tier 1 - World Class Agency",
        "Tier 2 - Good Agency",
        "Tier 3 - Local Good Quality Agency",
      ],
      datasets: [
        {
          label: "# of Registered Industries",
          data: [40, 25, 35],
          backgroundColor: ["#374CE7", "#EFF0FB", "#D7D9F4"],
          borderColor: ["white", "white", "white"],
          borderWidth: 1,
        },
      ],
    },
  },
  {
    name: "Market Type",
    data: {
      labels: ["Primary Market", "Secondary Market", "Global"],
      datasets: [
        {
          label: "# of Registered Industries",
          data: [25, 40, 35],
          backgroundColor: ["#374CE7", "#EFF0FB", "#D7D9F4"],
          borderColor: ["white", "white", "white"],
          borderWidth: 1,
        },
      ],
    },
  },
];

const columns = [
  { id: "serialNumber", label: "Sr. No.", minWidth: "33%", align: "center" },
  { id: "name", label: "Name of the user", minWidth: "33%", align: "center" },
  {
    id: "geography",
    label: "Geography",
    minWidth: "33%",
    align: "center",
  },
  {
    id: "industry",
    label: "Industry Regulation",
    minWidth: "33%",
    align: "center",
  },
  { id: "budget", label: "Budget", minWidth: "33%", align: "center" },
  {
    id: "campaignAmbition",
    label: "Campaign Ambition",
    minWidth: "33%",
    align: "center",
  },
  {
    id: "agencyType",
    label: "Agency Type",
    minWidth: "33%",
    align: "center",
  },
  {
    id: "marketType",
    label: "Market Type",
    minWidth: "33%",
    align: "center",
  },
  {
    id: "logFileUrl",
    label: "Calculation Log",
    minWidth: "33%",
    align: "center",
  },
];

function createData(
  serialNumber,
  name,
  geography,
  industry,
  budget,
  campaignAmbition,
  agencyType,
  marketType,
  logFileUrl
) {
  return {
    serialNumber,
    name,
    geography,
    industry,
    budget,
    campaignAmbition,
    agencyType,
    marketType,
    logFileUrl,
  };
}

const headingStyles = {
  color: "#FFF",
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  border: "1px solid #FFF",
  backgroundColor: "inherit",
  whiteSpace: "nowrap",
};
const cellStyles = {
  color: "rgba(255, 255, 255, 0.50)",
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  border: "1px solid rgba(255, 255, 255, 0.50)",
  backgroundColor: "inherit",
  whiteSpace: "nowrap",
};
const downloadBtnStyles = {
  borderRadius: "8px",
  backgroundColor: "#374CE7",
  padding: "8px 24px",
  color: "#FFFFFF",
  border: "none",
  fontSize: "12px",
  fontWeight: 700,
};

export default function BechmarkAnalysis() {
  const dispatch = useDispatch();
  const { benchmarkStats, recentBenchmark } = useSelector(
    (state) => state.admin
  );
  const { isLoading } = useSelector((state) => state.loader);

  const CreateRows = (recentBenchmark) => {
    const rows = [];
    if (recentBenchmark) {
      recentBenchmark.forEach((element, index) => {
        rows.push(
          createData(
            index + 1,
            element?.name,
            element?.globalOrRegional,
            element?.industry,
            `${(+element?.budget).toLocaleString("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`,
            element?.ambition,
            element?.agencyType,
            element?.intendedUse,
            element.BenchmarkInfos[0]?.logFileUrl
          )
        );
      });
    }
    return rows;
  };

  const charts = [
    {
      name: "Geography",
      data: {
        labels:
          benchmarkStats &&
          benchmarkStats.globalOrRegional &&
          benchmarkStats.globalOrRegional.length > 0
            ? benchmarkStats.globalOrRegional.map((item) => item.value)
            : [],
        datasets: [
          {
            label: "# of Geography",
            data:
              benchmarkStats &&
              benchmarkStats.globalOrRegional &&
              benchmarkStats.globalOrRegional.length > 0
                ? benchmarkStats.globalOrRegional.map((item) => item.percentage)
                : [],
            backgroundColor: ["#374CE7", "#EFF0FB"],
            borderColor: "white",
            borderWidth: 1,
          },
        ],
      },
    },
    {
      name: "Campaign Ambition",
      data: {
        labels:
          benchmarkStats &&
          benchmarkStats.ambition &&
          benchmarkStats.ambition.length > 0
            ? benchmarkStats.ambition.map((item) => item.value)
            : [],
        datasets: [
          {
            label: "# of Campaign Ambition",
            data:
              benchmarkStats &&
              benchmarkStats.ambition &&
              benchmarkStats.ambition.length > 0
                ? benchmarkStats.ambition.map((item) => item.percentage)
                : [],
            backgroundColor: [
              "#374CE7",
              "#EFF0FB",
              "#D7D9F4",
              "#B6BBEC",
              "#9AA0E5",
            ],
            borderColor: "white",
            borderWidth: 1,
          },
        ],
      },
    },
    {
      name: "Agency Tier",
      data: {
        labels:
          benchmarkStats &&
          benchmarkStats.tier &&
          benchmarkStats.tier.length > 0
            ? benchmarkStats.tier.map((item) => item.value)
            : [],
        datasets: [
          {
            label: "# of Agency Tier",
            data:
              benchmarkStats &&
              benchmarkStats.tier &&
              benchmarkStats.tier.length > 0
                ? benchmarkStats.tier.map((item) => item.percentage)
                : [],
            backgroundColor: ["#374CE7", "#EFF0FB", "#D7D9F4"],
            borderColor: ["white", "white", "white"],
            borderWidth: 1,
          },
        ],
      },
    },
    {
      name: "Industry Regulation",
      data: {
        labels:
          benchmarkStats &&
          benchmarkStats.intendedUse &&
          benchmarkStats.intendedUse.length > 0
            ? benchmarkStats.intendedUse.map((item) => item.value)
            : [],
        datasets: [
          {
            label: "# of Industry Regulation",
            data:
              benchmarkStats &&
              benchmarkStats.intendedUse &&
              benchmarkStats.intendedUse.length > 0
                ? benchmarkStats.intendedUse.map((item) => item.percentage)
                : [],
            backgroundColor: [
              "#374CE7",
              "#EFF0FB",
              "#D7D9F4",
              "#B6BBEC",
              "#9AA0E5",
            ],
            borderColor: ["white", "white", "white"],
            borderWidth: 1,
          },
        ],
      },
    },
    {
      name: "Agency Type",
      data: {
        labels:
          benchmarkStats &&
          benchmarkStats.agencyType &&
          benchmarkStats.agencyType.length > 0
            ? benchmarkStats.agencyType.map((item) => item.value)
            : [],
        datasets: [
          {
            label: "# of Agency Type",
            data:
              benchmarkStats &&
              benchmarkStats.agencyType &&
              benchmarkStats.agencyType.length > 0
                ? benchmarkStats.agencyType.map((item) => item.percentage)
                : [],
            backgroundColor: [
              "#374CE7",
              "#EFF0FB",
              "#D7D9F4",
              "#B6BBEC",
              "#9AA0E5",
              "#9AB0E6",
              "#9AB0E6",
            ],
            borderColor: "white",
            borderWidth: 1,
          },
        ],
      },
    },

    // {
    //   name: "Budget",
    //   data: {
    //     labels: ["Editorial", "Marketing", "Finance", "Design", "Development",],
    //     datasets: [
    //       {
    //         label: "# of Budget",
    //         data: [20, 20, 20, 20, 20, 20, 20, 20],
    //         backgroundColor: [
    //           "#374CE7",
    //           "#EFF0FB",
    //           "#D7D9F4",
    //           "#B6BBEC",
    //           "#9AA0E5",
    //         ],
    //         borderColor: ["white", "white", "white", "white", "white"],
    //         borderWidth: 1,
    //       },
    //     ],
    //   },
    // },
  ];

  useEffect(() => {
    dispatch(benchmarkStatsThunkMiddleware());
    dispatch(recentBenchmarkThunkMiddleware());
  }, []);

  return (
    <>
      <div className={styles.main}>
        {/* Heading */}
        <div className={styles.topHeading}>
          <div className={styles.left}>
            <div className={styles.displayName}>Benchmark Analysis</div>
          </div>
        </div>
        <div className={styles.topic}>
          <div className={styles.displayName}>Hi Vlad,</div>
          <div className={styles.welcomeNote}>
            Welcome to the Admin Dashboard, where you have complete control over
            the AI marketing platform.
          </div>
        </div>

        <div className={styles.mainContainer}>
          {/* Charts  */}
          <div className={styles.charts}>
            {charts.map(({ name, data }) => {
              return (
                <div className={styles.chart}>
                  <div className={styles.chartName}>{name}</div>
                  <Pie
                    data={data}
                    options={{
                      plugins: {
                        legend: {
                          position: "right",
                        },
                      },
                      responsive: false,
                      maintainAspectRatio: true,
                    }}
                  />
                </div>
              );
            })}
          </div>

          {/* Table  */}
          <div className={styles.benchMarkList}>
            <div className={styles.heading}>
              <div className={styles.text}>Recent benchmarks</div>
              <div className={styles.downloadBtn}>
                <Button style={downloadBtnStyles}>Download as CSV</Button>
              </div>
            </div>
            <StickyHeadTable>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={headingStyles}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableSkeleton col={8} />
                ) : (
                  <>
                    {recentBenchmark ? (
                      CreateRows(recentBenchmark).map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              console.log(value);
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={cellStyles}
                                >
                                  {column.id === "logFileUrl" ? (
                                    <Link
                                      to={value}
                                      className={styles.downloadBtn}
                                    >
                                      <Button style={downloadBtnStyles}>
                                        Download
                                      </Button>
                                    </Link>
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })
                    ) : (
                      <>
                        <TableRow>
                          <TableCell colSpan={8} style={cellStyles}>
                            <Typography style={{ textAlign: "center" }}>
                              No Benchmark to show
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </>
                )}
              </TableBody>
            </StickyHeadTable>
          </div>
        </div>
      </div>
    </>
  );
}
