import styles from "./Home.module.css";
import Button from "../../Button";
import WorkContainer from "../WorkContainer";
import SideNav from "../SideNav/SideNav";
import { setHome } from "../../../redux/features/Home";
import EstimateRateCard from "../EstimateRateCard/EstimateRateCard";
import { useDispatch, useSelector } from "react-redux";
import RecentWorks from "../RecentWorks/RecentWorks";
import { setNavBar } from "../../../redux/features/NavBar";
import { useEffect, useState } from "react";
import { getRatecardInfoByIdThunkMiddleware } from "../../../redux/features/RatedcardDetails";
import moment from "moment";
import { getBenchmarkInfoByIdThunkMiddleware } from "../../../redux/features/BenchmarkDetails";
import { getDashboardDataThunkMiddleware } from "../../../redux/features/Dashboard Stats";
import Result from "../EstimateRateCard/Result";
import RateCardSkeleton from "./RateCardSkeleton";
import { setHistory, setUpdateHistory } from "../../../redux/features/History";
import { MdLogout } from "react-icons/md";
import {
  getProfileThunkMiddleware,
  logoutThunkMiddleware,
} from "../../../redux/features/Profile";
import Profile from "../Profile/Profile";
import { MdOutlineQuestionMark } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ratecardLogo from "../../../assets/images/dashboard/1.png";
import benchmarkLogo from "../../../assets/images/dashboard/2.png";
import toast from "react-hot-toast";
import { getOptionListsThunkMiddleware } from "../../../redux/features/Miscellaneous/Index";

const actionButtonStyle = {
  color: "#3788E7",
  fontFamily: "Montserrat",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 900,
  borderRadius: "10px",
  backgroundColor: "#F5F5F5",
  minHeight: "9rem",
  padding: "10px",
};

export default function Home() {
  const [isViewAllTab, setIsViewAllTab] = useState(false);
  const [rateCardState, setRateCardState] = useState("form");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const homeState = useSelector((state) => state.home.name);
  const { isLoading } = useSelector((state) => state.loader);
  const { recentWorks } = useSelector((state) => state.dashboardStats);

  const { name } = useSelector((state) => state.profile);

  const rateCardDetailHandler = (rateCardInfo_id) => {
    dispatch(
      getRatecardInfoByIdThunkMiddleware(
        {
          rateCardInfo_id,
        },
        (error) => {
          if (!error) dispatch(setHome("rateCardDetail"));
        }
      )
    );
  };

  const benchMarkDetailHandler = (benchmarkInfo_id) => {
    dispatch(
      getBenchmarkInfoByIdThunkMiddleware(
        {
          benchmarkInfo_id,
        },
        (error) => {
          if (!error) {
            dispatch(setNavBar("history"));
            dispatch(setUpdateHistory("updateSummary"));
            dispatch(setHistory("updateSummary"));
          }
        }
      )
    );
  };

  const logoutHandler = () => {
    dispatch(logoutThunkMiddleware());
    toast.success("Logout Successfully");
  };

  useEffect(() => {
    const getDetails = () => {
      // dispatch(getUserSpecificRatecardInfoThunkMiddleware());
      // dispatch(getUserSpecificBenchmarkInfoThunkMiddleware());
      dispatch(getDashboardDataThunkMiddleware());
      dispatch(getProfileThunkMiddleware());
    };
    if (homeState === "home") {
      getDetails();
    }
  }, [homeState, dispatch]);

  useEffect(() => {
    dispatch(getOptionListsThunkMiddleware());
  }, []);

  return (
    <>
      <SideNav />
      {homeState === "home" && (
        <>
          <div className={styles.main}>
            <div className={styles.topHeading}>
              <div className={styles.left}>
                <div className={styles.displayName}>
                  Hi {name ? name.split(" ")[0] : "User"},
                </div>
                {/* <div className={styles.welcomeNote}>
                  Let's embark on an exciting journey of precision marketing
                  together!
                </div> */}
              </div>
              <div className={styles.right}>
                <div
                  className={styles.profileIcon}
                  onClick={() => {
                    dispatch(setHome("profile"));
                  }}
                >
                  <div className={styles.letter}>{name ? name[0] : "A"}</div>
                </div>
                <div className={styles.profileIcon} onClick={logoutHandler}>
                  <div className={styles.letter}>
                    <MdLogout />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.actionBtn}>
              <Button
                style={actionButtonStyle}
                onClick={(e) => {
                  dispatch(setNavBar("history"));
                }}
              >
                <div className={styles.btnText}>
                  <p>Let's get to work -</p>
                  <h4>COST SCOPE OF WORK </h4>
                </div>
                <img
                  src={benchmarkLogo}
                  alt="benchmarkLogo"
                  style={{
                    height: "40px",
                    width: "36px",
                  }}
                />
              </Button>
              <Button
                style={{ ...actionButtonStyle, color: "#A623F7" }}
                onClick={(e) => {
                  dispatch(setHome("estimate"));
                }}
              >
                <div className={styles.btnText}>
                  <p>Let's Start -</p>
                  <h5>BENCHMARK AGENCY RATECARD</h5>
                </div>
                <img
                  src={ratecardLogo}
                  alt="benchmarkLogo"
                  style={{
                    height: "40px",
                    width: "36px",
                  }}
                />
              </Button>
              <Button
                style={{ ...actionButtonStyle, color: "#373737" }}
                onClick={(e) => {
                  navigate("/");
                }}
              >
                <div className={styles.btnText}>
                  <p>Not sure where to start -</p>
                  <h5>Let Us Help You Decide</h5>
                </div>
                <MdOutlineQuestionMark size={32} />
              </Button>
            </div>

            <div className={styles.recentWork}>
              <div className={styles.heading}>
                <div className={styles.text1}>Recent Projects</div>
                <div
                  className={styles.text2}
                  onClick={() => {
                    dispatch(setHome("viewAll"));
                  }}
                >
                  View All
                </div>
              </div>

              <div className={styles.benchMarks}>Benchmarks</div>

              {isLoading ? (
                <>
                  <RateCardSkeleton />
                </>
              ) : (
                <>
                  <div className={styles.container}>
                    {recentWorks &&
                      recentWorks.length > 0 &&
                      recentWorks?.map((item, index) =>
                        item.type === "benchmark" ? (
                          <WorkContainer
                            clickHandler={() =>
                              benchMarkDetailHandler(item.benchmarkInfo_id)
                            }
                            color="#3788E7"
                            key={index}
                            name={item.benchmarkName}
                            date={moment(item.createdAt).format("MMM DD")}
                            type={item.benchmarkType}
                          />
                        ) : (
                          <WorkContainer
                            clickHandler={() =>
                              rateCardDetailHandler(item.rateCardInfo_id)
                            }
                            color="#A623F7"
                            key={index}
                            name={item.rateCardName}
                            date={moment(item.createdAt).format("MMM DD")}
                            type={item.rateCardType}
                          />
                        )
                      )}
                  </div>
                </>
              )}

              {/* No Data Found  */}
              {!recentWorks && (
                <div className={styles.noData}>No Benchmark Found</div>
              )}
            </div>
          </div>
        </>
      )}

      {homeState === "estimate" && (
        <EstimateRateCard
          rateCardState={rateCardState}
          setRateCardState={setRateCardState}
        />
      )}
      {homeState === "viewAll" && (
        <RecentWorks
          setIsViewAllTab={setIsViewAllTab}
          rateCardDetailHandler={rateCardDetailHandler}
          benchMarkDetailHandler={benchMarkDetailHandler}
        />
      )}
      {homeState === "profile" && <Profile />}
      {homeState === "rateCardDetail" && (
        <Result isViewAllTab={isViewAllTab} setIsViewAllTab={setIsViewAllTab} />
      )}
    </>
  );
}
