import { useSelector } from "react-redux";
import Button from "../../../Button";
import styles from "./Right.module.css";
import { setBenchmark } from "../../../../redux/features/BenchmarkDetails";
import InputTextButton from "../../../InputTextButton";
import { CircularProgress } from "@mui/material";
import { filterDeliverablesDetails } from "./Column";

const saveBtnStyles = {
  borderRadius: "8px",
  backgroundColor: "#374CE7",
  fontSize: "16px",
  fontWeight: "700",
  color: "#FFF",
  border: "none",
};

export default function Right() {
  const { isLoading } = useSelector((state) => state.loader);

  const {
    benchmarkDetail_id,
    totalBenchmarkValue,
    benchmarkCurrencySymbol,
    benchmarkName,
    globalOrRegional,
    industry,
    b2bOrB2c,
    location,
    agencyType,
    tier,
    ambition,
    intendedUse,
    budget,
    scope,
    primaryMarketsCount,
    benchmarkInfo_id,
    totalHours,
    currencyId,
    deliverableDetails,
  } = useSelector((state) => state.benchmarkDetails);

  const handleExport = () => {
    const csv = convertToCSV(filterDeliverablesDetails(deliverableDetails));
    downloadCSV(csv, `${benchmarkName}.csv`);
  };

  function convertToCSV(data) {
    let csv = "";

    const data1 = {
      benchmarkDetail_id,
      totalBenchmarkValue,
      benchmarkCurrencySymbol,
      benchmarkName,
      globalOrRegional,
      industry,
      b2bOrB2c,
      location,
      agencyType,
      tier,
      ambition,
      intendedUse,
      budget,
      scope,
      primaryMarketsCount,
      benchmarkInfo_id,
      totalHours,
      currencyId,
    };

    // Adding headers for benchmark details
    csv += "\n***** BENCHMARK DETAILS *****\n";

    Object.keys(data1).forEach((key, index) => {
      csv += `${key.toUpperCase()},${data1[key]}\n`;
    });

    // csv +=
    //   "BENCHMARK DETAIL ID,TOTAL BENCHMARK VALUE,BENCHMARK CURRENCY SYMBOL,BENCHMARK NAME,GLOBAL OR REGIONAL,INDUSTRY,B2B OR B2C,LOCATION,AGENCY TYPE,TIER,AMBITION,INTENDED USE,BUDGET,SCOPE,PRIMARY MARKETS COUNT,BENCHMARK INFO ID,TOTAL HOURS,CURRENCY ID\n";

    // csv += `${benchmarkDetail_id},${totalBenchmarkValue},${benchmarkCurrencySymbol},${benchmarkName},${globalOrRegional},${industry},${b2bOrB2c},${location},${agencyType},${tier},${ambition},${intendedUse},${budget},${scope},${primaryMarketsCount},${benchmarkInfo_id},${totalHours},${currencyId}\n\n`;

    // Adding headers for deliverbales details
    csv += "\n***** DETAILED DELIVERABLES *****\n";
    csv +=
      "CATEGORY,DELIVERABLE,SUBDELIVERABLE,COMPLEXITY,TOTAL NUMBERS,NUMBERS OF HOURS,COST\n";

    // Iterating over data
    data.forEach((category) => {
      category.deliverables.forEach((deliverable) => {
        deliverable.subDeliverables.forEach((subDeliverable) => {
          // Replace commas in subdeliverable with semicolon to avoid splitting into multiple columns
          const subDeliverableName = subDeliverable.subDeliverable.replace(
            ",",
            ";"
          );
          csv += `${category.category},${deliverable.deliverable},"${subDeliverableName}",${subDeliverable.complexity},${subDeliverable.number},${subDeliverable.hours},${subDeliverable.cost}\n`;
        });
      });
    });

    return csv;
  }

  function downloadCSV(csv, filename) {
    const csvFile = new Blob([csv], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  return (
    <>
      <div className={styles.heading}>Benchmark</div>
      <div className={styles.amountBox}>
        <div className={styles.text}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              {totalBenchmarkValue
                ? `${benchmarkCurrencySymbol} ${(+totalBenchmarkValue).toLocaleString(
                    "en-US",
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                  )}`
                : "No Benchmark Value"}
            </>
          )}
        </div>
      </div>
      <div className={styles.value}>Total Benchmark Value</div>
      <div className={styles.desc}>
        <div className={styles.highlitedText}>
          Since status of one or more deliverables were changed, the estimated
          results might differ from the real market value.
        </div>
        <div className={styles.normalText}>
          Based on the selected options, the estimated benchmark for the overall
          campaign is{" "}
          {isLoading
            ? "__"
            : `${benchmarkCurrencySymbol}${(+totalBenchmarkValue).toLocaleString(
                "en-US",
                { minimumFractionDigits: 0, maximumFractionDigits: 0 }
              )}`}
          . The cost of production would be impacted if the geography is
          changed:
        </div>
        <div className={styles.inputBoxContainer}>
          <div className={styles.inputLabel}>Benchmark Name:</div>
          <div className={styles.inputBox}>
            <InputTextButton
              name={"Enter Benchmark name"}
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.20)",
                border: "1px solid rgba(255, 255, 255, 0.50)",
              }}
              setValue={setBenchmark}
              initialValue={benchmarkName}
              title={"benchmarkName"}
            />
          </div>
        </div>
        <div className={styles.downloadBtn}>
          <Button onClick={handleExport} style={saveBtnStyles}>
            Download
          </Button>
        </div>
      </div>
    </>
  );
}
