import styles from "./Summary.module.css";
import Button from "../../../Button";
import Right from "./Right";
import { useDispatch, useSelector } from "react-redux";
import {
  setHistory,
  setUpdateHistory,
} from "../../../../redux/features/History/index";
import Row from "./Row";
import {
  initialState,
  postBenchmarkDetailsAndInfoThunkMiddleware,
  setBenchmark,
  updateBenchmarkDetailsAndInfoThunkMiddleware,
} from "../../../../redux/features/BenchmarkDetails";
import { setNavBar } from "../../../../redux/features/NavBar";
import { CircularProgress } from "@mui/material";
import StrategyTable from "./StrategyTable";
import { filterDeliverablesDetails } from "./Column";

const saveBtnStyles = {
  borderRadius: "8px",
  backgroundColor: "#374CE7",
  fontSize: "16px",
  fontWeight: "700",
  color: "#FFF",
  border: "none",
};

const recalcBtnStyles = {
  borderRadius: "8px",
  border: "1px solid #FFF",
  backgroundColor: "inherit",
  fontSize: "16px",
  fontWeight: "400",
  color: "#FFF",
};

export default function Summary() {
  const dispatch = useDispatch();

  const {
    source,
    globalOrRegional,
    industry,
    b2bOrB2c,
    location,
    agencyType,
    tier,
    ambition,
    intendedUse,
    budget,
    scope,
    deliverableType,
    benchmarkName,
    primaryMarketsCount,
    benchmarkInfo_id,
    benchmarkDetail_id,
    totalBenchmarkValue,
    benchmarkCurrencySymbol,
    totalHours,
    hoursAndRoleInfo,
    currencyId,
    deliverableDetails,
    logFileUrl,
  } = useSelector((state) => state.benchmarkDetails);

  const { name, update } = useSelector((state) => state.history);
  const { isSaveLoading, isUpdateLoading } = useSelector(
    (state) => state.loader
  );

  const BenchmarkDetails = [
    {
      name: "",
      label: "Geography",
      initialValue: globalOrRegional,
      disable: true,
    },
    { name: "", label: "Location", initialValue: location, disable: true },
    { name: "", label: "B2B OR B2C", initialValue: b2bOrB2c, disable: true },
    { name: "", label: "Industry", initialValue: industry, disable: true },
    { name: "", label: "Market Type", initialValue: tier, disable: true },
    {
      name: "",
      label: "Agency's Type",
      initialValue: agencyType,
      disable: true,
    },
    {
      name: "",
      label: "Agency's Calibre",
      initialValue: globalOrRegional,
      disable: true,
    },
    {
      name: "",
      label: "Campaign Ambition",
      initialValue: ambition,
      disable: true,
    },
    {
      name: "",
      label: "Development Type",
      initialValue: intendedUse,
      disable: true,
    },
    {
      name: "",
      label: "Primary Markets Count",
      initialValue: primaryMarketsCount,
      disable: true,
    },
    {
      name: "",
      label: "Budget",
      initialValue: budget
        ? (+budget).toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        : "No Budget",
      disable: true,
    },
    { name: "", label: "Scope", initialValue: scope, disable: true },
    { name: "", label: "Source", initialValue: source, disable: true },
  ];

  const SaveUpdateBenchmarkHandler = () => {
    if (name === "summary" && update !== "updateSummary") {
      dispatch(
        postBenchmarkDetailsAndInfoThunkMiddleware(
          {
            source,
            globalOrRegional,
            industry,
            b2bOrB2c,
            location,
            agencyType,
            tier,
            ambition,
            intendedUse,
            budget,
            primaryMarketsCount,
            scope,
            deliverableType,

            totalBenchmarkValue,
            benchmarkCurrencySymbol,
            totalHours,
            hoursAndRoleInfo,
            currencyId,
            benchmarkName,
            logFileUrl,
            deliverableDetails,
          },
          (error) => {
            if (!error) {
              dispatch(setHistory("conversation"));
              dispatch(setNavBar("home"));
            }
          }
        )
      );
    } else if (update === "updateSummary") {
      dispatch(
        updateBenchmarkDetailsAndInfoThunkMiddleware(
          {
            globalOrRegional,
            industry,
            b2bOrB2c,
            location,
            agencyType,
            tier,
            ambition,
            intendedUse,
            budget,
            scope,
            primaryMarketsCount,
            deliverableType,
            benchmarkDetail_id,

            totalBenchmarkValue,
            benchmarkCurrencySymbol,
            totalHours,
            hoursAndRoleInfo,
            currencyId,
            deliverableDetails,

            benchmarkName,
            benchmarkInfo_id,
          },
          (error) => {
            if (!error) {
              dispatch(setHistory("conversation"));
              dispatch(setNavBar("home"));
              dispatch(setUpdateHistory(null));
            }
          }
        )
      );
    }
  };

  return (
    <>
      {/* <SideNav /> */}
      <div className={styles.mainSummary}>
        <div className={styles.heading}>
          {/* <div className={styles.text}>Summary Marketing Estimation</div> */}
          <div className={styles.text}>Benchmark Breakdown</div>
        </div>
        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.heading}>Budget and Schedule</div>
            <div className={styles.text}>
              If the asset is developed across different geographies, here are
              the following estimates:
            </div>
            <div className={styles.box}>
              {BenchmarkDetails.map((item, index) => (
                <Row
                  key={index}
                  name={item.local}
                  label={item.label}
                  initialValue={item.initialValue}
                  disable={item.disable}
                />
              ))}

              <div className={styles.tableStyle}>
                {filterDeliverablesDetails(deliverableDetails) &&
                  filterDeliverablesDetails(deliverableDetails).map(
                    (item, index) => {
                      return (
                        <StrategyTable
                          key={index}
                          data={item}
                          benchmarkCurrencySymbol={benchmarkCurrencySymbol}
                        />
                      );
                    }
                  )}
              </div>
            </div>

            <div className={styles.buttonBox}>
              {source === "Selection" && (
                <div
                  className={styles.backBtn}
                  onClick={(e) => {
                    dispatch(setHistory("step5"));
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M9 14L4 9L9 4"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20 20V13C20 11.9391 19.5786 10.9217 18.8284 10.1716C18.0783 9.42143 17.0609 9 16 9H4"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              )}

              {update === "updateSummary" && (
                <div
                  className={styles.recalculateBtn}
                  onClick={(e) => {
                    dispatch(setHistory("conversation"));
                    dispatch(setNavBar("home"));
                    dispatch(setUpdateHistory(null));
                    dispatch(setBenchmark(initialState));
                  }}
                >
                  <Button style={recalcBtnStyles}>Cancel</Button>
                </div>
              )}

              <div
                className={styles.recalculateBtn}
                onClick={(e) => {
                  if (source === "Selection") {
                    dispatch(setHistory("step1"));
                  } else {
                    dispatch(setHistory("chat"));
                  }
                }}
              >
                <Button style={recalcBtnStyles}>Recalculate</Button>
              </div>

              <div className={styles.saveBtn}>
                <Button
                  style={saveBtnStyles}
                  onClick={SaveUpdateBenchmarkHandler}
                  disable={isUpdateLoading}
                >
                  {isSaveLoading || isUpdateLoading ? (
                    <CircularProgress size={30} color="inherit" />
                  ) : update === "updateSummary" ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </div>
          </div>

          {/*Total Benchamrk Result  */}
          <div className={styles.right}>
            <Right />
          </div>
        </div>
      </div>
    </>
  );
}
