import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../Button";
import styles from "./Modal.module.css";
import { TiInfoLarge } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { setDeliverables } from "../../../redux/features/BenchmarkDetails";

const saveBtnStyles = {
  height: "2.8rem",
  fontWeight: "700",
  backgroundColor: "#374CE7",
  border: "0",
};
const cancelBtnStyles = {
  height: "2.8rem",
  fontWeight: "400",
  backgroundColor: "inherit",
};

const Modal = ({
  modalopen,
  modalCloseHandler,
  initialValue,
  title,
  setClicked,
  deliverableTypeData,
  deleteRefresh,
}) => {
  const [subdeliverableValue, setSubdeliverableValue] = useState(null);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setSubdeliverableValue((prev) => {
      const updatedState = { ...prev };
      if (value) {
        updatedState[name] = value;
      } else {
        delete updatedState[name];
      }
      return updatedState;
    });
  };

  const dispatch = useDispatch();
  const { type, subType, name } = deliverableTypeData;

  const submitHandler = () => {
    if (subdeliverableValue && Object.keys(subdeliverableValue).length !== 0) {
      const obj = {
        type,
        subType,
        name,
        value:
          Object.keys(subdeliverableValue).length === 0
            ? null
            : subdeliverableValue,
      };
      dispatch(setDeliverables(obj));
      modalCloseHandler();
      setClicked((prev) => true);
    }
  };

  useEffect(() => {
    setSubdeliverableValue(null);
  }, [deleteRefresh]);

  useEffect(() => {
    console.log("Update");
    setSubdeliverableValue(initialValue ? initialValue : null);
  }, []);

  return (
    <Dialog
      open={modalopen}
      onClose={modalCloseHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={styles.cancelModal}>
        {/* Heading  */}
        <h1 className={styles.name}>{title}</h1>
        {/* Content  */}
        <div className={styles.modalContent}>
          <div className={styles.textFieldGroup}>
            <div className={styles.lableContainer}>
              <p className={styles.labelName}>High</p>
              <p className={styles.labelIcon}>
                <TiInfoLarge />
              </p>
            </div>
            <input
              type="number"
              className={styles.inputText}
              name="High"
              style={{
                backgroundColor: "transparent",
                width: "150px",
              }}
              placeholder={"Enter Number"}
              onChange={changeHandler}
              value={subdeliverableValue?.High}
            />
          </div>

          <div className={styles.textFieldGroup}>
            <div className={styles.lableContainer}>
              <p className={styles.labelName}>Medium</p>
              <p className={styles.labelIcon}>
                <TiInfoLarge />
              </p>
            </div>
            <input
              type="number"
              name="Medium"
              className={styles.inputText}
              style={{
                backgroundColor: "transparent",
                width: "150px",
              }}
              placeholder={"Enter Number"}
              onChange={changeHandler}
              value={subdeliverableValue?.Medium}
            />
          </div>

          <div className={styles.textFieldGroup}>
            <div className={styles.lableContainer}>
              <p className={styles.labelName}>Low</p>
              <p className={styles.labelIcon}>
                <TiInfoLarge />
              </p>
            </div>
            <input
              type="number"
              name="Low"
              className={styles.inputText}
              style={{
                backgroundColor: "transparent",
                width: "150px",
              }}
              placeholder={"Enter Number"}
              onChange={changeHandler}
              value={subdeliverableValue?.Low}
            />
          </div>
        </div>
        {/* Buttom  */}
        <div className={styles.buttonBox}>
          <div className={styles.cancelBtn}>
            <Button
              onClick={() => {
                modalCloseHandler();
              }}
              style={cancelBtnStyles}
            >
              Cancel
            </Button>
          </div>
          <div className={styles.saveBtn}>
            <Button style={saveBtnStyles} onClick={submitHandler}>
              Save
            </Button>
          </div>
        </div>{" "}
      </div>
    </Dialog>
  );
};

export default Modal;
