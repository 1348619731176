import LeftPage from "./LeftPage";
import RightPage from "./RightPage";
import styles from "./Auth.module.css";
import Logo from "../../Logo/Logo";
export default function Auth() {

  return (
    <div className={styles.authPage}>
      <Logo />
      <LeftPage />
      <RightPage />
    </div>
  );
}
