import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axiosInstance";
import toast from "react-hot-toast";
import { setLoader } from "../Loader";

const initialState = {
    message: null,
};

const supportSlice = createSlice({
    name: "supportSlice",
    initialState: initialState,
    reducers: {
        setSupport(state, action) {
            Object.keys(action.payload).forEach((key) => {
                state[key] = action.payload[key];
            });
        },
    },
});

export const { setSupport } = supportSlice.actions;

export default supportSlice.reducer;

//Contact Us - Sending Message
export const sendMessageThunkMiddleware = ({ message }) => {
    return async (dispatch) => {
        try {
            if (!message) {
                return toast.error("Please Enter Message");
            }
            dispatch(setLoader(true));
            const response = await axios({
                method: "POST",
                url: process.env.REACT_APP_SEND_MESSAGE,
                data: { message }
            });
            if (response.status === 201) {
                toast.success("Message Send Successfully, We will contact you Soon");
                dispatch(setSupport(initialState));
            }
        } catch (error) {
            let message = "ERROR";
            if (error.hasOwnProperty("response")) {
                message = error.response.data.message;
            } else {
                message = error.message;
            }
            toast.error(message);
        } finally {
            dispatch(setLoader(false));
        }
    };
};
