import { CircularProgress } from '@mui/material';
import React from 'react';
import styles from "./CardSkeleton.module.css";
export const RateCardSkeleton = () => {


    return (
        Array(4).fill(0).map((_, index) => (
            <div className={styles.skeleton} key={index}>
                <CircularProgress style={{
                    color: "#374CE7"
                }} />
                {/* <Skeleton className={styles.skeleton} animation={"wave"} variant="overlay" width={"100%"} /> */}
            </div>

        ))
    );
};
export const LatestCardSkeleton = () => {
    return (
        Array(3).fill(0).map((_, index) => (
            <div className={styles.skeleton} key={index}>
                <CircularProgress style={{
                    color: "#374CE7"
                }} />
                {/* <Skeleton className={styles.skeleton} animation={"wave"} variant="overlay" width={"100%"} /> */}
            </div>

        ))
    );
};

