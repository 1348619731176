import styles from "./Main.module.css";
import Button from "../../Button";
import bell from "../../../assets/images/Bell.png";
import Conversation from "./Conversation";
import Selection from "./Selection";
import { useDispatch, useSelector } from "react-redux";
import SelectionEstimation from "../../SelectionEstimation/SelectionEstimation";
import { setHistory } from "../../../redux/features/History";
const selectedBtnStyles = { backgroundColor: "#374CE7", border: "none" };
const btnStyles = { backgroundColor: "inherit" };
export default function Main() {
  const dispatch = useDispatch();
  const name = useSelector((state) => state.history.name);

  return (
    <div className={styles.mainHistory}>
      <div className={styles.options}>
        <div className={styles.left}>
          <div className={styles.buttons}>
            <Button
              style={{ ...selectedBtnStyles, borderRadius: "8px" }}
              onClick={(e) => {
                dispatch(setHistory("conversation"));
              }}
            >
              Start Chatting With Alex
            </Button>
          </div>
        </div>
      </div>

      {(name === "none" || name === "conversation") && <Conversation />}
      {/* {name === "selection" && <Selection />} */}
    </div>
  );
}
