import styles from "./Step1.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setHistory } from "../../../redux/features/History/index";
import AgencyTypeDropDown from "../Step2/AgencyTypeDropDown";
import {
  initialState,
  setBenchmark,
} from "../../../redux/features/BenchmarkDetails/index";
import { MdOutlineHelpOutline } from "react-icons/md";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

export default function Step1() {
  const dispatch = useDispatch();
  const { update } = useSelector((state) => state.history);

  const { industriesList, focusList, organisationsList } = useSelector(
    (state) => state.miscellaneous
  );
  const { globalOrRegional, industry, b2bOrB2c } = useSelector(
    (state) => state.benchmarkDetails
  );

  return (
    <div className={styles.step1}>
      {/* <div className={styles.heading}>
        Fill in the questions below for Alex to extract relevant details for
        your algorithm
      </div> */}

      <div className={styles.stepNo}>Step 1 of 5</div>

      <div className={styles.about}>
        <div className={styles.text}>About the Client</div>
        <div className={styles.helpLogo}>
          <MdOutlineHelpOutline />
        </div>
      </div>

      <div className={styles.inputBox}>
        <div className={styles.inputLabel}>
          Are you part of a Global or Regional/Local organisation?
        </div>
        <div className={styles.inputText}>
          <AgencyTypeDropDown
            options={organisationsList}
            name={"Select your region"}
            setOption={setBenchmark}
            initialValue={globalOrRegional}
            title={"globalOrRegional"}
          />
        </div>
      </div>

      <div className={styles.inputLabel}>Industry & Focus</div>

      <div className={styles.inputBox}>
        <div className={styles.inputLabel}>Which industry are you in?</div>
        <div className={styles.inputText}>
          <AgencyTypeDropDown
            options={industriesList}
            name={"Select your industry"}
            setOption={setBenchmark}
            initialValue={industry}
            title={"industry"}
          />
        </div>
      </div>

      <div className={styles.inputBox}>
        <div className={styles.inputLabel}>
          Do you primarily have a B2B or B2C focus(or hybrid)?
        </div>
        <div className={styles.inputText}>
          <AgencyTypeDropDown
            options={focusList}
            name={"Select B2B or B2C"}
            setOption={setBenchmark}
            initialValue={b2bOrB2c}
            title={"b2bOrB2c"}
          />
        </div>
      </div>

      <div className={styles.arrow}>
        <div
          className={styles.arrowIcon}
          onClick={(e) => {
            dispatch(setHistory("conversation"));
            dispatch(setBenchmark(initialState));
          }}
        >
          {update === "updateSummary" ? null : <FaArrowLeftLong />}
        </div>
        <div
          className={styles.arrowIcon}
          onClick={(e) => {
            dispatch(setHistory("step2"));
          }}
        >
          <FaArrowRightLong />
        </div>
      </div>
    </div>
  );
}
