import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import StickyHeadTable from "../../../Table/Table";

const columns = [
  {
    id: "subDeliverable",
    label: "Subdeliverable",
    minWidth: "30%",
    align: "center",
  },
  { id: "complexity", label: "Complexity", minWidth: "15%", align: "center" },
  {
    id: "number",
    label: "Total Numbers",
    minWidth: "15%",
    align: "center",
  },
  {
    id: "hours",
    label: "Numbers of Hours",
    minWidth: "15%",
    align: "center",
  },
  {
    id: "cost",
    label: "Cost",
    minWidth: "25%",
    align: "center",
  },
];

const heading1Styles = {
  opacity: 1,
  fontSize: "14px",
  fontWeight: 700,
  color: "#000",
  border: "1px solid rgba(255, 255, 255, 0.50)",
  backgroundColor: "#FFF",
};
const heading2Styles = {
  opacity: 0.5,
  fontSize: "14px",
  fontWeight: 400,
  color: "#000",
  border: "1px solid rgba(255, 255, 255, 0.50)",
  borderRadius: 0,
  backgroundColor: "#FFF",
};
const heading3Styles = {
  color: "#000",
  opacity: 0.8,
  fontSize: "14px",
  fontWeight: 400,
  border: "1px solid rgba(255, 255, 255, 0.50)",
  borderRadius: 0,
  backgroundColor: "#c4c1c1",
};
const cellStyles = {
  color: "#FFF",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontSize: "14px",
  fontWeight: 400,
  border: "1px solid rgba(255, 255, 255, 0.50)",
  borderRadius: 0,
};
const noData = {
  textAlign: "center",
  color: "#fff",
  opacity: "0.5",
  fontWeight: "500",
};

const StrategyTable = ({ data, benchmarkCurrencySymbol }) => {
  return (
    <div>
      <StickyHeadTable>
        <TableRow>
          <TableCell align="center" colSpan={5} style={heading1Styles}>
            {data?.category}
          </TableCell>
        </TableRow>

        {data?.deliverables.map((item, index) => (
          <>
            <TableRow>
              <TableCell align="center" colSpan={5} style={heading2Styles}>
                {item?.deliverable}
              </TableCell>
            </TableRow>

            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={heading3Styles}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>

            <TableBody>
              {item.subDeliverables && item.subDeliverables.length > 0 ? (
                item.subDeliverables.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={cellStyles}
                          >
                            {value
                              ? column.id === "cost"
                                ? `${benchmarkCurrencySymbol}${value.toLocaleString(
                                    "en-US",
                                    {
                                      maximumFractionDigits: 0,
                                      minimumFractionDigits: 0,
                                    }
                                  )}`
                                : value
                              : ""}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <p style={noData}>No Deliverable to show</p>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </>
        ))}
      </StickyHeadTable>
    </div>
  );
};

export default StrategyTable;
