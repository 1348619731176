import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axiosInstance";
import toast from "react-hot-toast";
import { setLoader } from "../Loader";

export const initialState = {
  country: null,
  stateProvince: null,
  agencyType: null,
  tier: null,
  rateCardDetail_id: null,
  user_id: null,
  rateCardName: null,
  rateCardInfo_id: null,
  rateCardData: null,
  userSpecificRateCardInfo: null,
  logFileUrl: null,
};

const ratecardDetailsSlice = createSlice({
  name: "ratecardDetails",
  initialState: initialState,
  reducers: {
    setRatecard(state, action) {
      Object.keys(action.payload).forEach((key, index) => {
        state[key] = action.payload[key];
      });
    },
  },
});

export const { setRatecard } = ratecardDetailsSlice.actions;

export default ratecardDetailsSlice.reducer;

// Save Rate Crad Detail --- Not In Use
export const postRatecardDetailsThunkMiddleware = (
  { country, tier, agencyType, rateCardName },
  callback
) => {
  return async (dispatch) => {
    try {
      if (!rateCardName) {
        return toast.error("Please Enter Ratecard Name");
      }
      if (!country || !tier || !agencyType) {
        return toast.error("Please Enter Ratecard Details");
      }
      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_POST_RATECARD_DETAILS,
        data: {
          country,
          agencyType,
          tier,
        },
      });
      if (response.status === 201) {
        const { user_id, rateCardDetail_id } = response.data;
        console.log("id", rateCardDetail_id);
        await dispatch(setRatecard({ user_id, rateCardDetail_id }));
        toast.success("Ratecard Details Saved Successfully");
        callback(null);
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    }
  };
};

// Calculate RateCard with Loader
export const getRatecardInfoThunkMiddleware = (
  { country, tier, agencyType },
  callback
) => {
  return async (dispatch) => {
    try {
      if (!country || !tier || !agencyType) {
        return toast.error("Please Enter Ratecard Details");
      }
      dispatch(setLoader(true));
      console.log("value index", { country, tier, agencyType });
      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_GET_RATECARD_INFO,
        data: {
          country,
          tier,
          agencyType,
        },
      });

      if (response.status === 200) {
        const { rateCardData } = response.data;
        const logFileUrl = response.data["Log File URL"];
        dispatch(setRatecard({ rateCardData, logFileUrl }));
        callback(null);
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      dispatch(setRatecard({ rateCardData: null }));
      toast.error(message);
    } finally {
      dispatch(setLoader(false));
    }
  };
};

// Save Ratecard Info --- Not In Use
export const postRatecardInfoThunkMiddleware = (
  { rateCardDetail_id, rateCardName, rateCardData },
  callback
) => {
  return async (dispatch) => {
    try {
      if (!rateCardName) {
        return toast.error("Please Enter Ratecard Name");
      }
      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_POST_RATECARD_INFO,
        data: {
          rateCardDetail_id,
          rateCardName,
          rateCardData,
        },
      });
      console.log("Hello First Save ratecard details");
      if (response.status === 201) {
        toast.success("Ratecard Details and Info Saved Successfully");
        dispatch(setRatecard(initialState));
        callback(null);
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    }
  };
};

// Save Rate Crad Detail and Info with Loader
export const postRatecardDetailsAndInfoThunkMiddleware = (
  { country, tier, agencyType, rateCardName, rateCardData, logFileUrl },
  callback
) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true));
      if (!rateCardName) {
        return toast.error("Please Enter Ratecard Name");
      }
      if (!country || !tier || !agencyType) {
        return toast.error("Please Enter Ratecard Details");
      }
      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_POST_RATECARD_DETAILS,
        data: {
          country,
          agencyType,
          tier,
        },
      });
      if (response.status === 201) {
        const { user_id, rateCardDetail_id } = response.data;
        dispatch(setRatecard({ user_id, rateCardDetail_id }));

        const responseOne = await axios({
          method: "POST",
          url: process.env.REACT_APP_POST_RATECARD_INFO,
          data: {
            rateCardDetail_id,
            rateCardName,
            rateCardData,
            "Log File URL": logFileUrl,
          },
        });

        if (responseOne.status === 201) {
          toast.success("Ratecard Details and Info Saved Successfully");
          dispatch(setRatecard(initialState));
          callback(null);
        }
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      dispatch(setLoader(false));
    }
  };
};

// Get RateCard Info by RateCardID with loader and callback first
export const getRatecardInfoByIdThunkMiddleware = (
  { rateCardInfo_id },
  callback
) => {
  return async (dispatch) => {
    try {
      // dispatch(setLoader(true));
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_GET_USER_SPECIFIC_RATECARD_INFO_BYID}/${rateCardInfo_id}`,
      });
      if (response.status === 200) {
        const { rateCardName, rateCardData } = response.data;
        dispatch(setRatecard({ rateCardName, rateCardData }));
        callback(null);
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      // dispatch(setLoader(false));
    }
  };
};

// Getting User Specific Ratecard Info with loader
export const getUserSpecificRatecardInfoThunkMiddleware = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_GET_USER_SPECIFIC_RATECARD}`,
      });

      if (response.status === 200) {
        dispatch(setRatecard({ userSpecificRateCardInfo: response.data }));
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      dispatch(setLoader(false));
    }
  };
};
