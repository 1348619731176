import styles from "./WorkContainer.module.css";
import Button from "../Button";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
const buttonStyles = {
  borderRadius: "4px",
  border: "none",
  background: `rgba(255, 255, 255, 0.20)`,
  padding: "8px 16px",
};
export default function WorkContainer({
  color,
  name,
  date,
  type,
  clickHandler,
}) {
  return (
    <div
      className={styles.container}
      onClick={clickHandler}
      style={{
        border: `1px solid ${color}`,
      }}
    >
      <div className={styles.top}>
        <div className={styles.text}>{name}</div>
        <div className={styles.date}>{date}</div>
      </div>
      <div className={styles.bottom}>
        <Button style={buttonStyles}>
          {type ? type : "Type Not Available"}
        </Button>
      </div>
    </div>
  );
}
