import { useEffect, useState } from "react";
import Screen1 from "./Screen1/Screen1";
import Screen2 from "./Screen2/Screen2";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setProfileOptions } from "../../redux/features/Profile";
import toast from "react-hot-toast";
import { getOptionListsThunkMiddleware } from "../../redux/features/Miscellaneous/Index";

export default function GetStarted() {
  const dispatch = useDispatch();
  const [screen, setScreen] = useState("screen1");

  useEffect(() => {
    const getProfileOptions = async () => {
      try {
        const res = await axios({
          method: "GET",
          url: process.env.REACT_APP_GET_PROFILE_OPTIONS,
        });
        if (res.status === 200) {
          const { data } = res;
          console.log(data);
          dispatch(setProfileOptions(data));
        }
      } catch (error) {
        dispatch(setProfileOptions({}));
        toast.error(error.message);
      }
    };
    getProfileOptions();
  }, []);

  useEffect(() => {
    dispatch(getOptionListsThunkMiddleware());
  }, []);

  return (
    <>
      {screen === "screen1" ? (
        <Screen1 setScreen={setScreen} />
      ) : (
        <Screen2 setScreen={setScreen} />
      )}
    </>
  );
}
