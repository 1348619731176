import styles from "./Right.module.css";
import Button from "../../Button";
import InputTextAreaButton from "../../InputTextAreaButton";
import {
  sendMessageThunkMiddleware,
  setSupport,
} from "../../../redux/features/Support";
import { useDispatch, useSelector } from "react-redux";

export default function Right() {
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.support);

  const sendMessageHandler = () => {
    dispatch(sendMessageThunkMiddleware({ message }));
  };

  return (
    <div className={styles.right}>
      <div className={styles.heading}>Reach Out to us</div>
      <div className={styles.text}>
        Stuck or need expert advice? We're just a message away. Use the form
        below to send us your questions, concerns, or feedback, and we'll get
        back to you as soon as possible.
      </div>
      {/* <div className={styles.input}>
        <div className={styles.inputLabel}>Name</div>
        <div className={styles.inputBtn}>
          <InputTextButton
            name={"Select your department"}
            style={{ backgroundColor: "inherit" }}
          />
        </div>
      </div>
      <div className={styles.input}>
        <div className={styles.inputLabel}>Email Address</div>
        <div className={styles.inputBtn}>
          <InputTextButton
            name={"Write your email ID"}
            style={{ backgroundColor: "inherit" }}
          />
        </div>
      </div> */}
      <div className={`${styles.input} ${styles.message}`}>
        <div className={styles.inputLabel}>Message</div>
        <div className={styles.inputBtn}>
          <InputTextAreaButton
            name={"Write your message for us"}
            style={{ backgroundColor: "inherit" }}
            setValue={setSupport}
            initialValue={message}
            title={"message"}
          />
        </div>
      </div>
      <div className={styles.actionBtn}>
        {/* <Button style={{ backgroundColor: "inherit", fontWeight: 700 }}>
          Cancel
        </Button> */}
        <Button
          style={{
            backgroundColor: "#374CE7",
            fontWeight: 700,
            border: "none",
          }}
          onClick={sendMessageHandler}
        >
          Send
        </Button>
      </div>
    </div>
  );
}
