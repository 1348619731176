import styles from "./LeftPage.module.css";

export default function LeftPage() {
  return (
    <div className={styles.leftPage}>
      <div className={styles.modal}>
        <div className={styles.head1}>
          <div className={styles.content}>
            Welcome to the Admin Panel Login!
          </div>
        </div>
        <div className={styles.description}>
          <div className={styles.description1}>
            <div className={styles.content}>
              Please enter your credentials to access the powerful AI marketing
              platform's administrative features.
            </div>
          </div>
          <div className={styles.description2}>
            <div className={styles.content}>
              As an admin, you will have control over user management, campaign
              analytics, and valuable insights to optimize your marketing
              strategies.
            </div>
          </div>
          <div className={styles.description3}>
            <div className={styles.content}>Let's get started!</div>
          </div>
        </div>
      </div>
    </div>
  );
}
