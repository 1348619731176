import React from "react";
import styles from "./InputGroup.module.css";
import AgencyTypeDropDown from "../../SelectionEstimation/Step2/AgencyTypeDropDown";

const SelectGroup = ({
  label = "Label",
  options,
  initialValue,
  setFunction,
  activeStatus,
  title,
}) => {
  return (
    <div className={styles.inputGroup}>
      <div className={styles.label}>{label}</div>
      <div className={styles.selectDropDown}>
        <AgencyTypeDropDown
          name={`Select your ${label}`}
          options={options}
          initialValue={initialValue}
          setOption={setFunction}
          title={title}
          isDisable={activeStatus}
        />
      </div>
    </div>
  );
};

export default SelectGroup;
