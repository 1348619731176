import { useEffect, useState } from "react";
import styles from "./Card.module.css";
import { setDeliverables } from "../../../redux/features/BenchmarkDetails";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Button";
import { MdDelete } from "react-icons/md";
import Modal from "./Modal";

const options = [
  { label: "Select", value: null },
  { label: "New", value: "New" },
  { label: "Existing", value: "Existing" },
  { label: "Revision", value: "Revision" },
];

export default function Card({ type, subType, name }) {
  const [modalopen, setModalopen] = useState(false);
  const dispatch = useDispatch();
  const { deliverableType } = useSelector((state) => state.benchmarkDetails);
  const deliverableTypeData = { type, subType, name };

  const preValue =
    deliverableType[type] &&
    deliverableType[type][subType] &&
    deliverableType[type][subType][name] &&
    deliverableType[type][subType][name];

  const [clicked, setClicked] = useState(preValue ? true : false);

  const initialValue = preValue ? preValue : null;

  const modalOpenHandler = () => {
    setModalopen(true);
  };

  const modalCloseHandler = () => {
    setModalopen(false);
  };

  const [deleteRefresh, setDeleteRefres] = useState(false);
  const deleteDeliverableHandler = () => {
    if (clicked) {
      const obj = {
        type,
        subType,
        name,
        value: null,
      };
      dispatch(setDeliverables(obj));
      setClicked((prev) => false);
      setDeleteRefres((prev) => !prev);
    }
  };

  useEffect(() => {
    if (initialValue && Object.keys(initialValue).length === 0) {
      setClicked((prev) => false);
    }
  }, [initialValue]);

  const clickHandler = () => {
    if (!initialValue) {
      modalOpenHandler();
    }
  };
  return (
    <>
      <div
        className={`${styles.cardDeliverable} ${
          clicked ? styles.clicked : null
        }`}
        onClick={clickHandler}
      >
        <div className={styles.name}>{name}</div>
        <div
          className={`${styles.buttonBox} ${clicked ? styles.clicked : null}`}
        >
          {initialValue && (
            <>
              <div className={styles.btn}>
                <Button
                  style={{
                    backgroundColor: "inherit",
                    fontWeight: 600,
                    borderColor: `${clicked ? "#374ce7" : "inherit"}`,
                    color: `${clicked ? "#374ce7" : "inherit"}`,
                  }}
                  onClick={(e) => {
                    modalOpenHandler();
                  }}
                >
                  Details
                </Button>
              </div>

              <div className={styles.deletebtn}>
                <Button
                  style={{
                    backgroundColor: "#374CE7",
                    fontWeight: 600,
                    border: "none",
                    fontSize: "1.4rem",
                  }}
                  onClick={deleteDeliverableHandler}
                >
                  <MdDelete />
                </Button>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal
        modalopen={modalopen}
        modalCloseHandler={modalCloseHandler}
        setClicked={setClicked}
        title={name}
        deliverableTypeData={deliverableTypeData}
        initialValue={initialValue}
        deleteRefresh={deleteRefresh}
      />
    </>
  );
}
