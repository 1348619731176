import { CircularProgress, TableCell, TableRow } from '@mui/material';
import React from 'react';
const cellStyles = {
    color: "rgba(255, 255, 255, 0.50)",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    border: "1px solid rgba(255, 255, 255, 0.50)",
    backgroundColor: "inherit",
};
const TableSkeleton = ({ col }) => {
    return (
        <TableRow >
            <TableCell colSpan={col} style={cellStyles} >
                <div style={{
                    minHeight: "2.5rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CircularProgress style={{
                        color: "#374CE7",
                    }} />
                </div>
            </TableCell>
        </TableRow>
    );
};

export default TableSkeleton;