import styles from "./RatecardManagement.module.css";
import StickyHeadTable from "../../../Table/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "../../../Button";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  plugins,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  rateCardStatsThunkMiddleware,
  recentRateCardThunkMiddleware,
} from "../../../../redux/features/Admin";
import { Typography } from "@mui/material";
import TableSkeleton from "../Home/TableSkeleton";
import { Link } from "react-router-dom";
ChartJS.register(ArcElement, Tooltip, Legend);

const columns = [
  { id: "serialNumber", label: "Sr. No.", minWidth: "33%", align: "center" },
  { id: "name", label: "Name of the user", minWidth: "33%", align: "center" },
  {
    id: "country",
    label: "Country",
    minWidth: "33%",
    align: "center",
  },
  {
    id: "agencyType",
    label: "Agency Type",
    minWidth: "33%",
    align: "center",
  },
  { id: "tier", label: "Agency Tier", minWidth: "33%", align: "center" },
  {
    id: "logFileUrl",
    label: "Calculation Log",
    minWidth: "33%",
    align: "center",
  },
];
function createData(serialNumber, name, country, agencyType, tier, logFileUrl) {
  return {
    serialNumber,
    name,
    country,
    agencyType,
    tier,
    logFileUrl,
  };
}

const headingStyles = {
  color: "#FFF",
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  border: "1px solid #FFF",
  backgroundColor: "inherit",
  whiteSpace: "nowrap",
};
const cellStyles = {
  color: "rgba(255, 255, 255, 0.50)",
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  border: "1px solid rgba(255, 255, 255, 0.50)",
  backgroundColor: "inherit",
  whiteSpace: "nowrap",
};
const downloadBtnStyles = {
  borderRadius: "8px",
  backgroundColor: "#374CE7",
  padding: "8px 24px",
  color: "#FFFFFF",
  border: "none",
  fontSize: "12px",
  fontWeight: 700,
};

export default function RatecardManagement() {
  const dispatch = useDispatch();
  const { rateCardStats, recentRateCard } = useSelector((state) => state.admin);
  const { isLoading } = useSelector((state) => state.loader);

  const CreateRows = (recentRateCard) => {
    const rows = [];
    if (recentRateCard) {
      recentRateCard.forEach((element, index) => {
        rows.push(
          createData(
            index + 1,
            element.profileName,
            element.country,
            element.agencyType,
            element.tier,
            element.RateCardInfos[0]?.logFileUrl
          )
        );
      });
    }
    return rows;
  };
  const charts = [
    {
      name: "Agency Type",
      data: {
        labels:
          rateCardStats &&
          rateCardStats.agencyType &&
          rateCardStats.agencyType.length > 0
            ? rateCardStats.agencyType.map((item) => item.agencyType)
            : [],
        datasets: [
          {
            label: "# of Agency Type",
            data:
              rateCardStats &&
              rateCardStats.agencyType &&
              rateCardStats.agencyType.length > 0
                ? rateCardStats.agencyType.map((item) => item.percentage)
                : [],
            backgroundColor: ["#374CE7", "#EFF0FB", "#D7D9F4", "#B6BBEC"],
            borderColor: ["white", "white", "white", "white"],
            borderWidth: 1,
          },
        ],
      },
    },
    {
      name: "Agency Tier",
      data: {
        labels:
          rateCardStats && rateCardStats.tier && rateCardStats.tier.length > 0
            ? rateCardStats.tier.map((item) => item.value)
            : [],
        datasets: [
          {
            label: "# of Agency Tier",
            data:
              rateCardStats &&
              rateCardStats.tier &&
              rateCardStats.tier.length > 0
                ? rateCardStats.tier.map((item) => item.percentage)
                : [],
            backgroundColor: ["#374CE7", "#EFF0FB", "#D7D9F4"],
            borderColor: ["white", "white", "white"],
            borderWidth: 1,
          },
        ],
      },
    },
    // {
    //   name: "Hourly Rate",
    //   data: {
    //     labels: ["$110", "$100", "$70", "$160", "$200"],
    //     datasets: [
    //       {
    //         label: "# of Registered Industries",
    //         data: [40, 25, 15, 20, 20],
    //         backgroundColor: [
    //           "#374CE7",
    //           "#EFF0FB",
    //           "#D7D9F4",
    //           "#B6BBEC",
    //           "#9AA0E5",
    //         ],
    //         borderColor: ["white", "white", "white", "white", "white"],
    //         borderWidth: 1,
    //       },
    //     ],
    //   },
    // },
  ];

  useEffect(() => {
    dispatch(rateCardStatsThunkMiddleware());
    dispatch(recentRateCardThunkMiddleware());
  }, []);
  return (
    <>
      <div className={styles.main}>
        <div className={styles.topHeading}>
          <div className={styles.left}>
            <div className={styles.displayName}>Ratecard Management</div>
          </div>
        </div>
        <div className={styles.topic}>
          <div className={styles.displayName}>Hi Vlad,</div>
          <div className={styles.welcomeNote}>
            Welcome to the Admin Dashboard, where you have complete control over
            the AI marketing platform.
          </div>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.charts}>
            {charts.map(({ name, data }) => {
              return (
                <div className={styles.chart}>
                  <div className={styles.chartName}>{name}</div>
                  <Pie
                    data={data}
                    options={{
                      plugins: { legend: { position: "right" } },
                      responsive: false,
                      maintainAspectRatio: true,
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className={styles.benchMarkList}>
            <div className={styles.heading}>
              <div className={styles.text}>Recent users</div>
              <div className={styles.downloadBtn}>
                <Button style={downloadBtnStyles}>Download as CSV</Button>
              </div>
            </div>
            <StickyHeadTable>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={headingStyles}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableSkeleton col={5} />
                ) : (
                  <>
                    {recentRateCard ? (
                      CreateRows(recentRateCard).map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              console.log(value);
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={cellStyles}
                                >
                                  {column.id === "logFileUrl" ? (
                                    <Link
                                      to={value}
                                      className={styles.downloadBtn}
                                    >
                                      <Button style={downloadBtnStyles}>
                                        Download
                                      </Button>
                                    </Link>
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })
                    ) : (
                      <>
                        <TableRow>
                          <TableCell colSpan={5} style={cellStyles}>
                            <Typography style={{ textAlign: "center" }}>
                              No Ratecard to show
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </>
                )}
              </TableBody>
            </StickyHeadTable>
          </div>
        </div>
      </div>
    </>
  );
}
