import styles from "./Support.module.css";
import Right from "./Right";
import bell from "../../../../assets/images/Bell.png";
import RightSideBar from "../../../Dashboard/BenchmarkHistory/RightSideBar";
export default function Support() {
  return (
    <>
      <div className={styles.main}>
        <div className={styles.topHeading}>
          <div className={styles.left}>
            <div className={styles.displayName}>Support and help</div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.agenda}>
              <div className={styles.heading}>We're Here to Help</div>
              <div className={styles.description}>
                Welcome to our support and help center. Our dedicated team is
                ready to assist you every step of the way.{" "}
              </div>
            </div>
            <div className={styles.faq}>
              <div className={styles.heading}>Frequently Asked Questions</div>
              <div className={styles.description}>
                Looking for quick answers?
              </div>
              <div className={styles.description}>
                Check out our comprehensive FAQs to find solutions to common
                queries.
              </div>
              <div className={styles.description}>
                If you don't find what you're looking for, don't hesitate to
                contact our support team for further assistance.
              </div>
              <div className={styles.heading}>
                Contact Support: support@help.com
              </div>
            </div>
            <div className={styles.explore}>
              <div className={styles.heading}>Our Support Hours</div>
              <div className={styles.description}>
                Our team is available to assist you during the following hours:
              </div>
              <ul className={styles.listItems}>
                <li>Monday to Friday: 9:00 AM to 6:00 PM (GMT)</li>
                <li>Saturday: 10:00 AM to 3:00 PM (GMT)</li>
                <li>Sunday: Closed</li>
              </ul>
            </div>
          </div>
          <Right />
        </div>
      </div>
    </>
  );
}
