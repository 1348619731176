import { configureStore } from "@reduxjs/toolkit";
import navBarReducer from "../features/NavBar/index";
import homeReducer from "../features/Home/index";
import historyReducer from "../features/History/index";
import authReducer from "../features/Auth/index";
import profileReducer from "../features/Profile/index";
import benchmarkDetailsReducer from "../features/BenchmarkDetails";
import ratecardDetailsReducer from "../features/RatedcardDetails";
import loaderReducer from "../features/Loader";
import miscellaneousReducer from "../features/Miscellaneous/Index";
import dashboardStatsReducer from "../features/Dashboard Stats/index";
import supportReducer from "../features/Support";
import chatReducer from "../features/Chat";

import adminReducer from "../features/Admin";

export const store = configureStore({
  reducer: {
    dashboardStats: dashboardStatsReducer,
    navBar: navBarReducer,
    home: homeReducer,
    history: historyReducer,
    auth: authReducer,
    profile: profileReducer,
    benchmarkDetails: benchmarkDetailsReducer,
    ratecardDetails: ratecardDetailsReducer,
    loader: loaderReducer,
    miscellaneous: miscellaneousReducer,
    support: supportReducer,
    chat: chatReducer,
    admin: adminReducer,
  },
});
