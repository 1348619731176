import Button from "../../Button";
import styles from "./Screen2.module.css";
import arrow from "../../../assets/images/LeftArrow.png";
import Logo from "../../Logo/Logo";
import AgencyTypeDropDown from "../../SelectionEstimation/Step2/AgencyTypeDropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  postProfileThunkMiddleware,
  setPersonalInfo,
} from "../../../redux/features/Profile";
import { CircularProgress } from "@mui/material";
import InputTextButton from "../../InputTextButton";

export default function Screen2({ setScreen }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.loader);
  const { focusList, organisationsList } = useSelector(
    (state) => state.miscellaneous
  );

  const {
    name,
    currentCompany,
    title,
    industry,
    country,
    globalOrRegional,
    b2bOrB2c,
  } = useSelector((state) => state.profile);

  const updateState = async () => {
    dispatch(
      postProfileThunkMiddleware({
        name,
        currentCompany,
        title: title,
        country,
        globalOrRegional,
        industry,
        b2bOrB2c,
      })
    );
  };

  return (
    <div className={styles.screen2}>
      <Logo />
      <div className={styles.modal}>
        <div className={styles.head}>
          Let's get started with some basic company information.
        </div>
        <div className={styles.step}>Step 2 of 2</div>
        <div className={styles.inputLabel}>
          Are you part of a Global or Regional/Local organisation?
        </div>
        <div className={styles.inputBox}>
          <AgencyTypeDropDown
            name={"Select your region"}
            options={organisationsList}
            setOption={setPersonalInfo}
            initialValue={globalOrRegional}
            title={"globalOrRegional"}
          />
        </div>
        <div className={styles.inputLabel}>Which industry you're in?</div>
        <div className={styles.inputBox}>
          <InputTextButton
            name={"Enter your industry"}
            style={{
              backgroundColor: "#000",
              border: "1px solid rgba(255, 255, 255, 0.50)",
            }}
            setValue={setPersonalInfo}
            initialValue={industry}
            title={"industry"}
          />
        </div>
        <div className={styles.inputLabel}>
          Do you primarily have a B2B or B2C focus(or hybrid)?
        </div>
        <div className={styles.inputBox}>
          <AgencyTypeDropDown
            name={"Select your focus"}
            options={focusList}
            setOption={setPersonalInfo}
            initialValue={b2bOrB2c}
            title={"b2bOrB2c"}
          />
        </div>
        <div className={styles.buttonBox}>
          <div className={styles.button2}>
            <Button
              name={"Save"}
              style={{
                height: "2.8rem",
                fontWeight: "700",
                backgroundColor: "#374CE7",
                border: "0",
              }}
              onClick={(e) => {
                updateState();
              }}
              disable={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={30} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      </div>
      <div
        className={styles.arrow}
        onClick={(e) => {
          setScreen("screen1");
        }}
      >
        <img src={arrow}></img>
      </div>
    </div>
  );
}
