import { createSlice } from "@reduxjs/toolkit";


const initialState = { name: "home" };
const homeSlice = createSlice({
  name: "navbar",
  initialState: initialState,
  reducers: {
    setHome(state, action) {
      state.name = action.payload;
    },
  },
});
export const { setHome } = homeSlice.actions;
export default homeSlice.reducer;
