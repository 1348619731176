import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axios from "../../../utils/axiosInstance";
import { setLoader } from "../Loader";

const initialState = {
  totalBenchmarkValue: null,
  totalBenchmarks: null,
  recentWorks: null,
  benchmarkChangeMessage: null,
  estimationChangeMessage: null,
};

const dashboardStatsSlice = createSlice({
  name: "dashboardStats",
  initialState: initialState,
  reducers: {
    setDashboardStats(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

export const { setDashboardStats } = dashboardStatsSlice.actions;

export default dashboardStatsSlice.reducer;

export const getDashboardStatsThunkMiddleware = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_GET_DASHBOARD_STATS,
      });

      if (res.status === 200) {
        const {
          totalBenchmarkValue,
          totalBenchmarks,
          benchmarkChangeMessage,
          estimationChangeMessage,
        } = res.data;
        dispatch(
          setDashboardStats({
            totalBenchmarkValue,
            totalBenchmarks,
            benchmarkChangeMessage,
            estimationChangeMessage,
          })
        );
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      dispatch(setLoader(false));
    }
  };
};

export const getDashboardDataThunkMiddleware = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_GET_DASHBOARD_RECENT_WORKS,
      });

      if (res.status === 200) {
        const recentWorks = res.data;
        dispatch(
          setDashboardStats({
            recentWorks,
          })
        );
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      dispatch(setLoader(false));
    }
  };
};
