import { useState, useEffect, useRef } from "react";
import Auth from "../components/Auth/Auth";
import GetStarted from "../components/GetStarted/GetStarted";
import Dashboard from "../components/Dashboard/Dashboard";
import Home from "../components/Home/Home";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { logoutThunkMiddleware } from "../redux/features/Profile";
import { unstable_HistoryRouter } from "react-router-dom";

export default function User() {
  const dispatch = useDispatch();
  const { isAuthenticated, role } = useSelector((state) => state.auth);
  const { isSaved } = useSelector((state) => state.profile);

  return (
    <>
      {isAuthenticated && role && role === process.env.REACT_APP_USER ? (
        isSaved && JSON.parse(isSaved) ? (
          <Dashboard />
        ) : (
          <GetStarted />
        )
      ) : (
        <Auth />
      )}
    </>
  );
}
