import styles from "./Chat.module.css";
import Messages from "./Messages/Messages";

export default function Modal({ children, scrollBottom }) {
  return (
    <div className={styles.modal}>
      <Messages scrollBottom={scrollBottom} />
      {children}
    </div>
  );
}
