import styles from "./Dashboard.module.css";
import SideNav from "./SideNav/SideNav";
import Home from "./Home/Home";
import BenchmarkHistory from "./BenchmarkHistory/BenchmarkHistory";
import Support from "./Support/Support";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Result from "./EstimateRateCard/Result";
import { setHome } from "../../redux/features/Home";
import { getProfileThunkMiddleware } from "../../redux/features/Profile";
import RightSideBar from "./BenchmarkHistory/RightSideBar";
import {
  getAgencyTypeList,
  getAmbitionsList,
  getCalibresList,
  getCountryList,
  getIndustriesList,
  getSubDeliverablesList,
  getTierAgencyList,
  getFocusList,
  getOrganisationsList,
  getDevelopmentTypesList,
} from "../../redux/features/Miscellaneous/Index";

export default function Dashboard() {
  const dispatch = useDispatch();
  const navBarState = useSelector((state) => state.navBar.name);

  useEffect(() => {
    dispatch(getProfileThunkMiddleware());
  }, []);

  return (
    <div className={styles.dashBoard}>
      {navBarState === "home" && <Home />}
      {navBarState === "history" && <BenchmarkHistory />}
      {navBarState === "support" && <Support />}
      <RightSideBar />
    </div>
  );
}
