import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axiosInstance";
import toast from "react-hot-toast";
import { setLoader, setSaveLoader, setUpdateLoader } from "../Loader";

export const initialState = {
  source: "Selection",
  globalOrRegional: null,
  industry: null,
  b2bOrB2c: null,
  location: null,
  agencyType: null,
  tier: null,
  ambition: null,
  intendedUse: null,
  budget: null,
  primaryMarketsCount: 1,
  newAssets: null,
  noOfAdaption: null,
  scope: null,
  deliverableType: {},

  benchmarkInfo_id: null,
  benchmarkDetail_id: null,
  benchmarkName: null,
  benchmarkType: null,
  totalHours: null,
  currencyId: null,
  hoursAndRoleInfo: null,
  deliverableDetails: null,
  logFileUrl: null,

  totalBenchmarkValue: null,
  benchmarkCurrencySymbol: null,
  benchmarkData: null,

  userSpecificBenchmarkInfo: null,
  latestInfos: null,
};

const benchmarkDetailsSlice = createSlice({
  name: "benchmarkDetails",
  initialState: initialState,
  reducers: {
    setBenchmark(state, action) {
      Object.keys(action.payload).forEach((key, index) => {
        state[key] = action.payload[key];
      });
    },
    setDeliverables(state, action) {
      const { type, subType, name, value } = action.payload;
      const deliverableTypeData = { ...state.deliverableType };

      if (value !== null) {
        // Check if the type exists in the deliverableType object
        if (deliverableTypeData[type]) {
          if (deliverableTypeData[type][subType]) {
            deliverableTypeData[type][subType][name] = value;
          } else {
            deliverableTypeData[type][subType] = {};
            deliverableTypeData[type][subType][name] = value;
          }
        } else {
          deliverableTypeData[type] = {};
          deliverableTypeData[type][subType] = {};
          deliverableTypeData[type][subType][name] = value;
        }
      } else {
        if (deliverableTypeData[type]) {
          if (deliverableTypeData[type][subType]) {
            delete deliverableTypeData[type][subType][name];

            if (Object.keys(deliverableTypeData[type][subType]).length === 0) {
              delete deliverableTypeData[type][subType];
            }
          }
          if (Object.keys(deliverableTypeData[type]).length === 0) {
            delete deliverableTypeData[type];
          }
        }
      }

      state.deliverableType = deliverableTypeData;
    },
  },
});
export const { setBenchmark, setDeliverables } = benchmarkDetailsSlice.actions;
export default benchmarkDetailsSlice.reducer;

// Save Benchmark Details --- Not in Use
export const postBenchmarkDetailsThunkMiddleware = (
  {
    globalOrRegional,
    industry,
    b2bOrB2c,
    location,
    agencyType,
    tier,
    ambition,
    intendedUse,
    budget,
    scope,
    deliverableType,
    primaryMarketsCount,
  },
  callback
) => {
  return async (dispatch) => {
    try {
      if (
        !globalOrRegional ||
        !industry ||
        !b2bOrB2c ||
        !location ||
        !agencyType ||
        !tier ||
        !ambition ||
        !intendedUse ||
        !budget ||
        !scope ||
        !primaryMarketsCount
      ) {
        return toast.error("Please Fill All Details");
      }

      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_POST_BENCHMARK_DETAILS,
        data: {
          globalOrRegional,
          industry,
          b2bOrB2c,
          location,
          agencyType,
          tier,
          ambition,
          intendedUse,
          budget,
          primaryMarketsCount,
          scope,
          deliverableType,
        },
      });
      if (response.status === 201) {
        const { benchmarkDetail_id } = response.data;
        dispatch(setBenchmark({ benchmarkDetail_id }));
        toast.success("Benchmark Details Saved Successfully");
        callback(null);
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    }
  };
};

// Caluculate Benchmark wih Loader
export const getBenchmarkInfoThunkMiddleware = (
  {
    globalOrRegional,
    industry,
    b2bOrB2c,
    location,
    agencyType,
    tier,
    ambition,
    intendedUse,
    budget,
    scope,
    primaryMarketsCount,
    deliverableType,
  },
  callback
) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const fields = [
        { field: globalOrRegional, message: "Global or Regional" },
        { field: industry, message: "Industry" },
        { field: b2bOrB2c, message: "B2B or B2C" },
        { field: location, message: "Location" },
        { field: agencyType, message: "Agency Type" },
        { field: tier, message: "Tier" },
        { field: ambition, message: "Ambition" },
        { field: intendedUse, message: "Intended Use" },
        // { field: budget, message: "Budget" },
        // { field: scope, message: "Scope" },
        { field: primaryMarketsCount, message: "Primary Markets Count" },
      ];

      let emptyFields = [];

      for (let i = 0; i < fields.length; i++) {
        if (!fields[i].field) {
          emptyFields.push(fields[i].message);
        }
      }

      if (!deliverableType || Object.keys(deliverableType).length === 0) {
        emptyFields.push("Deiverables Typess");
      }

      if (emptyFields.length > 0) {
        const errorMessage = `Please fill in the following fields: ${emptyFields.join(
          ", "
        )}`;
        return toast.error(errorMessage);
      }

      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_GET_BENCHMARK_INFO,
        data: {
          globalOrRegional,
          industry,
          b2bOrB2c,
          location,
          agencyType,
          tier,
          ambition,
          intendedUse,
          budget,
          scope,
          primaryMarketsCount,
          deliverableType,
        },
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(
          setBenchmark({
            totalBenchmarkValue: response.data["Total Benchmark Value"],
            benchmarkCurrencySymbol: response.data["Currency Symbol"],
            totalHours: response.data["Total Hours"],
            hoursAndRoleInfo: response.data["Hours and Role Info"],
            currencyId: response.data["Currency ID"],
            logFileUrl: response.data["Log File URL"],
            deliverableDetails: response.data["Deliverable Details"],
          })
        );
        callback(null);
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      dispatch(setLoader(false));
    }
  };
};

//Save Benchmark Info and null state after save --- Not in Use
export const postBenchmarkInfoThunkMiddleware = (
  { benchmarkData, benchmarkName },
  callback
) => {
  return async (dispatch) => {
    try {
      if (!benchmarkName) {
        return toast.error("Please Enter benchmark Name");
      }
      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_POST_BENCHMARK_INFO,
        data: { ...benchmarkData, benchmarkName: benchmarkName },
      });
      if (response.status === 201) {
        toast.success("Benchmark Info Saved Successfully");
        dispatch(setBenchmark(initialState));
        callback(null);
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    }
  };
};

// Save Benchmark Details and Info
export const postBenchmarkDetailsAndInfoThunkMiddleware = (
  {
    source,
    globalOrRegional,
    industry,
    b2bOrB2c,
    location,
    agencyType,
    tier,
    ambition,
    intendedUse,
    primaryMarketsCount,
    budget,
    scope,
    deliverableType,

    totalBenchmarkValue,
    benchmarkCurrencySymbol,
    totalHours,
    hoursAndRoleInfo,
    currencyId,
    benchmarkName,
    logFileUrl,
    deliverableDetails,
  },
  callback
) => {
  return async (dispatch) => {
    try {
      dispatch(setSaveLoader(true));
      const fields = [
        { field: globalOrRegional, message: "Global or Regional" },
        { field: industry, message: "Industry" },
        { field: b2bOrB2c, message: "B2B or B2C" },
        { field: location, message: "Location" },
        { field: agencyType, message: "Agency Type" },
        { field: tier, message: "Tier" },
        { field: ambition, message: "Ambition" },
        { field: intendedUse, message: "Intended Use" },
        // { field: budget, message: "Budget" },
        // { field: scope, message: "Scope" },
        { field: primaryMarketsCount, message: "Primary Markets Count" },
        { field: benchmarkName, message: "Benchmark Name" },
      ];

      let emptyFields = [];

      for (let i = 0; i < fields.length; i++) {
        if (!fields[i].field) {
          emptyFields.push(fields[i].message);
        }
      }

      if (!deliverableType || Object.keys(deliverableType).length === 0) {
        emptyFields.push("Deiverables Typess");
      }

      if (emptyFields.length > 0) {
        const errorMessage = `Please fill in the following fields: ${emptyFields.join(
          ", "
        )}`;
        return toast.error(errorMessage);
      }

      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_POST_BENCHMARK_DETAILS,
        data: {
          globalOrRegional,
          industry,
          b2bOrB2c,
          location,
          agencyType,
          tier,
          ambition,
          intendedUse,
          primaryMarketsCount,
          budget,
          scope,
          deliverableType,
        },
      });

      if (response.status === 201) {
        const { benchmarkDetail_id } = response.data;
        dispatch(setBenchmark({ benchmarkDetail_id }));

        const data = {
          "Total Benchmark Value": totalBenchmarkValue,
          "Currency Symbol": benchmarkCurrencySymbol,
          "Total Hours": totalHours,
          "Hours and Role Info": hoursAndRoleInfo,
          "Currency ID": currencyId,
          "Log File URL": logFileUrl,
          "Deliverable Details": deliverableDetails,
        };

        const responseInfo = await axios({
          method: "POST",
          url: process.env.REACT_APP_POST_BENCHMARK_INFO,
          data: { ...data, benchmarkDetail_id, benchmarkName, source },
        });

        if (responseInfo.status === 201) {
          toast.success("Benchmark Details and Info Saved Successfully");
          dispatch(setBenchmark(initialState));
          callback(null);
        }
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      dispatch(setSaveLoader(false));
    }
  };
};

// Getting Benchmark Info by benchmarkInfo_id
export const getBenchmarkInfoByIdThunkMiddleware = (
  { benchmarkInfo_id },
  callback
) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_GET_USER_SPECIFIC_BENCHMARK_INFO_BYID}/${benchmarkInfo_id}`,
      });
      if (response.status === 200) {
        console.log(response.data);
        const {
          benchmarkInfo_id,
          benchmarkDetail_id,
          benchmarkName,
          benchmarkValue,
          totalHours,
          hoursAndRoleInfo,
          currencySymbol,
          BenchmarkDetail,
          currencyId,
          source,
          deliverableDetails,
        } = await response.data;
        console.log(response.data);
        const {
          globalOrRegional,
          industry,
          b2bOrB2c,
          location,
          agencyType,
          tier,
          ambition,
          intendedUse,
          budget,
          scope,
          deliverableType,
          primaryMarketsCount,
        } = BenchmarkDetail;

        dispatch(
          setBenchmark({
            benchmarkInfo_id,
            benchmarkDetail_id,
            benchmarkName,
            totalBenchmarkValue: benchmarkValue,
            benchmarkCurrencySymbol: currencySymbol,
            currencyId,
            totalHours,
            hoursAndRoleInfo,
            globalOrRegional,
            industry,
            b2bOrB2c,
            location,
            agencyType,
            tier,
            ambition,
            intendedUse,
            budget,
            scope,
            deliverableType,
            primaryMarketsCount,
            deliverableDetails,
            source,
          })
        );
        callback(null);
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    }
  };
};

//Update Benchmark Details --- Not in Use
export const updateBenchmarkDetailsThunkMiddleware = (
  {
    globalOrRegional,
    industry,
    b2bOrB2c,
    location,
    agencyType,
    tier,
    ambition,
    intendedUse,
    budget,
    scope,
    deliverableType,
    benchmarkDetail_id,
  },
  callback
) => {
  return async (dispatch) => {
    try {
      setUpdateLoader(true);
      console.log({
        globalOrRegional,
        industry,
        b2bOrB2c,
        location,
        agencyType,
        tier,
        ambition,
        intendedUse,
        budget,
        scope,
        deliverableType,
        benchmarkDetail_id,
      });
      if (!deliverableType || Object.keys(deliverableType).length === 0) {
        return toast.error("Please Select Deiverables Types");
      }

      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_UPDATE_BENCHMARK_DETAILS}/${benchmarkDetail_id}`,
        data: {
          globalOrRegional,
          industry,
          b2bOrB2c,
          location,
          agencyType,
          tier,
          ambition,
          intendedUse,
          budget,
          scope,
          deliverableType,
        },
      });
      if (response.status === 200) {
        toast.success("Benchmark Details Updated Successfully");
        const { benchmarkDetail_id } = response.data;
        dispatch(setBenchmark({ benchmarkDetail_id }));
        callback(null);
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      setUpdateLoader(false);
    }
  };
};

//Update Benchmark Info and null state after update --- Not in Use
export const updateBenchmarkInfoThunkMiddleware = (
  { benchmarkData, benchmarkName, benchmarkInfo_id },
  callback
) => {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_UPDATE_USER_SPECIFIC_BENCHMARK_INFO_BYID}/${benchmarkInfo_id}`,
        data: { ...benchmarkData, benchmarkName: benchmarkName },
      });

      if (response.status === 200) {
        toast.success("Benchmark Info Updated Successfully");
        dispatch(setBenchmark(initialState));
        callback(null);
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    }
  };
};

//Update Benchmark Details And Info with updateLoader ---
export const updateBenchmarkDetailsAndInfoThunkMiddleware = (
  {
    globalOrRegional,
    industry,
    b2bOrB2c,
    location,
    agencyType,
    tier,
    ambition,
    intendedUse,
    budget,
    scope,
    primaryMarketsCount,
    deliverableType,
    benchmarkDetail_id,

    totalBenchmarkValue,
    benchmarkCurrencySymbol,
    totalHours,
    hoursAndRoleInfo,
    currencyId,
    deliverableDetails,

    benchmarkName,
    benchmarkInfo_id,
  },
  callback
) => {
  return async (dispatch) => {
    try {
      dispatch(setUpdateLoader(true));
      const fields = [
        { field: globalOrRegional, message: "Global or Regional" },
        { field: industry, message: "Industry" },
        { field: b2bOrB2c, message: "B2B or B2C" },
        { field: location, message: "Location" },
        { field: agencyType, message: "Agency Type" },
        { field: tier, message: "Tier" },
        { field: ambition, message: "Ambition" },
        { field: intendedUse, message: "Intended Use" },
        // { field: budget, message: "Budget" },
        // { field: scope, message: "Scope" },
        { field: primaryMarketsCount, message: "Primary Markets Count" },
        { field: benchmarkName, message: "Benchmark Name" },
      ];

      let emptyFields = [];

      for (let i = 0; i < fields.length; i++) {
        if (!fields[i].field) {
          emptyFields.push(fields[i].message);
        }
      }

      if (!deliverableType || Object.keys(deliverableType).length === 0) {
        emptyFields.push("Deiverables Typess");
      }

      if (emptyFields.length > 0) {
        const errorMessage = `Following fields are required: ${emptyFields.join(
          ", "
        )}`;
        return toast.error(errorMessage);
      }

      let data = {
        globalOrRegional,
        industry,
        b2bOrB2c,
        location,
        agencyType,
        tier,
        ambition,
        intendedUse,
        budget,
        scope,
        primaryMarketsCount,
        deliverableType,
      };

      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_UPDATE_BENCHMARK_DETAILS}/${benchmarkDetail_id}`,
        data,
      });
      if (response.status === 200) {
        const data = {
          "Total Benchmark Value": totalBenchmarkValue,
          "Currency Symbol": benchmarkCurrencySymbol,
          "Total Hours": totalHours,
          "Hours and Role Info": hoursAndRoleInfo,
          "Currency ID": currencyId,
          "Deliverable Details": deliverableDetails,
        };

        const responseInfo = await axios({
          method: "PUT",
          url: `${process.env.REACT_APP_UPDATE_USER_SPECIFIC_BENCHMARK_INFO_BYID}/${benchmarkInfo_id}`,
          data: { ...data, benchmarkDetail_id, benchmarkName },
        });

        if (responseInfo.status === 200) {
          toast.success("Benchmark Details and Info Updated Successfully");
          dispatch(setBenchmark(initialState));
          callback(null);
        }
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      dispatch(setUpdateLoader(false));
    }
  };
};

// Getting User Specific Benchmark Info with loader
export const getUserSpecificBenchmarkInfoThunkMiddleware = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_GET_USER_SPECIFIC_BENCHMARK}`,
      });

      if (response.status === 200) {
        dispatch(setBenchmark({ userSpecificBenchmarkInfo: response.data }));
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      dispatch(setLoader(false));
    }
  };
};

// Getting Latest Infos with loader
export const getLatestInfosThunkMiddleware = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_GET_LATEST_INFOS}`,
      });

      console.log(response.data);
      if (response.status === 200) {
        dispatch(setBenchmark({ latestInfos: response.data }));
      }
    } catch (error) {
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      dispatch(setLoader(false));
    }
  };
};
