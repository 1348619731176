import styles from "./RightSideBar.module.css";
import Button from "../../Button";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBenchmarkInfoByIdThunkMiddleware,
  getUserSpecificBenchmarkInfoThunkMiddleware,
} from "../../../redux/features/BenchmarkDetails";
import { setNavBar } from "../../../redux/features/NavBar";
import {
  setHistory,
  setHistorySidebar,
  setUpdateHistory,
} from "../../../redux/features/History";

const btnStyles = {
  backgroundColor: `rgba(255, 255, 255, 0.20)`,
  borderRadius: "4px",
  border: "0px solid #000",
  color: "#FFF",
  fontWeight: 700,
  fontSize: "12px",
  overflow: "hidden",
};

export default function RightSideBar() {
  const [clicked, setClicked] = useState(false);
  const dispatch = useDispatch();
  const { userSpecificBenchmarkInfo } = useSelector(
    (state) => state.benchmarkDetails
  );
  const { historySidebar } = useSelector((state) => state.history);

  useEffect(() => {
    dispatch(getUserSpecificBenchmarkInfoThunkMiddleware());
  }, []);

  const benchMarkDetailHandler = (benchmarkInfo_id) => {
    dispatch(
      getBenchmarkInfoByIdThunkMiddleware(
        {
          benchmarkInfo_id,
        },
        (error) => {
          if (!error) {
            dispatch(setNavBar("history"));
            dispatch(setUpdateHistory("updateSummary"));
            dispatch(setHistory("updateSummary"));
          }
        }
      )
    );
  };

  return (
    <motion.div
      initial={{ width: "0" }}
      animate={historySidebar ? { width: "20rem" } : { width: "0" }}
      transition={{ duration: 0.4, ease: "easeIn" }}
      // style={{ width: "25%", height: "100%", overflow: "hidden" }}
      className={styles.rightSideBarHistory}
    >
      <div className={styles.heading}>
        <div className={styles.text}>Benchmark History</div>
        <div
          className={styles.closeIcon}
          onClick={(e) => {
            dispatch(setHistorySidebar(false));
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <path
              d="M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <hr className={styles.dotted} />
      <div className={styles.cardContainer}>
        <div className={styles.recent}>Recent</div>
        {userSpecificBenchmarkInfo && userSpecificBenchmarkInfo.length > 0 ? (
          userSpecificBenchmarkInfo.map((item, index) => (
            <div
              className={styles.card}
              onClick={() => {
                benchMarkDetailHandler(item.benchmarkInfo_id);
                dispatch(setHistorySidebar(!historySidebar));
              }}
            >
              <div className={styles.nameDate}>
                <div className={styles.name}>{item.benchmarkName}</div>
                <div className={styles.date}>Jul 20</div>
              </div>
              <div className={styles.btn}>
                <Button style={btnStyles}>
                  {item.benchmarkType
                    ? item.benchmarkType
                    : "Benchmark Type Not Available"}
                </Button>
              </div>
            </div>
          ))
        ) : (
          <div className={styles.noBenchmarkData}>No Benchmark</div>
        )}
      </div>
    </motion.div>
  );
}
