import styles from "./UserManagement.module.css";
import bell from "../../../../assets/images/Bell.png";
import AgencyTypeDropDown from "../../../SelectionEstimation/Step2/AgencyTypeDropDown";
import { useDispatch, useSelector } from "react-redux";
import StickyHeadTable from "../../../Table/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import BenchMarkCard from "../BenchMarkCard";
import Button from "../../../Button";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  plugins,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { useEffect } from "react";
import {
  profileIndustryStatsThunkMiddleware,
  recentUsersThunkMiddleware,
} from "../../../../redux/features/Admin";
import TableSkeleton from "../Home/TableSkeleton";
import { Typography } from "@mui/material";
import { setLoader } from "../../../../redux/features/Loader";
ChartJS.register(ArcElement, Tooltip, Legend);
const columns = [
  { id: "serialNumber", label: "Sr. No.", minWidth: "33%", align: "center" },
  { id: "name", label: "Name of the user", minWidth: "33%", align: "center" },
  {
    id: "company",
    label: "Current Company",
    minWidth: "33%",
    align: "center",
  },
  { id: "title", label: "Title", minWidth: "33%", align: "center" },
  { id: "industry", label: "Industry", minWidth: "33%", align: "center" },
];
function createData(serialNumber, name, company, title, industry) {
  return { serialNumber, name, company, title, industry };
}
const headingStyles = {
  color: "#FFF",
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  border: "1px solid #FFF",
  backgroundColor: "inherit",
};
const cellStyles = {
  color: "rgba(255, 255, 255, 0.50)",
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  border: "1px solid rgba(255, 255, 255, 0.50)",
  backgroundColor: "inherit",
};
const downloadBtnStyles = {
  borderRadius: "8px",
  backgroundColor: "#374CE7",
  padding: "8px 24px",
  color: "#FFFFFF",
  border: "none",
  fontSize: "12px",
  fontWeight: 700,
};

export default function UserManagement() {
  const dispatch = useDispatch();
  const { totalUsers, recentUsers, industryStats, departmentStats } =
    useSelector((state) => state.admin);
  const { isLoading } = useSelector((state) => state.loader);

  const CreateRows = (recentUsers) => {
    const rows = [];
    if (recentUsers) {
      recentUsers.forEach((element, index) => {
        rows.push(
          createData(
            index + 1,
            element.name,
            element.currentCompany,
            element.title,
            element.industry
          )
        );
      });
    }
    return rows;
  };

  const industryPieChartData = {
    labels:
      industryStats && industryStats.length > 0
        ? industryStats.map((item) => item.industry)
        : [],
    datasets: [
      {
        label: "# of Registered Industries",
        data:
          industryStats && industryStats.length > 0
            ? industryStats.map((item) => item.percentage)
            : [],
        backgroundColor: [
          "#374CE7",
          "#EFF0FB",
          "#D7D9F4",
          "#B6BBEC",
          "#9AA0E5",
        ],
        borderColor: ["white", "white", "white", "white", "white"],
        borderWidth: 1,
      },
    ],
  };

  const departmentPieChartData = {
    labels:
      departmentStats && departmentStats.length > 0
        ? departmentStats.map((item) => item.department)
        : [],
    datasets: [
      {
        label: "# of Registered Departments",
        data:
          departmentStats && departmentStats.length > 0
            ? departmentStats.map((item) => item.percentage)
            : [],
        backgroundColor: [
          "#374CE7",
          "#EFF0FB",
          "#D7D9F4",
          "#B6BBEC",
          "#9AA0E5",
        ],
        borderColor: ["white", "white", "white", "white", "white"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    dispatch(recentUsersThunkMiddleware());
    dispatch(profileIndustryStatsThunkMiddleware());
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.topHeading}>
        <div className={styles.left}>
          <div className={styles.displayName}>User Management</div>
        </div>
        {/* <div className={styles.right}>
          <div className={styles.icons}>
            <div className={styles.bellIcon}>
              <img src={bell}></img>
            </div>
            <div className={styles.profileIcon}>
              <div className={styles.letter}>V</div>
            </div>
          </div>
        </div> */}
      </div>
      <div className={styles.topic}>
        <div className={styles.displayName}>Hi Vlad,</div>
        <div className={styles.welcomeNote}>
          Welcome to the Admin Dashboard, where you have complete control over
          the AI marketing platform.
        </div>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.benchMark}>
          <BenchMarkCard
            heading={"Total number of users registration"}
            value={totalUsers}
            comment={""}
          />
          <div className={styles.chart}>
            <div className={styles.chartName}>Registered Industries</div>
            <Pie
              data={industryPieChartData}
              options={{
                plugins: { legend: { position: "right" } },
                responsive: false,
                maintainAspectRatio: true,
              }}
            />
          </div>
          {/* <div className={styles.chart}>
            <div className={styles.chartName}>Registered Departments</div>
            <Pie
              data={departmentPieChartData}
              options={{
                plugins: { legend: { position: "right" } },
                responsive: false,
                maintainAspectRatio: true,
              }}
            />
          </div> */}
        </div>
        <div className={styles.benchMarkList}>
          <div className={styles.heading}>
            <div className={styles.text}>Recent users</div>
            <div className={styles.downloadBtn}>
              <Button style={downloadBtnStyles}>Download as CSV</Button>
            </div>
          </div>
          <StickyHeadTable>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={headingStyles}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableSkeleton col={6} />
              ) : (
                <>
                  {recentUsers ? (
                    CreateRows(recentUsers).map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={cellStyles}
                              >
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  ) : (
                    <>
                      <TableRow>
                        <TableCell colSpan={6} style={cellStyles}>
                          <Typography style={{ textAlign: "center" }}>
                            No Users to show
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </>
              )}
            </TableBody>
          </StickyHeadTable>
        </div>
      </div>
    </div>
  );
}
