export const filterDeliverablesDetails = (inputData) => {
  // Define a helper function to map complexity details
  function mapComplexityDetails(complexityDetails, subDeliverable) {
    return complexityDetails.map((detail) => ({
      subDeliverable: subDeliverable,
      complexity: detail.complexity,
      number: parseInt(detail.totalNumber),
      hours: detail.totalHours,
      cost: detail.totalRate,
    }));
  }

  // Transform DeliverableDetails into the desired format
  const transformedData = inputData.reduce((result, item) => {
    const existingCategory = result.find(
      (entry) => entry.category === item.category
    );
    if (existingCategory) {
      const existingDeliverable = existingCategory.deliverables.find(
        (deliverable) => deliverable.deliverable === item.deliverable
      );
      if (existingDeliverable) {
        existingDeliverable.subDeliverables.push(
          ...mapComplexityDetails(item.complexityDetails, item.subDeliverable)
        );
      } else {
        existingCategory.deliverables.push({
          deliverable: item.deliverable,
          subDeliverables: mapComplexityDetails(
            item.complexityDetails,
            item.subDeliverable
          ),
        });
      }
    } else {
      result.push({
        category: item.category,
        deliverables: [
          {
            deliverable: item.deliverable,
            subDeliverables: mapComplexityDetails(
              item.complexityDetails,
              item.subDeliverable
            ),
          },
        ],
      });
    }
    return result;
  }, []);

  return transformedData;
};
