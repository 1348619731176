import styles from "./Home.module.css";
import logo from "../../assets/images/AlexLogo.png";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa";
import DashboardImg from "../../assets/images/Dashboard.png";
import { useDispatch } from "react-redux";
import { setMiscellaneousData } from "../../redux/features/Miscellaneous/Index";
import { IoIosArrowForward } from "react-icons/io";

const BoxData = [
  {
    title: "PLANNING",
    content: "Start a conversation with Alex and build a plan",
  },
  {
    title: "BUDGETTED",
    content: "Get Alex's stamp of approval on your plan",
  },
  {
    title: "ON DEMAND",
    content: "Benchmark outputs or rate card in real-time",
  },
];

export default function Home() {
  const dispatch = useDispatch();

  return (
    <div className={styles.home}>
      <div
        className={styles.top1}
        onClick={() => dispatch(setMiscellaneousData({ authButton: "logIn" }))}
      >
        <Link to={"/user"} className={styles.getStarted}>
          <div className={styles.getLoginBtnStyles}>
            <div>Log In</div>
          </div>
        </Link>
      </div>

      <div className={styles.top2}>
        <div className={styles.left}>
          <Link to={"/"} className={styles.logo}>
            <img src={logo} alt="logo" />
          </Link>

          <div className={styles.title}>
            <div className={styles.text1}>Smart</div>
            <span className={styles.text1}>Benchmarks</span>
            <div className={styles.text3}>with GEN AI</div>
            <span className={styles.text2}>at Your Fingertips</span>
          </div>

          <div>
            <p className={styles.desc}>
              Alex is powered by billions of recent marketing investments, over
              50 years of human intelligence and turbo-charged with bespoke Gen
              Al technology. MORE{" >"}
            </p>
          </div>
          <div
            onClick={() =>
              dispatch(setMiscellaneousData({ authButton: "signUp" }))
            }
          >
            <Link to={"/user"} className={styles.getStarted}>
              <div className={styles.getStartedBtnStyles}>
                <div>Get Started!</div>
                <FaArrowRightLong />
              </div>
            </Link>
          </div>
        </div>

        <div className={styles.right}>
          <div className={styles.card1}>
            <h1 className={styles.heading}>
              Alex will impartially benchmark your costs, empowering you to make
              better marketing and commercial decisions
            </h1>

            <div className={styles.boxes}>
              {BoxData.map((item, index) => (
                <div key={index} className={styles.box}>
                  <h1>{item.title}</h1>
                  <p>{item.content}</p>
                  <figure>
                    <FaArrowRight size={24} />
                  </figure>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.card2}>
            <div className={styles.card2box}>
              <h1 className={styles.meetHeading}>Meet Alex</h1>
              <div className={styles.introVideo}></div>
            </div>
            <div className={styles.card2box}>
              <h1 className={styles.heading}>
                Not sure where to start Let us help you decide
              </h1>
              <div className={styles.dashboardImg}>
                <img src={DashboardImg} alt="dashboard" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
