import styles from "./Step4.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setHistory } from "../../../redux/features/History/index";
import { AnimatePresence, motion } from "framer-motion";
import { useRef, useState } from "react";
import AgencyTypeDropDown from "../Step2/AgencyTypeDropDown";
import { setBenchmark } from "../../../redux/features/BenchmarkDetails";
import { MdOutlineHelpOutline } from "react-icons/md";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const scopeOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export default function Step4() {
  const dispatch = useDispatch();
  const { scope } = useSelector((state) => state.benchmarkDetails);
  const count = useRef(0);
  const [key, setKey] = useState(0);

  // useEffect(() => {
  //   count.current += 1;
  //   if (scope == "Yes" && count.current > 1) {
  //     dispatch(setHistory("step5"));
  //   }
  // }, [scope, key]);

  const nextStepTrigger = () => {
    setKey((prevKey) => prevKey + 1);
  };

  return (
    <AnimatePresence>
      <motion.div
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.25, ease: "easeIn" }}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: "1.5rem",
          opacity: 1,
        }}
        className={styles.step4}
      >
        {/* <div className={styles.heading}>
          Fill in the questions below for Alex to extract relevant details for
          your algorithm
        </div> */}
        <div className={styles.stepNo}>Step 4 of 5</div>
        <div className={styles.about}>
          <div className={styles.text}>About the deliverable</div>
          <div className={styles.helpLogo}>
            <MdOutlineHelpOutline />
          </div>
        </div>
        <div className={styles.inputBox}>
          <div className={styles.inputLabel}>
            Do you have a specific scope in mind?
          </div>
          <div className={styles.inputText}>
            <AgencyTypeDropDown
              options={scopeOptions}
              name={"Select"}
              setOption={setBenchmark}
              title={"scope"}
              initialValue={scope}
              nextStepTrigger={nextStepTrigger}
            />
          </div>
        </div>
        <div className={styles.arrow}>
          <div
            className={styles.arrowIcon}
            onClick={(e) => {
              dispatch(setHistory("step3"));
            }}
          >
            <FaArrowLeftLong />
          </div>
          <div
            className={styles.arrowIcon}
            onClick={(e) => {
              dispatch(setHistory("step5"));
            }}
          >
            <FaArrowRightLong />
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
