import styles from "./Step3.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setHistory } from "../../../redux/features/History/index";
import Button from "../../Button";
import { useEffect, useState } from "react";
import InputTextButton from "../../InputTextButton";
import AgencyTypeDropDown from "../Step2/AgencyTypeDropDown";
import { setBenchmark } from "../../../redux/features/BenchmarkDetails";
import { MdOutlineHelpOutline } from "react-icons/md";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const actionBtnStyles = {
  borderRadius: "4px",
  borderBottom: "0.5px solid rgba(0, 0, 0, 0.10)",
  backgroundColor: "#FFF",
  color: "#374CE7",
  justifyContent: "left",
  paddingLeft: "1rem",
  paddingRight: "1rem",
};
const reselectBtn = {
  borderRadius: "4px",
  borderBottom: "0.5px solid rgba(0, 0, 0, 0.10)",
  backgroundColor: "#FFF",
  color: "#374CE7",
  justifyContent: "left",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  width: "6rem",
  fontWeight: 900,
};

export default function Step3() {
  const dispatch = useDispatch();

  const { ambitionsList, developmentTypesList } = useSelector(
    (state) => state.miscellaneous
  );

  const { intendedUse, ambition, budget, primaryMarketsCount } = useSelector(
    (state) => state.benchmarkDetails
  );
  const { update } = useSelector((state) => state.history);

  const [budgetSelected, setBudgetSelected] = useState(budget ? true : false);
  const [budgetType, setBudgetType] = useState(budget ? "yes" : "no");

  const reselectHandler = () => {
    dispatch(setBenchmark({ budget: null }));
    setBudgetSelected(false);
    setBudgetType("");
  };

  useEffect(() => {
    if (update === "updateSummary") {
      setBudgetSelected(true);
    }
  }, []);
  return (
    <>
      <div className={styles.step3}>
        {/* <div className={styles.heading}>
          Fill in the questions below for Alex to extract relevant details for
          your algorithm
        </div> */}
        <div className={styles.stepNo}>Step 3 of 5</div>
        <div className={styles.about}>
          <div className={styles.text}>About the deliverable</div>
          <div className={styles.helpLogo}>
            <MdOutlineHelpOutline />
          </div>
        </div>
        <div className={styles.inputBox}>
          <div className={styles.inputLabel}>
            What's the ambition of your work?
          </div>
          <div className={styles.inputText}>
            <AgencyTypeDropDown
              options={ambitionsList}
              name={"Select your ambition"}
              title={"ambition"}
              setOption={setBenchmark}
              initialValue={ambition}
            />
          </div>
        </div>
        <div className={styles.inputBox}>
          <div className={styles.inputLabel}>
            Are you developing for Global/Region use, or are you involving any
            of your markets?
          </div>
          <div className={styles.inputText}>
            <AgencyTypeDropDown
              options={developmentTypesList}
              name={"Select your development type"}
              title={"intendedUse"}
              setOption={setBenchmark}
              initialValue={intendedUse}
            />
          </div>
        </div>
        <div className={styles.inputBox}>
          <div className={styles.inputLabel}>
            Can you let us know how many Primary markets will need to feed into
            the development process?
          </div>
          <div className={styles.inputBoxContainer}>
            <div className={styles.inputTextField}>
              <InputTextButton
                name={"Add your Global reach"}
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid rgba(255, 255, 255, 0.50)",
                }}
                setValue={setBenchmark}
                initialValue={primaryMarketsCount}
                title={"primaryMarketsCount"}
              />
            </div>
          </div>
        </div>
        <div className={styles.inputBox}>
          <div className={styles.inputLabel}>
            Do you have a budget in mind for the deliverable?
          </div>
          <div className={styles.buttonBox}>
            {!budgetSelected ? (
              <>
                <div className={styles.btn}>
                  <Button
                    style={actionBtnStyles}
                    onClick={(e) => {
                      setBudgetSelected(true);
                      setBudgetType("yes");
                    }}
                  >
                    Yes
                  </Button>
                </div>
                <div className={styles.btn}>
                  <Button
                    style={actionBtnStyles}
                    onClick={(e) => {
                      setBudgetSelected(true);
                      setBudgetType("no");
                    }}
                  >
                    No
                  </Button>
                </div>
              </>
            ) : (
              <>
                {budgetType === "yes" ? (
                  <div className={styles.btn}>
                    <InputTextButton
                      name={"Add your amount"}
                      style={actionBtnStyles}
                      type="number"
                      initialValue={budget}
                      title={"budget"}
                      setValue={setBenchmark}
                    />
                  </div>
                ) : (
                  <div className={styles.btn}>
                    <InputTextButton
                      name={"Add your amount"}
                      style={actionBtnStyles}
                      type="text"
                      initialValue={"No Budget"}
                      title={"budget"}
                      setValue={setBenchmark}
                      disable={true}
                    />
                  </div>
                )}
                <Button style={reselectBtn} onClick={reselectHandler}>
                  Reselect
                </Button>
              </>
            )}
          </div>
        </div>
        <div className={styles.arrow}>
          <div
            className={styles.arrowIcon}
            onClick={(e) => {
              dispatch(setHistory("step2"));
            }}
          >
            <FaArrowLeftLong />
          </div>
          <div
            className={styles.arrowIcon}
            onClick={(e) => {
              dispatch(setHistory("step4"));
            }}
          >
            <FaArrowRightLong />
          </div>
        </div>
      </div>
    </>
  );
}
