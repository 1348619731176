import styles from "./Chat.module.css";
import Modal from "./Modal";
import Button from "../Button";
import InputTextButton from "../InputTextButton";
import { useDispatch, useSelector } from "react-redux";
import { setHistory } from "../../redux/features/History";
import {
  clearChats,
  fetchChatsThunkMiddleware,
  saveConversationThunkMiddleware,
  setMessage,
} from "../../redux/features/Chat";
import { IoMdSend } from "react-icons/io";
import { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const saveBtnStyles = {
  height: "2.8rem",
  fontWeight: "700",
  backgroundColor: "#374CE7",
  border: "0",
};
const cancelBtnStyles = {
  height: "2.8rem",
  fontWeight: "400",
  backgroundColor: "inherit",
};

const inputBtnStyles = {
  borderRadius: "8px",
  border: "1px solid #FFF",
  background: `rgba(255, 255, 255, 0.10)`,
  color: "#FFF",
  fontSize: "18px",
  fontWeight: 500,
  padding: "0.8rem 5rem 0.8rem 0.8rem",
};
export default function Chat() {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [cancelModalopen, setCancelModalopen] = useState(false);
  const [saveModalopen, setSaveModalopen] = useState(false);

  const { message, messages, conversationName } = useSelector(
    (state) => state.chat
  );
  const { isSaveLoading } = useSelector((state) => state.loader);

  const sendMessageHandler = (e) => {
    e.preventDefault(e);
    dispatch(
      fetchChatsThunkMiddleware(
        {
          message: { content: message, role: "user" },
          messages,
        },
        (error) => {}
      )
    );
    //Reset Input
    if (inputRef.current && inputRef.current.resetInput) {
      inputRef.current.resetInput();
    }
  };

  const handleCancelConversationOpen = () => {
    setCancelModalopen(true);
  };

  const handleCancelConversationClose = () => {
    setCancelModalopen(false);
  };

  const cancelConversationHandler = () => {
    handleCancelConversationClose();
    dispatch(clearChats([]));
    dispatch(setHistory("conversation"));
  };

  const handleSaveConversationOpen = () => {
    setSaveModalopen(true);
  };

  const handleSaveConversationClose = () => {
    setSaveModalopen(false);
  };

  const saveConversationHandler = (e) => {
    dispatch(
      saveConversationThunkMiddleware(
        { conversationName, messages },
        (error) => {
          if (!error) {
            handleSaveConversationClose();
            dispatch(clearChats([]));
            dispatch(setHistory("conversation"));
          }
        }
      )
    );
  };

  useEffect(() => {
    if (inputRef.current && inputRef.current.resetInput) {
      inputRef.current.resetInput();
    }
  }, [messages]);

  return (
    <>
      <div className={styles.chat}>
        <div className={styles.main}>
          <div className={styles.modalContainer}>
            <Modal>
              <form
                onSubmit={sendMessageHandler}
                className={styles.sendMessageBtn}
              >
                <div className={styles.sendIcon} onClick={sendMessageHandler}>
                  <IoMdSend />
                </div>
                <InputTextButton
                  ref={inputRef}
                  name={"Send a message..."}
                  style={inputBtnStyles}
                  setValue={setMessage}
                  initialValue={message}
                  title={"message"}
                />
              </form>
              <div className={styles.buttonBox}>
                <div className={styles.cancelBtn}>
                  <Button
                    onClick={handleCancelConversationOpen}
                    style={cancelBtnStyles}
                  >
                    Cancel
                  </Button>
                </div>
                <div className={styles.saveBtn}>
                  <Button
                    onClick={handleSaveConversationOpen}
                    style={saveBtnStyles}
                  >
                    Save
                  </Button>
                </div>
              </div>{" "}
            </Modal>
          </div>
        </div>
      </div>

      {/* Cancel Conversation Modal  */}
      <Dialog
        open={cancelModalopen}
        onClose={handleCancelConversationClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles.cancelModalContainer}
      >
        <div className={styles.cancelModal}>
          <DialogTitle
            id="alert-dialog-title"
            color={"white"}
            fontSize={30}
            textAlign={"center"}
          >
            {"Benchmark Conversation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              color={"white"}
              textAlign={"center"}
            >
              Do you sure want to cancel conversation?
            </DialogContentText>
          </DialogContent>
          <div className={styles.buttonBox}>
            <div className={styles.cancelBtn}>
              <Button
                onClick={handleCancelConversationClose}
                style={cancelBtnStyles}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.saveBtn}>
              <Button style={saveBtnStyles} onClick={cancelConversationHandler}>
                Agree
              </Button>
            </div>
          </div>{" "}
        </div>
      </Dialog>

      {/* Save Conversation Modal  */}
      <Dialog
        open={saveModalopen}
        onClose={handleSaveConversationClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={styles.cancelModal}>
          <DialogTitle
            id="alert-dialog-title"
            color={"white"}
            fontSize={30}
            textAlign={"center"}
          >
            {"Benchmark Conversation"}
          </DialogTitle>
          <DialogContent>
            <div className={styles.inputGroup}>
              <div className={styles.label}>Conversation Name</div>
              <div className={styles.inputTextbox}>
                <InputTextButton
                  name={"Enter Conversation Name"}
                  style={{
                    backgroundColor: "transparent",
                  }}
                  setValue={setMessage}
                  initialValue={conversationName}
                  title={"conversationName"}
                />
              </div>
            </div>
          </DialogContent>
          <div className={styles.buttonBox}>
            <div className={styles.cancelBtn}>
              <Button
                onClick={handleSaveConversationClose}
                style={cancelBtnStyles}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.saveBtn}>
              <Button style={saveBtnStyles} onClick={saveConversationHandler}>
                {isSaveLoading ? (
                  <CircularProgress size={30} color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </div>{" "}
        </div>
      </Dialog>
    </>
  );
}
