import React, { useState } from "react";
import styles from "./Profile.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setHome } from "../../../redux/features/Home";
import { IoMdTime, IoMdMail, IoMdCheckmark } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import InputGroup from "./InputGroup";
import {
  getProfileOptionsThunkMiddleware,
  getProfileThunkMiddleware,
  setPersonalInfo,
  setSelectedOption,
  updateProfileThunkMiddleware,
} from "../../../redux/features/Profile";
import SelectGroup from "./SelectGroup";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { MdOutlineArrowBack } from "react-icons/md";

const Profile = () => {
  const dispatch = useDispatch();

  const {
    name,
    email,
    currentCompany,
    title,
    industry,
    country,
    globalOrRegional,
    b2bOrB2c,
  } = useSelector((state) => state.profile);
  const { countryList, focusList, organisationsList } = useSelector(
    (state) => state.miscellaneous
  );
  const { isLoading } = useSelector((state) => state.loader);

  const [profileEdit, setProfileEdit] = useState(false);

  useEffect(() => {
    dispatch(getProfileOptionsThunkMiddleware());
    dispatch(getProfileThunkMiddleware());
  }, []);

  const updateHandler = () => {
    dispatch(
      updateProfileThunkMiddleware(
        {
          name,
          currentCompany,
          title,
          country,
          globalOrRegional,
          b2bOrB2c,
          industry,
          email,
        },
        () => {
          setProfileEdit(false);
        }
      )
    );
  };

  return (
    <div className={styles.profileContainer}>
      {/* Heading  */}
      <div className={styles.topHeading}>
        <div className={styles.left}>
          <div className={styles.displayName}>Profile</div>
          <div className={styles.welcomeNote}>
            Mentioning below the detailed view of your profile and you can edit
            your profile!
          </div>
        </div>
        <div className={styles.right}>
          <div
            className={styles.headingIcon}
            onClick={() => dispatch(setHome("home"))}
          >
            <div className={styles.letter}>
              <MdOutlineArrowBack />
            </div>
          </div>
        </div>
      </div>

      {/* Content  */}
      <div className={styles.scrollBar}>
        {/* Header  */}
        <div className={styles.profileHeader}>
          {/* Avatar  */}
          <div
            className={styles.profileIcon}
            onClick={() => {
              dispatch(setHome("profile"));
            }}
          >
            <div className={styles.letter}>{name ? name[0] : "A"}</div>
          </div>

          {/* Profile Baisc Details  */}
          <div className={styles.profileInfoContainer}>
            <div className={styles.profileName}>{name}</div>

            <div className={styles.profileInfo}>
              {/* Time  */}
              <div className={styles.profileInfoIcon}>
                <span>
                  <IoMdTime />
                </span>
                <p>19:35 local time</p>
              </div>
              {/* Mail */}
              <div className={styles.profileInfoIcon}>
                <span>
                  <IoMdMail />
                </span>
                <p>{email}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Onboarding Details  */}
        <div className={styles.detailContainer}>
          {/* header  */}
          <div className={styles.detailHeader}>
            <h3></h3>
            <div className={styles.updateIcon}>
              {isLoading ? (
                <CircularProgress size={30} color="inherit" />
              ) : (
                <>
                  {!profileEdit ? (
                    <span className="" onClick={() => setProfileEdit(true)}>
                      <CiEdit />
                    </span>
                  ) : (
                    <span className="" onClick={updateHandler}>
                      <IoMdCheckmark />
                    </span>
                  )}
                </>
              )}
            </div>
          </div>

          {/* Details  */}
          <div className={styles.details}>
            <InputGroup
              label={"Title"}
              placeholder={"Title"}
              initialValue={title}
              setFunction={setPersonalInfo}
              activeStatus={!profileEdit}
              title={"title"}
            />
            <InputGroup
              label={"Name"}
              placeholder={"Name"}
              initialValue={name}
              setFunction={setPersonalInfo}
              activeStatus={!profileEdit}
              title={"name"}
            />
            <InputGroup
              label={"Email"}
              placeholder={"Email"}
              initialValue={email}
              setFunction={setPersonalInfo}
              activeStatus={!profileEdit}
              title={"email"}
            />

            <InputGroup
              label={"Current Company"}
              placeholder={"Current Company"}
              initialValue={currentCompany}
              setFunction={setPersonalInfo}
              activeStatus={!profileEdit}
              title={"currentCompany"}
            />
            <InputGroup
              label={"Industry"}
              placeholder={"Industry"}
              initialValue={industry}
              setFunction={setPersonalInfo}
              activeStatus={!profileEdit}
              title={"industry"}
            />

            <SelectGroup
              label={"Global Or Regional"}
              initialValue={globalOrRegional}
              options={organisationsList}
              setFunction={setPersonalInfo}
              activeStatus={!profileEdit}
              title={"globalOrRegional"}
            />
            <SelectGroup
              label={"Country"}
              initialValue={country}
              options={countryList}
              setFunction={setPersonalInfo}
              activeStatus={!profileEdit}
              title={"country"}
            />
            <SelectGroup
              label={"B2B Or B2C"}
              initialValue={b2bOrB2c}
              options={focusList}
              setFunction={setPersonalInfo}
              activeStatus={!profileEdit}
              title={"b2bOrB2c"}
            />
          </div>
        </div>
        {/* Onboarding Details  */}
        <div className={styles.detailContainer}>
          {/* header  */}
          {/* <div className={styles.detailHeader}>
            <h3></h3>
            <div className={styles.updateIcon}>
              {isLoading ? (
                <CircularProgress size={30} color="inherit" />
              ) : (
                <>
                  {!showOnboardingEdit ? (
                    <span
                      className=""
                      onClick={() => setShowOnboardingEdit(true)}
                    >
                      <CiEdit />
                    </span>
                  ) : (
                    <span
                      className=""
                      onClick={() => {
                        updateHandler();
                        setShowOnboardingEdit(false);
                      }}
                    >
                      <IoMdCheckmark />
                    </span>
                  )}
                </>
              )}
            </div>
          </div> */}

          {/* Details  */}
          <div className={styles.details}></div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
