import styles from "./RightPage.module.css";
import Button from "../../Button";
import InputTextButton from "../../InputTextButton";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../redux/features/Loader";
import axiosInstance from "../../../utils/axiosInstance";
import { setToken } from "../../../redux/features/Auth";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router";
import { logoutThunkMiddleware } from "../../../redux/features/Profile";

export default function RightPage() {
  const dispatch = useDispatch();
  const location = useLocation();

  const userName = useRef(""),
    passWord = useRef("");

  const { isLoading } = useSelector((state) => state.loader);
  const { role } = useSelector((state) => state.auth);

  const onUserNameAndPasswordAuth = async (e) => {
    try {
      dispatch(setLoader(true));
      e.preventDefault();

      if (role === "USER" && location.pathname === "/admin") {
        dispatch(logoutThunkMiddleware());
      }

      const email = userName.current.getValue(),
        password = passWord.current.getValue();
      console.log(email, password);
      if (!email.length || !password.length) {
        throw new Error("Please enter both username and password");
      }

      const res = await axiosInstance({
        url: process.env.REACT_APP_ADMIN_LOGIN,
        method: "POST",
        data: { email, password },
      });
      if (res.status === 200) {
        const { token } = res.data;
        dispatch(setToken({ token, isAuthenticated: true }));
        toast.success("SignIn Successfully");
      } else {
        const { message } = res.data;
        throw new Error(message);
      }
    } catch (error) {
      dispatch(setToken({ token: null, isAuthenticated: false }));
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      dispatch(setLoader(false));
    }
  };

  return (
    <div className={styles.rightPage}>
      <div className={styles.modal}>
        <form className={styles.form} onSubmit={onUserNameAndPasswordAuth}>
          <div className={styles.formItem}>
            <div className={styles.inputLabel}>Username</div>
            <div className={styles.inputText}>
              <InputTextButton name="Enter your email" ref={userName} />
            </div>
          </div>
          <div className={styles.formItem}>
            <div className={styles.inputLabel}>Password</div>
            <div className={styles.inputText}>
              <InputTextButton
                name="Enter your password"
                ref={passWord}
                type={"password"}
              />
            </div>
          </div>
          <div className={styles.submitButton}>
            <Button
              style={{
                backgroundColor: "#374CE7",
                fontWeight: "700",
                border: "none",
              }}
              disable={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={30} color="inherit" />
              ) : (
                "Sign in"
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
