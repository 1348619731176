import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isUpdateLoading: false,
  isSaveLoading: false,
  isChatLoading: false,
};

const loaderSlice = createSlice({
  name: "loader",
  initialState: initialState,
  reducers: {
    setLoader(state, action) {
      state.isLoading = action.payload;
    },
    setChatLoader(state, action) {
      state.isChatLoading = action.payload;
    },
    setUpdateLoader(state, action) {
      state.isUpdateLoading = action.payload;
    },
    setSaveLoader(state, action) {
      state.isSaveLoading = action.payload;
    },
  },
});

export const { setLoader, setChatLoader, setUpdateLoader, setSaveLoader } =
  loaderSlice.actions;
export default loaderSlice.reducer;
