import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  BrowserRouter,
  Routes,
  Navigate,
} from "react-router-dom";
import User from "./pages/User";
import Admin from "./pages/Admin";
import Token from "./components/Token";
import "./App.css";
import { setToken } from "./redux/features/Auth";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import { setSaveStatus } from "./redux/features/Profile";
import axios from "./utils/axiosInstance";
import Chat from "./components/Chat/Chat";
import Home from "./components/Home/Home";

const Protected = ({ children }) => {
  const token = localStorage.getItem("token");
  if (!token) {
    return <Navigate to="/" replace />;
  }
  return children;
};

function App() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.loader);
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    const checkIsUserAuthenticated = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          const res = await axios({
            method: "GET",
            url: process.env.REACT_APP_IS_AUTHENTICATED,
          });
          const { role } = res.data;
          dispatch(setToken({ token, isAuthenticated: true, role }));
        } else {
          localStorage.clear();
          dispatch(
            setToken({ token: null, isAuthenticated: false, role: null })
          );
          dispatch(setSaveStatus({ isSaved: false }));
        }
      } catch (error) {
        localStorage.clear();
        dispatch(setToken({ token: null, isAuthenticated: false, role: null }));
        dispatch(setSaveStatus({ isSaved: false }));
      }
    };
    checkIsUserAuthenticated();
  }, [isAuthenticated, dispatch]);

  return (
    <div className={`App ${isLoading ? "" : ""}`}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/user" element={<User />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/auth" element={<Token />} />
          <Route path="/chat" element={<Chat />} />
        </Routes>
      </Router>
      <Toaster />
    </div>
  );
}

export default App;
