import { useDispatch } from "react-redux";
import styles from "./CalibreDropDown.module.css";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

export default function CalibreDropDown({
  name,
  options,
  initialValue,
  setOption,
  title,
}) {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(initialValue);
  const [clicked, setClicked] = useState(false);
  const [modal, setModal] = useState("none");

  useEffect(() => {
    if (typeof setOption === "function") {
      const obj = {};
      obj[title] = selectedOption;
      dispatch(setOption(obj));
    }
  }, [selectedOption]);

  return (
    <>
      <div
        className={`${styles.calibreDropDown} ${selectedOption !== null ? styles.selected : ""
          }`}
        onClick={(e) => {
          setClicked((state) => !state);
        }}
      >
        {!selectedOption ? name : selectedOption}
      </div>
      <div className={styles.dropDownIndicator}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M6 9L12 15L18 9"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <AnimatePresence>
        {clicked && (
          <motion.div
            initial={{ height: "0", opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{
              duration: 0.25,
              ease: "easeIn",
              damping: 25,
              stiffness: 500,
            }}
          >
            {options && options.length > 0 ? options.map((op) => {
              return (
                <div
                  className={styles.menuItem}
                  onClick={(e) => {
                    setSelectedOption(op.tier);
                    setClicked((state) => !state);
                  }}
                >
                  <div className={styles.optionsContainer}>
                    <div className={styles.option}>
                      <div className={styles.content}>
                        <div className={styles.heading}>{op.tier_name}</div>
                        <div className={styles.subHeading}>
                          {op?.description}
                        </div>
                      </div>
                      <div
                        className={styles.helpLogo}
                        onMouseEnter={(e) => {
                          setModal(op.calibre_agency_id);
                        }}
                        onMouseLeave={(e) => {
                          setModal("none");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
                            stroke="#374CE7"
                            stroke-opacity="0.9"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.08984 9.50008C9.32495 8.83175 9.789 8.26819 10.3998 7.90921C11.0106 7.55024 11.7287 7.41902 12.427 7.53879C13.1253 7.65857 13.7587 8.02161 14.2149 8.56361C14.6712 9.10561 14.9209 9.7916 14.9198 10.5001C14.9198 12.5001 11.9198 13.5001 11.9198 13.5001"
                            stroke="#374CE7"
                            stroke-opacity="0.9"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 17.5H12.01"
                            stroke="#374CE7"
                            stroke-opacity="0.9"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  {modal === op.calibre_agency_id && (
                    <div className={styles.overlay}>{op.details}</div>
                  )}
                </div>
              );
            }) :
              <div className={styles.menuItem} >
                <div className={styles.optionsContainer}>
                  <div className={styles.option}>
                    <div className={styles.content}>
                      <div className={styles.heading}>No Item to Select</div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
