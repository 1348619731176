import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axiosInstance";
import toast from "react-hot-toast";

const initialState = {
  countryList: null,
  tierAgencyList: null,
  agencyTypeList: null,
  industriesList: null,
  ambitionsList: null,
  calibresList: null,
  subDeliverables: null,
  focusList: null,
  organisationsList: null,
  developmentTypesList: null,

  authButton: "signUp",
};

const miscellaneousSlice = createSlice({
  name: "miscellaneousDetails",
  initialState: initialState,
  reducers: {
    setMiscellaneousData(state, action) {
      Object.keys(action.payload).forEach((key, index) => {
        state[key] = action.payload[key];
      });
    },
  },
});

export const { setMiscellaneousData } = miscellaneousSlice.actions;

export default miscellaneousSlice.reducer;

//Getting Country, Tier, Agency Type, Industries, Calibres, Ambitions, SubDeliverables

export const getOptionListsThunkMiddleware = () => async (dispatch) => {
  try {
    const response = await axios({
      method: "GET",
      url: process.env.REACT_APP_GET_OPTION_LIST,
    });

    if (response.status === 200) {
      const {
        agencyTypeOptions,
        ambitionsOptions,
        calibreOptions,
        countryOptions,
        developmentTypeOptions,
        focusTypeOptions,
        industryOptions,
        organisationOptions,
        subDeliverableOptions,
        agencyTierOptions,
      } = response.data;

      dispatch(
        setMiscellaneousData({
          countryList: countryOptions,
          tierAgencyList: agencyTierOptions,
          agencyTypeList: agencyTypeOptions,
          industriesList: industryOptions,
          ambitionsList: ambitionsOptions,
          calibresList: calibreOptions,
          subDeliverables: subDeliverableOptions,
          focusList: focusTypeOptions,
          organisationsList: organisationOptions,
          developmentTypesList: developmentTypeOptions,
        })
      );
    }
  } catch (error) {
    let message = "ERROR";
    if (error.hasOwnProperty("response")) {
      message = error.response.data.message;
    } else {
      message = error.message;
    }
    toast.error(message);
  }
};
