import { useState } from "react";
import Auth from "../components/Admin/Auth/Auth";
import Dashboard from "../components/Admin/Dashboard/Dashboard";
import { useSelector } from "react-redux";

export default function Admin() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [home, setHome] = useState(true);
  const { token, isAuthenticated, role } = useSelector((state) => state.auth);
  const { isSaved } = useSelector((state) => state.profile);

  return (
    <>
      {isAuthenticated && role && role === process.env.REACT_APP_ADMIN ? (
        <Dashboard />
      ) : (
        <Auth />
      )}
    </>
  );
}
