import styles from "./RightPage.module.css";
import Button from "../Button";
import InputTextButton from "../InputTextButton";
import { useRef } from "react";
import toast from "react-hot-toast";
import { setToken } from "../../redux/features/Auth/index";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../redux/features/Loader";
import {
  logoutThunkMiddleware,
  setSaveStatus,
} from "../../redux/features/Profile";
import axios from "../../utils/axiosInstance";
import { CircularProgress } from "@mui/material";
import {
  getOptionListsThunkMiddleware,
  setMiscellaneousData,
} from "../../redux/features/Miscellaneous/Index";
import { setNavBar } from "../../redux/features/NavBar";
import { Await, useLocation } from "react-router";

export default function RightPage({ setIsLoggedIn }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const userName = useRef(""),
    passWord = useRef("");

  const { isLoading } = useSelector((state) => state.loader);
  const { authButton } = useSelector((state) => state.miscellaneous);
  const { isAuthenticated, role } = useSelector((state) => state.auth);

  const onUserNameAndPasswordAuth = async (e) => {
    try {
      dispatch(setLoader(true));
      e.preventDefault();

      if (role === "ADMIN" && location.pathname === "/user") {
        dispatch(logoutThunkMiddleware());
      }

      const email = userName.current.getValue(),
        password = passWord.current.getValue();

      if (!email.length || !password.length) {
        throw new Error("Please enter both username and password");
      }

      if (authButton === "signUp") {
        const res = await axios({
          url: process.env.REACT_APP_SIGNUP,
          method: "POST",
          data: { email, password },
        });
        if (res.status === 200) {
          //signup successful
          console.log(res.data);
          const { token, isProfileSaved } = res.data;
          dispatch(setToken({ token, isAuthenticated: true }));
          dispatch(setSaveStatus({ isSaved: isProfileSaved }));
          toast.success("Signed Up Successfully");
        } else {
          const { message } = res.data;
          throw new Error(message);
        }
      } else {
        const res = await axios({
          url: process.env.REACT_APP_LOGIN,
          method: "POST",
          data: { email, password },
        });
        if (res.status === 200) {
          //Login successful
          const { token, isProfileSaved } = res.data;
          dispatch(setToken({ token, isAuthenticated: true }));
          dispatch(setSaveStatus({ isSaved: isProfileSaved }));
          toast.success("Logged In Successfully");
        }
      }

      // Getting All Options
      dispatch(getOptionListsThunkMiddleware());
    } catch (error) {
      dispatch(setToken({ token: null, isAuthenticated: false }));
      let message = "ERROR";
      if (error.hasOwnProperty("response")) {
        message = error.response.data.message;
      } else {
        message = error.message;
      }
      toast.error(message);
    } finally {
      dispatch(setLoader(false));
    }
  };

  const onGoogleOAuth = () => {
    window.location.href = process.env.REACT_APP_OAUTH_LOGIN;
  };

  return (
    <div className={styles.rightPage}>
      <div className={styles.modal}>
        <div className={styles.authSelector}>
          <Button
            name="Sign up"
            onClick={() =>
              dispatch(setMiscellaneousData({ authButton: "signUp" }))
            }
            style={{
              backgroundColor: authButton === "signUp" ? "#374CE7" : "#1C1C1E",
              borderRadius: "10px 0 0 10px",
              border: authButton === "signUp" ? "none" : "1px solid white",
            }}
          >
            Sign Up
          </Button>
          <Button
            name="Log in"
            onClick={() =>
              dispatch(setMiscellaneousData({ authButton: "logIn" }))
            }
            style={{
              backgroundColor: authButton === "logIn" ? "#374CE7" : "#1C1C1E",
              borderRadius: "0 10px 10px 0",
              border: authButton === "logIn" ? "none" : "1px solid white",
            }}
          >
            Log in
          </Button>
        </div>
        {/* <div className={styles.header}>Sign up to explore Alex!</div> */}
        <form className={styles.form} onSubmit={onUserNameAndPasswordAuth}>
          <div className={styles.formItem}>
            <div className={styles.inputLabel}>Work email address</div>
            <div className={styles.inputText}>
              <InputTextButton
                name="Enter your email"
                ref={userName}

                // initialValue={userName.current.getValue()}
              />
            </div>
          </div>
          <div className={styles.formItem}>
            <div className={styles.inputLabel}>Password</div>
            <div className={styles.inputText}>
              <InputTextButton
                name="Enter your password"
                ref={passWord}
                type={"password"}
                // initialValue={passWord.current.getValue()}
              />
            </div>
          </div>
          <div className={styles.submitButton}>
            <Button
              name={authButton === "signUp" ? "Create account" : "Log in"}
              style={{
                backgroundColor: "#374CE7",
                fontWeight: "700",
                border: "none",
              }}
              disable={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={30} color="inherit" />
              ) : authButton === "signUp" ? (
                "Create account"
              ) : (
                "Log in"
              )}
            </Button>
          </div>
        </form>
        <div className={styles.divider}>
          <hr />
          <div className={styles.content}>or</div>
          <hr />
        </div>
        <div className={styles.googleButton}>
          <Button
            style={{
              backgroundColor: "white",
              color: "black",
              fontSize: "1rem",
              fontWeight: "600",
            }}
            onClick={onGoogleOAuth}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="https://www.loginradius.com/blog/static/a9dad0fc4bf1af95243aa5e2d017bc22/a8669/google_cover.jpg"
                width="30px"
                height="100%"
                alt="googleauth"
              />
              {"  "}
              {authButton === "signUp"
                ? "Sign up with Google"
                : "Log in with Google"}
            </div>
          </Button>
        </div>
        <div className={styles.footer}>
          By continuing, you agree to the <a>Terms of Service</a> and{" "}
          <a>Privacy Policy</a>
        </div>
      </div>
    </div>
  );
}
