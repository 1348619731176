import styles from "./Step5.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setHistory } from "../../../redux/features/History/index";
import Card from "./Card";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../../Button";
import { useState } from "react";
import AgencyTypeDropDown from "../Step2/AgencyTypeDropDown";
import { getBenchmarkInfoThunkMiddleware } from "../../../redux/features/BenchmarkDetails";
import { MdOutlineHelpOutline } from "react-icons/md";
import { CircularProgress } from "@mui/material";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const options = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
export default function Step5() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.loader);
  const { subDeliverables } = useSelector((state) => state.miscellaneous);

  console.log(subDeliverables);
  const {
    globalOrRegional,
    industry,
    b2bOrB2c,
    location,
    agencyType,
    tier,
    ambition,
    intendedUse,
    budget,
    scope,
    primaryMarketsCount,
    deliverableType,
  } = useSelector((state) => state.benchmarkDetails);

  const postBenchmarkInfo = (e) => {
    dispatch(
      getBenchmarkInfoThunkMiddleware(
        {
          globalOrRegional,
          industry,
          b2bOrB2c,
          location,
          agencyType,
          tier,
          ambition,
          intendedUse,
          budget,
          scope,
          primaryMarketsCount,
          deliverableType,
        },
        (error) => {
          if (!error) dispatch(setHistory("summary"));
        }
      )
    );
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: "easeIn" }}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: "1.5rem",
          opacity: 1,
        }}
        className={styles.step5}
      >
        {/* <div className={styles.heading}>
          Fill in the questions below for Alex to extract relevant details for
          your algorithm
        </div> */}
        <div className={styles.stepNo}>Step 5 of 5</div>
        <div className={styles.about}>
          <div className={styles.text}>About the deliverable</div>
          <div className={styles.helpLogo}>
            <MdOutlineHelpOutline />
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.inputBox}>
            <div className={styles.inputLabel}>
              What type of deliverable do you want the Alex to benchmark?
            </div>
            {/* <div className={styles.inputText}>
              <AgencyTypeDropDown
                options={options}
                isDisable={true}
                name={"Select"}
                initialValue={"Yes"}
              />
            </div> */}
          </div>

          {/* Deliverables  */}
          <DeliverablesComponent subDeliverables={subDeliverables} />

          {/* Buttons  */}
          <div className={styles.buttonBox}>
            <div className={styles.btn}>
              {/* <Button
                style={{ backgroundColor: "inherit", fontWeight: 700 }}
                onClick={(e) => {
                  dispatch(setHistory("step4"));
                }}
              >
                Reselect
              </Button> */}

              {/* <div className={styles.arrowIcon}>
                <FaArrowLeftLong
                  onClick={() => {
                    dispatch(setHistory("step4"));
                  }}
                />
              </div> */}
            </div>
            <div className={styles.btn}>
              <Button
                style={{
                  backgroundColor: "#374CE7",
                  fontWeight: 700,
                  border: "none",
                }}
                onClick={postBenchmarkInfo}
                disable={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={30} color="inherit" />
                ) : (
                  "Calculate"
                )}
              </Button>
            </div>
          </div>
        </div>

        <div className={styles.arrow}>
          <div
            className={styles.arrowIcon}
            onClick={() => {
              dispatch(setHistory("step4"));
            }}
          >
            <FaArrowLeftLong />
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

const DeliverablesComponent = ({ subDeliverables }) => {
  return (
    <>
      {subDeliverables &&
        Object.entries(subDeliverables).map(
          ([deliverableLabel, deliverableValue], index) => (
            <SubDeliverable
              key={index}
              deliverableLabel={deliverableLabel}
              deliverableValue={deliverableValue}
            />
          )
        )}
    </>
  );
};

const SubDeliverable = ({ deliverableLabel, deliverableValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  return (
    <div>
      {/* Label  */}
      <div
        className={styles.strategyHeading}
        onClick={(e) => {
          setIsOpen((prev) => !prev);
        }}
      >
        <div className={styles.text}>{deliverableLabel}</div>
        <div className={styles.dropDownIndicator}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6 9L12 15L18 9"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      {/* Content  */}
      {deliverableValue &&
        Object.entries(deliverableValue).map(
          ([subdeliverableLabel, subdeliverableValue], index) =>
            isOpen && (
              <motion.div
                initial={{ opacity: 0, top: -20 }}
                animate={{ opacity: 1, top: 0 }}
                transition={{ duration: 0.75 }}
                className={styles.strategy}
                key={index}
              >
                <div className={styles.heading}>
                  <div className={styles.text}>{subdeliverableLabel}</div>
                  {/* <div
                    className={styles.selectAll}
                    onClick={(e) => {
                      if (selectAll) {
                        e.target.classList.remove(styles.clicked);
                      } else {
                        e.target.classList.add(styles.clicked);
                      }
                      setSelectAll((state) => !state);
                    }}
                  >
                    Select All
                  </div> */}
                </div>

                <div className={styles.row}>
                  {subdeliverableValue.map((item, index) => (
                    <Card
                      key={index}
                      type={deliverableLabel}
                      subType={subdeliverableLabel}
                      name={item}
                    />
                  ))}
                </div>
              </motion.div>
            )
        )}
    </div>
  );
};
