import styles from "./SideNav.module.css";
import companyLogo from "../../../assets/images/AlexLogo.png";
import A from "../../../assets/images/A.logo.png";
import NavBarItem from "../NavBarItem";
import { setNavBar } from "../../../redux/features/NavBar";
import { useSelector, useDispatch } from "react-redux";
import { setHistorySidebar } from "../../../redux/features/History";
import {
  MdOutlineHistory,
  MdOutlineTextSnippet,
  MdOutlineHome,
  MdOutlineHelpOutline,
  MdLogout,
  MdArrowBack,
  MdDashboard,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

export default function SideNav() {
  const dispatch = useDispatch();
  const navBarState = useSelector((state) => state.navBar.name);
  const { historySidebar } = useSelector((state) => state.history);

  const navigate = useNavigate();
  return (
    <div className={styles.sideNav}>
      <div className={styles.companyLogo}>
        <img src={companyLogo} height={"100%"} width={"auto"} />
      </div>

      <div className={styles.ALogo}>
        <img src={A} height={"100%"} width={"auto"} />
      </div>

      <hr className={styles.dotted} />
      <div className={styles.navBar}>
        <NavBarItem
          onClick={(e) => {
            dispatch(setNavBar("home"));
            dispatch(setHistorySidebar(false));
          }}
          style={{
            backgroundColor:
              navBarState === "home" ? `rgba(35, 44, 107, 1)` : "inherit",
          }}
        >
          <MdDashboard />
          <div className={styles.text}>Dashboard</div>
        </NavBarItem>

        <NavBarItem
          onClick={(e) => {
            dispatch(setNavBar("history"));
            dispatch(setHistorySidebar(false));
          }}
          style={{
            backgroundColor:
              navBarState === "history" ? `rgba(35, 44, 107, 1)` : "inherit",
          }}
        >
          <MdOutlineTextSnippet />
          <div className={styles.text}>Create Benchmark</div>
        </NavBarItem>

        <NavBarItem
          onClick={(e) => {
            dispatch(setHistorySidebar(!historySidebar));
          }}
          style={{
            backgroundColor: historySidebar
              ? `rgba(35, 44, 107, 1)`
              : "inherit",
          }}
        >
          <MdOutlineHistory />
          <div className={styles.text}>Benchmark History</div>
        </NavBarItem>

        <NavBarItem
          onClick={(e) => {
            dispatch(setNavBar("support"));
            dispatch(setHistorySidebar(false));
          }}
          style={{
            backgroundColor:
              navBarState === "support" ? `rgba(35, 44, 107, 1)` : "inherit",
          }}
        >
          <MdOutlineHelpOutline />
          <div className={styles.text}>Support and Help</div>
        </NavBarItem>

        <div className={styles.homeBtn}>
          <NavBarItem
            onClick={(e) => {
              dispatch(setNavBar("home"));
              navigate("/");
            }}
            style={{
              backgroundColor: `rgba(35, 44, 107, 1)`,
            }}
          >
            <MdArrowBack />
            <div className={styles.text}>Back to Home</div>
          </NavBarItem>
        </div>
      </div>
    </div>
  );
}
