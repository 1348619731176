import { CircularProgress, Skeleton } from "@mui/material";
import React from "react";
import styles from "./RateCardSkeleton.module.css";
const RateCardSkeleton = () => {
  return (
    <div className={styles.skeleton}>
      <CircularProgress
        style={{
          color: "#374CE7",
        }}
      />
      {/* <Skeleton className={styles.skeleton} animation={"wave"} variant="overlay" width={"100%"} /> */}
    </div>
  );
};

export default RateCardSkeleton;
