import styles from "./Home.module.css";
import BenchMarkCard from "../BenchMarkCard";
import { useDispatch, useSelector } from "react-redux";
import StickyHeadTable from "../../../Table/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect } from "react";
import {
  getAdminDashboardStatsThunkMiddleware,
  lastFiveBenchmarkThunkMiddleware,
} from "../../../../redux/features/Admin";
import { Typography } from "@mui/material";
import TableSkeleton from "./TableSkeleton";
import { logoutThunkMiddleware } from "../../../../redux/features/Profile";
import { MdLogout } from "react-icons/md";
import toast from "react-hot-toast";

const actionButtonStyle = {
  color: "#374CE7",
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  borderRadius: "10px",
  backgroundColor: "#F5F5F5",
};

const options = [
  { label: "Last Week", value: "lastWeek" },
  { label: "Last Week", value: "lastWeek" },
];

const rows = [
  createData("1", "John Smith", "$50k"),
  createData("2", "Emily Johnson", "$100k"),
  createData("3", "Michael Lee", "$250k"),
  createData("4", "Sarah Miller", "500k"),
  createData("5", "David Anderson", "1000k"),
];

const columns = [
  { id: "serialNumber", label: "Sr. No.", minWidth: "33%", align: "center" },
  { id: "name", label: "Name of the user", minWidth: "33%", align: "center" },
  {
    id: "benchmarks",
    label: "Benchmarks(budget)",
    minWidth: "33%",
    align: "center",
  },
];
function createData(serialNumber, name, benchmarks) {
  return { serialNumber, name, benchmarks };
}
const headingStyles = {
  color: "#FFF",
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  border: "1px solid #FFF",
  backgroundColor: "inherit",
};
const cellStyles = {
  color: "rgba(255, 255, 255, 0.50)",
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  border: "1px solid rgba(255, 255, 255, 0.50)",
  backgroundColor: "inherit",
};
export default function Home() {
  const dispatch = useDispatch();
  const homeState = useSelector((state) => state.home.name);
  const { totalUsers, totalBenchmarkValue, lastFiveBenchmark } = useSelector(
    (state) => state.admin
  );
  const { isLoading } = useSelector((state) => state.loader);

  const CreateRows = (lastFiveBenchmark) => {
    const rows = [];
    if (lastFiveBenchmark) {
      lastFiveBenchmark.forEach((element, index) => {
        rows.push(
          createData(
            index + 1,
            element.User.Profile.name,
            `${
              element.currencySymbol
            } ${(+element.benchmarkValue).toLocaleString("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`
          )
        );
      });
    }
    return rows;
  };

  useEffect(() => {
    const getDetails = () => {
      dispatch(getAdminDashboardStatsThunkMiddleware());
      dispatch(lastFiveBenchmarkThunkMiddleware());
    };
    if (homeState === "home") {
      getDetails();
    }
  }, []);

  const logoutHandler = () => {
    dispatch(logoutThunkMiddleware());
    toast.success("Logout Successfully");
  };

  return (
    <>
      {homeState === "home" && (
        <>
          <div className={styles.main}>
            <div className={styles.topHeading}>
              <div className={styles.left}>
                <div className={styles.displayName}>Hi Admin,</div>
                <div className={styles.welcomeNote}>
                  Welcome to the Admin Dashboard, where you have complete
                  control over the AI marketing platform.
                </div>
              </div>
              <div className={styles.right}>
                <div
                  className={styles.profileIcon}
                  // onClick={() => {
                  //   dispatch(setHome("profile"));
                  // }}
                >
                  <div className={styles.letter}>A</div>
                </div>
                <div className={styles.profileIcon} onClick={logoutHandler}>
                  <div className={styles.letter}>
                    <MdLogout />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.mainContainer}>
              {/*Dashboard Stats  */}
              <div className={styles.summaryContainer}>
                <div className={styles.summaryText}>
                  Summary of the key KPIs
                </div>
                {/* <div className={styles.dropDown}>
                  <AgencyTypeDropDown name={"Last Week"} options={options} />
                </div> */}
                {/* <div className={styles.icons}>
                  <div className={styles.helpIcon}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.08984 8.99999C9.32495 8.33166 9.789 7.7681 10.3998 7.40912C11.0106 7.05015 11.7287 6.91893 12.427 7.0387C13.1253 7.15848 13.7587 7.52151 14.2149 8.06352C14.6712 8.60552 14.9209 9.29151 14.9198 9.99999C14.9198 12 11.9198 13 11.9198 13"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 17H12.01"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div> */}
              </div>
              <div className={styles.benchMark}>
                <BenchMarkCard
                  heading={"Total number of users registration"}
                  value={totalUsers}
                  comment={""}
                />
                <BenchMarkCard
                  heading={"Total Benchmark"}
                  value={`$${
                    totalBenchmarkValue
                      ? (+(totalBenchmarkValue / 1000)).toLocaleString(
                          "en-US",
                          { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                        )
                      : 0
                  }k`}
                  comment={""}
                />
              </div>

              {/*Benchmark  Table  */}
              <div className={styles.benchMarkList}>
                <div className={styles.heading}>Last five benchmarks</div>
                <StickyHeadTable>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={headingStyles}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableSkeleton col={3} />
                    ) : (
                      <>
                        {lastFiveBenchmark ? (
                          CreateRows(lastFiveBenchmark).map((row, index) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                              >
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                      style={cellStyles}
                                    >
                                      {value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })
                        ) : (
                          <>
                            <TableRow>
                              <TableCell colSpan={3} style={cellStyles}>
                                <Typography
                                  style={{ textAlign: "center" }}
                                  variant="subtitle1"
                                >
                                  No Benchmark to show
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </>
                    )}
                  </TableBody>
                </StickyHeadTable>
              </div>
              <div className={styles.footer}>
                For any assistance or queries, visit our Support Center or
                contact our support team at <span>support@alchemists.ai</span>{" "}
                or call (555) 123-4567.
              </div>
            </div>
          </div>
          {/* <RightSideBar /> */}
        </>
      )}
      {/* {homeState === "estimate" && <EstimateRateCard />} */}
      {/*   {homeState === "create" && <CreateRateCard />} */}
    </>
  );
}
