import { useEffect, useState } from "react";
import SideNav from "../Dashboard/BenchmarkHistory/SideNav";
import styles from "./SelectionEstimation.module.css";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import Step4 from "./Step4/Step4";
import Step5 from "./Step5/Step5";
import bell from "../../assets/images/Bell.png";
import { useDispatch, useSelector } from "react-redux";
import { postBenchmarkDetailsThunkMiddleware } from "../../redux/features/BenchmarkDetails";
import { setHome } from "../../redux/features/Home";
const check = ["step1", "step2", "step3", "step4", "step5"];

export default function SelectionEstimation() {
  const dispatch = useDispatch();
  const name = useSelector((state) => state.history.name);

  const {
    globalOrRegional,
    industry,
    b2bOrB2c,
    location,
    agencyType,
    tier,
    ambition,
    intendedUse,
    budget,
    scope,
    deliverableType,
  } = useSelector((state) => state.benchmarkDetails);

  // useEffect(() => {
  //   if (name === "step5") {
  //     dispatch(
  //       postBenchmarkDetailsThunkMiddleware(
  //         {
  //           globalOrRegional,
  //           industry,
  //           b2bOrB2c,
  //           location,
  //           agencyType,
  //           tier,
  //           ambition,
  //           intendedUse,
  //           budget,
  //           scope,
  //         },
  //         (error) => {
  //           if (!error) dispatch(setHome("home"));
  //         }
  //       )
  //     );
  //   }
  // }, [name]);
  return (
    <div className={styles.selectionEstimation}>
      {check.includes(name) && (
        <>
          <div className={styles.main}>
            <div className={styles.modal}>
              {name === "step1" ? (
                <Step1 />
              ) : name === "step2" ? (
                <Step2 />
              ) : name === "step3" ? (
                <Step3 />
              ) : name === "step4" ? (
                <Step4 />
              ) : name === "step5" ? (
                <Step5 />
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
