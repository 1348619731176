import styles from "./EstimateRateCard.module.css";
import Button from "../../Button";
import Result from "./Result";
import { useDispatch, useSelector } from "react-redux";
import { setHome } from "../../../redux/features/Home";
import {
  getRatecardInfoThunkMiddleware,
  initialState,
  setRatecard,
} from "../../../redux/features/RatedcardDetails";
import AgencyTypeDropDown from "../../SelectionEstimation/Step2/AgencyTypeDropDown";
import { CircularProgress } from "@mui/material";

export default function EstimateRateCard({ rateCardState, setRateCardState }) {
  const dispatch = useDispatch();
  // const [state, setState] = useState("form");
  const { isLoading } = useSelector((state) => state.loader);

  const { country, tier, agencyType } = useSelector(
    (state) => state.ratecardDetails
  );
  const { countryList, tierAgencyList, agencyTypeList } = useSelector(
    (state) => state.miscellaneous
  );

  const postRatecard = (e) => {
    dispatch(
      getRatecardInfoThunkMiddleware(
        {
          country,
          tier,
          agencyType,
        },
        (error) => {
          if (!error) setRateCardState("result");
        }
      )
    );
  };

  return (
    <>
      {rateCardState === "form" && (
        <div className={styles.estimateRateCardMain}>
          <div className={styles.content}>
            <div className={styles.heading}>
              Let's get started with rate card information.
            </div>
            <div className={styles.geography}>Geography</div>
            <div className={styles.box}>
              <div className={styles.inputLabel}>Country</div>
              <div className={styles.inputBox}>
                <AgencyTypeDropDown
                  name={"Select your country"}
                  options={countryList}
                  title={"country"}
                  initialValue={country}
                  setOption={setRatecard}
                />
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.inputLabel}>Tier of Agency</div>
              <div className={styles.inputBox}>
                <AgencyTypeDropDown
                  name={"Select your Agency Tier"}
                  options={tierAgencyList}
                  title={"tier"}
                  initialValue={tier}
                  setOption={setRatecard}
                />
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.inputLabel}>Agency Type</div>
              <div className={styles.inputBox}>
                <AgencyTypeDropDown
                  name={"Select your Agency Type"}
                  options={agencyTypeList}
                  title={"agencyType"}
                  initialValue={agencyType}
                  setOption={setRatecard}
                />
              </div>
            </div>
            <div className={styles.buttonBox}>
              <div className={styles.button1}>
                <Button
                  name={"Cancel"}
                  style={{
                    height: "2.8rem",
                    fontWeight: "400",
                    backgroundColor: "inherit",
                  }}
                  onClick={(e) => {
                    dispatch(setHome("home"));
                    dispatch(setRatecard(initialState));
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className={styles.button2}>
                <Button
                  name={"Save"}
                  style={{
                    height: "2.8rem",
                    fontWeight: "700",
                    backgroundColor: "#374CE7",
                    border: "0",
                  }}
                  onClick={postRatecard}
                  disable={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={30} color="inherit" />
                  ) : (
                    "Estimate"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {rateCardState === "result" && (
        <Result setState={setRateCardState} state={rateCardState} />
      )}
    </>
  );
}
