import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  role: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setToken(state, action) {
      const { token, isAuthenticated, role } = action.payload;
      state.token = token;
      state.isAuthenticated = isAuthenticated;
      state.role = role;
      if (token) {
        localStorage.setItem("token", token);
      }
    },
  },
});

export const { setToken } = authSlice.actions;
export default authSlice.reducer;
